import { defineStore } from "pinia";
import { ref } from "vue";

export const useMeeting = defineStore("meeting", () => {
  const meetingId = ref<string>();

  function connect(id: string) {
    meetingId.value = id;
  }

  function disconnect() {
    meetingId.value = undefined;
  }

  return {
    meetingId,

    connect,
    disconnect,
  };
});
