<script lang="ts" setup>
import { QBtn } from "quasar";
import { usePromise } from "vue-composable";
import MLayoutStack from "../../medicus/MLayoutStack/MLayoutStack.vue";
import { hasPermission } from "../../../utils/permission";
import { openPage } from "../../../composables/dialog/drawer";

function openCalendar() {
  openPage("/scheduling/calendar");
}

const { loading: checkingPermission, result: hasAccess } = usePromise(() =>
  hasPermission("/scheduling/calendar"),
);
</script>

<template>
  <q-btn
    flat
    no-caps
    dense
    data-testid="calendar-menu-item"
    :disable="checkingPermission || !hasAccess"
    @click="openCalendar"
  >
    <MLayoutStack horizontal center full class="relative-position">
      <q-icon name="fa-regular fa-calendar" />
      <span>Calendar</span>
    </MLayoutStack>
  </q-btn>
</template>
