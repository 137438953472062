<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { DrawerUrlOptions } from "../../../store/drawers";
import { randomID } from "../../../utils/helpers";
import MAction, { ActionModelValue } from "../MAction";
import MButton from "../MButton";
import MBadge from "../MBadge";
import { usePermission } from "../../../composables/permission";

const props = withDefaults(
  defineProps<{
    title?: string;
    footer?: string;
    action?: ActionModelValue | Record<string, any> | null;
    badge?: any;
    to?: string;

    expand?: boolean;
    expanded?: boolean;

    toSlideover?: string | DrawerUrlOptions | false;
    toModal?: string | DrawerUrlOptions | false;

    focused?: boolean;
    border?: boolean;
    dense?: boolean;
    flex?: boolean;
    onClick?: (() => void) | (() => void)[];
    borderless?: boolean;
    gap?: string;
    noShadow?: boolean;
    verticalLine?: boolean;
  }>(),
  {
    gap: "1",

    expand: undefined,
  },
);

const emit = defineEmits({
  click: (_e: any) => true,
  "update:expanded": (_value: boolean) => true,
});

const cardButtonRef = ref<HTMLDivElement | null>(null);

const __expandedInner = ref(props.expanded);
watch(
  () => props.expanded,
  (v) => (__expandedInner.value = v),
);
const expanded = computed({
  get() {
    return __expandedInner.value;
  },
  set() {
    __expandedInner.value = !__expandedInner.value;
    emit("update:expanded", __expandedInner.value);
  },
});

const { hasLink, disabled, onClick } = usePermission(props, true);
function handleClick(e: any) {
  if (disabled.value) return;
  e.preventDefault();
  return onClick(e);
}

const hasClick = computed(() => hasLink.value || props.onClick);

const labelId = randomID("m-card__label");
const contentId = randomID("m-card__content");
</script>
<template>
  <div
    class="m-card"
    :class="{
      'with-hover medicus-outline cursor-pointer': hasClick,
      dense,
      borderless,
      focused,
      'with-action': badge || action || $slots.action,
      'no-shadow': noShadow || border,
      border: border,
      'vertical-line': verticalLine,
    }"
    :disable="disabled"
    :role="hasClick ? 'button' : 'group'"
    :tabindex="hasClick ? 0 : undefined"
    v-on="props.onClick !== undefined ? { click: handleClick } : {}"
    @keydown.enter.space.self="handleClick"
  >
    <div
      v-if="action || $slots.action || expand !== undefined || expanded"
      ref="cardButtonRef"
      class="card-button"
    >
      <slot name="action">
        <MAction v-if="action" :model-value="action" />
      </slot>
      <m-button
        v-if="expand !== undefined || expanded"
        :icon="expanded ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'"
        ghost
        icon-only
        :label="`${expanded ? 'Collapse section:' : 'Expand section:'} ${title}`"
        :aria-expanded="expanded"
        :aria-controls="contentId"
        data-testid="expand-button"
        @click="expanded = !expanded"
      />
    </div>
    <div :id="contentId" :class="`card-container flex flex-col gap-${gap} flex-auto`">
      <div
        v-if="title || $slots.title"
        class="card-header"
        :style="{
          paddingRight: cardButtonRef ? `${cardButtonRef.offsetWidth + 10}px` : undefined,
        }"
      >
        <h3 :id="labelId">
          <slot name="title">{{ title }}</slot>
        </h3>
      </div>
      <div v-if="badge || $slots.headline" class="card--headline">
        <m-badge v-if="badge && badge.text" v-bind="badge" />
        <m-badge v-else-if="badge" :text="badge" />

        <slot name="headline" />
      </div>

      <div v-if="expand ? expanded : true" :class="{ 'card-body': true, 'flex flex-auto': flex }">
        <slot :label-id="labelId"></slot>
      </div>
      <div v-if="footer || $slots.footer" class="card-footer">
        <slot name="footer">{{ footer }}</slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
// BUTTON needs some special handling
button.m-card {
  // width: calc(100% - 30px);
  width: 100%;
  overflow: hidden;

  text-align: left;
}
.m-card {
  --card-padding: var(--gap-3);
  // NOTE update MDataCard if any main changes
  background: #ffffff;
  border-radius: 4px;
  box-shadow: var(--box-shadow);
  padding: var(--gap-2) 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 37px;
  flex: 0 0 auto;
  position: relative;

  .card-container {
    .card-header,
    .card-footer,
    .card--headline,
    .card-body:only-child,
    .card-body:not(:only-child) > *:not(.m-list, dl) {
      margin-left: var(--card-padding);
      margin-right: var(--card-padding);
    }

    .card-body:only-child:has(> .m-list, > .description-list) {
      margin-left: 0;
      margin-right: 0;

      > *:not(.m-list, .description-list) {
        margin-left: var(--card-padding);
        margin-right: var(--card-padding);
      }
    }
  }

  &.dense {
    border: 0px;
    border-bottom: 1px solid var(--grey-light);
    padding: 9px 0;
    border-radius: 0;
  }
  &.border {
    border: 1px solid var(--grey);
  }
  &.focused {
    background-color: var(--grey-lightest);
  }
  .card-button {
    position: absolute;
    right: 15px;
    display: flex;
    align-self: flex-start;

    .m-dropdown-button.ghost {
      padding: 0;
      height: auto;
      min-height: auto;
    }
  }
  &.with-hover:hover {
    background: var(--hover-background);
  }
  &.with-action {
    min-height: 60px;
  }
  &.no-shadow {
    box-shadow: none;
  }
  &.vertical-line {
    margin-left: 0rem;
    &::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 3px;
      // border-top-left-radius: 4px;
      // border-bottom-left-radius: 4px;
      background-color: var(--status-blue);
    }
  }

  .card--headline {
    display: flex;
  }
}

.m-card:has(.ag-theme-material) {
  padding-bottom: 0;

  .card-container {
    .card-body {
      .ag-theme-material {
        border: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
        margin-left: 0;
      }

      .ag-root-wrapper {
        border-radius: 0;
        box-shadow: none;
      }
    }
  }

  .ag-root-wrapper-body {
    height: auto;
  }
}
</style>
