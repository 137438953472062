<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<script lang="ts" setup>
import { QSpinner } from "quasar";
import {
  computed,
  getCurrentInstance,
  nextTick,
  onMounted,
  ref,
  useAttrs,
  useSlots,
  watch,
} from "vue";
import MValidationComponent from "./../MValidationComponent";

import InputHelper from "../../../InputHelper.vue";

import { Key } from "ts-key-enum";
import {
  InputRules,
  useHorizontalLabel,
  useInGroup,
  useInputRules,
  useInputWidth,
  usePlaceholder,
} from "../../../../composables/horizontalLabel";
import { validAttribute } from "../../../../utils/misc";

import { isFunction, isString } from "@vue/shared";
import InputMask from "inputmask";
import useBrowser from "../../../../store/browser";
import { checkLabel, randomID } from "../../../../utils/helpers";
import { ActionModelValue } from "../../MAction";
import MButton from "../../MButton";

const props = withDefaults(
  defineProps<{
    for?: string;
    label?: string;

    modelValue?: string | number | null;
    // just to prevent from overriding the modelValue on <input/>
    value?: any;

    name?: string;

    clearable?: boolean;
    loading?: boolean;

    rules?: InputRules;

    showAllErrors?: boolean;
    horizontal?: boolean;

    labelWidth?: string;

    autocomplete?: string;

    action?: ActionModelValue;
    actions?: ActionModelValue;
    // TODO probably remove this
    actionsLeft?: boolean;
    // /todo

    requiredText?: string;

    instructions?: string;

    placeholder?: string;

    type?: string;

    suffix?: string;

    small?: boolean;
    medium?: boolean;
    large?: boolean;

    border?: boolean;
    hideFocusOutline?: boolean;

    helper?: string;

    min?: number | string;
    minText?: string;
    max?: number | string;
    maxText?: string;

    autogrow?: boolean;
    uppercase?: boolean;
    mask?: string;
    inputFormat?: string | undefined;
    invalid?: boolean;

    step?: number | string;
    decimalPlaces?: number | string;
    srLabel?: string;

    autofocus?: boolean;

    debounce?: number | string;

    disable?: boolean;
    disabled?: boolean;

    role?: string;
    inputRole?: string;

    showClear?: boolean;

    counter?: boolean;
    counterText?: string | ((len: number, str: string, max?: number) => string);
    maxlength?: number | string;
    maxlengthText?: string;

    onKeydown?: (e: KeyboardEvent) => void;
    onClick?: (e: MouseEvent) => void;

    noValidation?: boolean;
  }>(),
  {
    autocomplete: "chrome-off",
    type: "search",

    border: true,

    showClear: undefined,
  },
);

const emit = defineEmits({
  "update:modelValue": (_: any) => true,
  clear: () => true,
  blur: (e: any) => true,
  animationend: (e: AnimationEvent) => true,

  change: (text: string) => true,

  keydown: (e: KeyboardEvent) => true,
});

const attrs: Record<string, any> = useAttrs();
const slots = useSlots();

const inGroup = useInGroup();

const labelHorizontal = useHorizontalLabel(props);
const _labelWidth = useInputWidth(props);
const labelWidth = computed(() => _labelWidth.value || "auto");
const placeholder = usePlaceholder(props);
const inputEl = ref<HTMLInputElement | HTMLTextAreaElement | null>(null);

const id = computed(() => props.for || attrs.id || randomID("input"));
const instructionsId = computed(() => id.value + "-instructions");

const isTextarea = computed(() => props.type === "textarea" || props.autogrow);

const { proxy } = getCurrentInstance()!;

function countDecimals(v: number) {
  if (Math.floor(v.valueOf()) === v.valueOf()) return 0;

  var str = v.toString();
  if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
    return +str.split("-")[1] || 0;
  } else if (str.indexOf(".") !== -1) {
    return +str.split(".")[1].length || 0;
  }
  return +str.split("-")[1] || 0;
}

const {
  value,
  displayErrors: _displayErrors,
  valid,
  formTouched,
  touched,
} = useInputRules(
  props,
  () => {
    const inputs = proxy?.$el.getElementsByTagName(
      props.type === "textarea" ? "textarea" : "input",
    );
    inputs[0]?.focus();
  },
  [
    (v: string) => {
      if (props.decimalPlaces === undefined) return true;

      const d = countDecimals(+v);
      const decimalPlaces = +props.decimalPlaces;
      if (decimalPlaces <= 0 && d === 0) {
        return true;
      }
      return (
        d <= decimalPlaces ||
        `Maximum ${decimalPlaces} decimal place${decimalPlaces! > 1 ? "s" : ""}`
      );
    },
    (v: string) => {
      if (props.type === "number" && typeof value.value === "string" && value.value.length > 0) {
        const endsInADot = value.value.indexOf(".") === value.value.length - 1;
        const hasManyDots = value.value.split(".").length - 1 > 1;
        if (endsInADot || hasManyDots) {
          return "This field must be a number";
        }
      }

      return true;
    },
  ],
  undefined,
  () => props.requiredText || `Enter a value for '${props.label || props.srLabel}'`,
);

const displayErrors = computed(
  () => attrs.displayErrors ?? attrs["display-errors"] ?? _displayErrors.value,
);

const originalValue = value.value;

const isInvalid = computed(() => {
  return (displayErrors.value && displayErrors.value.length) || props.invalid || !valid.value;
});
const classes = computed(() => {
  const invalid = isInvalid.value;
  return [
    labelHorizontal.value ? "flex-row items-center horizontal" : "flex-col",
    invalid && "with-errors",
    {
      required: validAttribute(attrs.required),
      readonly: validAttribute(attrs.readonly),
      disabled: validAttribute(attrs.disabled) ? true : undefined,

      valid: !invalid,
      error: invalid,

      empty: !value.value, //isEmpty
    },
  ];
});

const attributes = computed(() => {
  const attrs: Record<string, any> = {
    tabindex: 0,
    "data-autofocus": props.autofocus === true || void 0,
    rows: props.type === "textarea" ? 6 : void 0,
    "aria-label": props.label,

    disabled: props.disable || props.disabled,
  };

  if (isTextarea.value === false) {
    if (props.type === "number") {
      attrs.type = "text";
      attrs.inputmode = "numeric";
      attrs.pattern = "d+(.d+)?";
    } else {
      attrs.type = props.type;
    }
  }

  if (isTextarea.value === true) {
    attrs.rows = 1;
  }

  return attrs;
});

const onEvents = computed(() => {
  const evt: Record<string, any> = {};
  if (isTextarea.value === true) {
    evt.onAnimationend = onAnimationend;
  }

  return evt;
});

function onFocus(e: FocusEvent) {
  if (props.type === "number") {
    selectText();
  }
}

function onBlur(e: FocusEvent) {
  emit("blur", e);
  // @ts-expect-error custom prop
  if (e && e.preventFormTouch) return;

  if (props.noValidation) return;

  if (formTouched) {
    setTimeout(() => {
      const t = (touched.value = touched.value || value.value !== originalValue);

      if (!proxy?.$.isUnmounted) {
        formTouched(t);
      }
    }, 50);
  }
}

function clearValue() {
  value.value = "";
  emit("clear");

  if (inputEl.value) {
    inputEl.value.focus();
  }
}

function selectText() {
  // Delay allows for MSelect to update input value
  // otherwise, subselection will occur
  setTimeout(() => {
    inputEl.value?.select(); //FIX
  }, 80);
}

const hasLabel = computed(
  () => props.label || attrs.required || slots.helper || props.helper || props.srLabel,
);

const focus = () => {
  console.log("focus");
  inputEl.value?.focus();
};

defineExpose({
  focus,
  clearValue,
  selectText,
});

const steps = computed(() => {
  if (props.step) {
    return props.step;
  }
  if (props.decimalPlaces) {
    const decimalPlaces = +props.decimalPlaces;
    if (!decimalPlaces) return undefined;
    return "0.".padEnd(decimalPlaces + 1, "0") + "1";
  }
  return undefined;
});

// limit Number input to these keys
const NumberAllowedKeys = new Set([
  Key.Meta,
  Key.Backspace,
  Key.Shift,
  Key.Delete,
  Key.ArrowLeft,
  Key.ArrowRight,
  Key.ArrowUp,
  Key.ArrowDown,
  Key.Tab,
  ".",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
]);

function handleKeydown(e: KeyboardEvent) {
  if (props.autogrow) nextTick(adjustHeight);

  if (validAttribute(attrs.disable) || validAttribute(attrs.disabled)) {
    e.preventDefault();
    return;
  }

  if (props.type === "number") {
    if (e.metaKey === false && e.ctrlKey === false) {
      if (NumberAllowedKeys.has(e.key) === false) {
        e.preventDefault();
        return;
      }

      if ((e.target as HTMLInputElement).value?.includes(".") && e.key === ".") {
        e.preventDefault();
        return;
      }
    }
  }

  emit("keydown", e);
}

// using inputmask to prevent
watch(
  [() => props.decimalPlaces, () => props.mask, () => props.inputFormat, inputEl, placeholder],
  ([digits, mask, inputFormat, input, placeholder], _, onCleanup) => {
    if (!input) return;
    if (!digits && !mask && !inputFormat) return;

    const im = digits
      ? new InputMask({
          alias: "numeric",
          showMaskOnFocus: false,
          showMaskOnHover: false,
          rightAlign: false,
          digits,
          placeholder,
        })
      : new InputMask({
          mask,
          inputFormat,
          alias: inputFormat ? "datetime" : undefined,
          showMaskOnFocus: false,
          showMaskOnHover: false,
          jitMasking: true,

          placeholder,
        });

    im.mask(input);
    if (value.value) {
      value.value = im.format(value.value.toString());
    }

    onCleanup(() => {
      input.inputmask?.remove();
      im.remove();
    });
  },
  {
    flush: "sync",
  },
);
const browser = useBrowser();

// textarea only based on quasar implementation
// https://github.com/quasarframework/quasar/blob/c6242bac195ebc52d527742e801d9bc826bb2c95/ui/src/components/input/QInput.js#L306-L336
function adjustHeight() {
  requestAnimationFrame(() => {
    const inp = inputEl.value;
    if (inp !== null) {
      const parentStyle = (inp.parentNode as HTMLElement).style;
      // chrome does not keep scroll #15498
      const { scrollTop } = inp;
      // chrome calculates a smaller scrollHeight when in a .column container
      const { overflowY, maxHeight } =
        browser.name === "firefox" ? {} : window.getComputedStyle(inp);
      // on firefox or if overflowY is specified as scroll #14263, #14344
      // we don't touch overflow
      // firefox is not so bad in the end
      const changeOverflow = overflowY !== void 0 && overflowY !== "scroll";

      // reset height of textarea to a small size to detect the real height
      // but keep the total control size the same
      changeOverflow === true && (inp.style.overflowY = "hidden");
      parentStyle.marginBottom = inp.scrollHeight - 1 + "px";
      inp.style.height = "1px";

      inp.style.height = inp.scrollHeight + "px";
      // we should allow scrollbars only
      // if there is maxHeight and content is taller than maxHeight
      changeOverflow === true &&
        (inp.style.overflowY = parseInt(maxHeight, 10) < inp.scrollHeight ? "auto" : "hidden");
      parentStyle.marginBottom = "";
      inp.scrollTop = scrollTop;
    }
  });
}

onMounted(() => {
  // textarea only
  isTextarea.value === true && adjustHeight();
  // on MSelect the input does not update the rendering text
  nextTick(proxy?.$forceUpdate);
});

watch(value, () => {
  // textarea only
  isTextarea.value === true && nextTick(adjustHeight);
});
watch(isTextarea, (val) => {
  // textarea only
  if (val === true) {
    nextTick(adjustHeight);
  }
  // if it has a number of rows set respect it
  else if (inputEl.value !== null && attrs.rows > 0) {
    inputEl.value.style.height = "auto";
  }
});

function onAnimationend(e: AnimationEvent) {
  emit("animationend", e);
  adjustHeight();
}

const counterText = computed(() => {
  if (!props.counter) return;

  const str = `${value.value ?? ""}`;
  const len = str.length || 0;
  const max = +props.maxlength;

  let text = `${len}${max ? ` / ${max}` : ""} characters.`;

  if (props.counterText) {
    if (isFunction(props.counterText)) {
      text = props.counterText(len, str, max) || text;
    } else if (isString(props.counterText)) {
      text = props.counterText;
    }
  }

  return text;
});

// if (IS_APP && (import.meta.env.DEV || location.host.startsWith("uat."))) {
//   watchEffect(() => {
//     if (props.label) return;
//     if (inGroup.value) {
//       warn('This input is not accessible, please pass "label" property when in a group');
//     }
//   });
// }

// eslint-disable-next-line no-undef
if (IS_APP && (import.meta.env.DEV || location.host.startsWith("uat."))) {
  checkLabel(props, "MInput", "label");
}
</script>
<template>
  <m-validation-component
    class="m-input medicus-outline"
    :errors="displayErrors"
    :class="$attrs.class"
    :actions="actions"
    :actions-left="actionsLeft"
  >
    <div
      :class="[
        'm-input--content',
        {
          horizontal: horizontal && !inGroup,
          'with-errors': isInvalid,
          'gap-1': hasLabel && !srLabel && !instructions,
          'in-group': inGroup,
          disabled,
        },
      ]"
    >
      <label
        v-if="hasLabel"
        :id="`label-${id}`"
        :for="id"
        class="label gap-1"
        :class="{ 'sr-only': srLabel }"
      >
        <span>{{ label || srLabel }}</span>

        <input-helper v-if="$slots.helper || helper" :text="helper || ''">
          <slot name="helper" />
        </input-helper>
        <span v-if="validAttribute($attrs.required)" class="required">*</span>
      </label>
      <p v-if="props.instructions" :id="instructionsId" class="instructions">
        {{ props.instructions }}
      </p>
      <div
        ref="container"
        class="m-input--input-box"
        :class="{
          'textarea-box': type === 'textarea',
          disabled: disable || disabled,
          small,
          medium,
          large,
          border,
          'hide-focus-outline': hideFocusOutline,
        }"
        :style="isTextarea ? { height: 'auto' } : undefined"
        :role="role"
        @click="onClick"
      >
        <div v-if="$slots.prepend" class="m-input--input-box--prepend">
          <slot name="prepend" />
        </div>

        <slot
          name="input"
          v-bind="{
            id,
            autocomplete,
            min,
            max,
            maxlength,
            steps,
            inputRole,
            onBlur,
            handleKeydown,
          }"
        >
          <!-- NOTE having input and textarea here, because of component is
        does not bind correctly to the component -->
          <textarea
            v-if="isTextarea"
            v-bind="{ ...$attrs, ...attributes, ...onEvents, class: undefined }"
            :id="id"
            ref="inputEl"
            v-model="value"
            :class="classes"
            :autocomplete="autocomplete"
            :min="min"
            :max="max"
            :step="steps"
            :role="inputRole"
            :autofocus="autofocus"
            :aria-labelledby="`label-${id}`"
            :aria-describedby="instructionsId"
            @blur="onBlur"
            @keydown="handleKeydown"
            @change="$emit('change', $event?.target?.value)"
          />
          <input
            v-else
            v-bind="{ ...$attrs, ...attributes, ...onEvents, class: undefined }"
            :id="id"
            ref="inputEl"
            v-model="value"
            :class="classes"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :autofocus="autofocus"
            :min="min"
            :max="max"
            :step="steps"
            :role="inputRole"
            :aria-labelledby="`label-${id}`"
            :aria-describedby="instructionsId"
            @focus="onFocus"
            @blur="onBlur"
            @keydown="handleKeydown"
            @change="$emit('change', $event?.target?.value)"
          />
        </slot>

        <div
          v-if="$slots.append || clearable || showClear || suffix"
          class="m-input--input-box--append"
        >
          <m-button
            v-if="showClear === undefined ? clearable && value : showClear"
            ghost
            icon="fa-solid fa-circle-xmark"
            color="secondary"
            icon-only
            :label="`Clear ${srLabel || label}`"
            data-testid="input-clear-button"
            @click="clearValue"
          />
          <div v-if="loading" class="loading">
            <q-spinner />
          </div>
          <span v-if="suffix">{{ suffix }}</span>
          <slot name="append" />
        </div>
      </div>
    </div>
    <div v-if="counter" class="m-input--bottom">
      <div></div>
      <div class="m-input--counter" :class="{ error: value?.length > maxlength }">
        {{ counterText }}
      </div>
    </div>
    <slot :el="$refs.container" />
  </m-validation-component>
</template>
<style lang="scss">
.m-input {
  .m-input--content {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      "label"
      "instructions"
      "input";
    position: relative;

    &.border {
      border: 1px var(--border-colour);
    }

    &.horizontal {
      grid-template-columns: v-bind(labelWidth) 1fr;
      grid-template-rows: auto;
      grid-template-areas: "label instructions input";

      label {
        align-self: flex-start;
        margin: 10px 5px auto auto;

        padding-right: 15px !important;
        text-align: right;
      }
    }

    &.disabled {
      opacity: 0.6;
    }

    > .small {
      max-width: 6rem;
    }

    > .medium {
      max-width: 10em;
    }

    > .large {
      max-width: 100%;
    }

    label {
      font-style: normal;

      grid-area: label;

      font-size: 14px;
      line-height: 150%;

      color: var(--text-color);
      font-weight: bold;
      display: flex;
      align-items: center;

      .required {
        color: var(--status-red);
      }
    }

    .error-icon {
      color: var(--status-red);
    }

    .m-input--input-box {
      grid-area: input;
      position: relative;

      display: grid;

      grid-template-columns: auto 1fr auto;
      grid-template-areas: "prepend input append";

      background-color: white;

      border-radius: 4px;
      height: 35px;
      min-height: 35px;

      transition: background 140ms ease-out;

      &.border::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;

        border-radius: inherit;

        border: 1px solid var(--grey-lightest-non-text);
        z-index: 100;
        transition: border-color 0.36s cubic-bezier(0.4, 0, 0.2, 1);
      }

      &:not(&.disabled).border:hover {
        &::before {
          border: 2px solid var(--grey-darker);
        }
      }

      &.hide-focus-outline:hover {
        background: var(--grey-lightest);
      }

      &.hide-focus-outline:focus-within {
        background: var(--grey-lightest);

        &::before {
          opacity: 0;
        }
      }

      &.disabled {
        &::before {
          border-color: var(--grey-light);
        }

        .m-input--input-box--prepend,
        .m-input--input-box--append {
          background: none;
          border: none;
        }
      }

      input,
      textarea,
      .input,
      .m-input--input-box--prepend,
      .m-input--input-box--append {
        grid-area: input;

        width: 100%;
        outline: none;

        line-height: 150%;
        font-weight: 400;
        // quasar
        padding: 0 10px 0 14px;
        background: none;
        border: none;
        letter-spacing: 0.00937em;
        text-decoration: inherit;
        text-transform: inherit;
      }
      textarea {
        padding-top: 10px;
        padding-bottom: 10px;
        resize: none;
        min-height: 42px;
      }

      .m-input--input-box--prepend,
      .m-input--input-box--append {
        background: var(--grey-lightest);
        padding: 0 10px;
        border-color: var(--grey-lightest-non-text);
        color: var(--text-color-light);

        &:has(.medicus-button-container) {
          padding: 0;

          .medicus-button-container {
            height: 100%;

            button {
              height: 100%;
              padding: 0 10px;
              height: 100%;
            }

            &:not(:only-child) {
              border-right: 1px solid var(--grey-lightest-non-text);
            }
          }
        }

        &:empty {
          display: none;
        }

        .loading {
          padding: 0 10px;
        }
      }

      .m-input--input-box--prepend {
        grid-area: prepend;
        z-index: 1;
        font-size: 14px;
        display: flex;
        align-items: center;
      }

      .m-input--input-box--prepend {
        border-right-width: 1px;
        border-right-style: solid;
      }

      .m-input--input-box--append {
        grid-area: append;
        border-left-width: 1px;
        border-left-style: solid;

        z-index: 1;
        font-size: 14px;

        display: flex;
        align-items: center;

        &:has(.loading) {
          padding-left: 0;
        }
      }
    }

    .textarea-box {
      height: auto;

      textarea {
        min-height: 55px;
      }
    }

    &.with-errors {
      .m-input--input-box::before {
        border-color: var(--status-red);
      }
    }

    &.in-group {
      grid-template-rows: auto;
      grid-template-areas: "input";

      position: relative;

      label {
        position: absolute;
        z-index: 1;

        top: 5px;
        left: 12px;

        color: var(--grey-darkest);
        font-size: 12px;
      }
      .m-input--input-box {
        height: 45px;

        input {
          padding-top: 20px;
        }
      }
    }
  }

  &.empty {
    input:placeholder {
      color: var(--grey-darker) !important;
    }

    input {
      color: var(--grey-darker);
    }

    textarea::placeholder {
      color: var(--grey-darker);
    }
  }

  .instructions {
    color: var(--text-color-lightest);
    margin-bottom: var(--gap-2);
  }

  input {
    // color: #3c3735;
    color: var(--text-color);

    &::-webkit-calendar-picker-indicator {
      // filter: invert(1);
      color: var(--grey-darker);
    }

    &::placeholder {
      font-style: italic;
      color: var(--grey-darker);
    }
  }

  &.with-errors {
    margin-bottom: 0;
  }

  .input-action {
    padding: 0 !important;
    border: none !important;
    min-width: unset !important;
    &:focus {
      outline: none !important;
      border: none !important;
    }
  }

  .m-input--bottom {
    color: rgba(0, 0, 0, 0.54);
    padding: 0 0 0 12px;
    backface-visibility: hidden;

    display: flex;
    justify-content: space-between;
  }

  .with-errors ~ .m-input--bottom {
    color: var(--status-red);
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
