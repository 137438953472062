import { router } from "../../router";
import { DrawerOptions, useDrawerStore } from "../../store/drawers";

export const openModal = (url: string, options?: DrawerOptions) => {
  return useDrawerStore().open("modal", url, options);
};
export const openSlideover = (url: string, options?: DrawerOptions) => {
  return useDrawerStore().open("slideover", url, options);
};
export async function openPage(url: string) {
  return await router.push(url);
}
