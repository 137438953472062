import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { isString } from "vue-composable";
import { load } from "../../api/medicus";
import { MenuItem } from "../../layouts/MedicusLayout/SidebarMenu";
import { useAuth } from "../auth";

const APP_TITLE = "Medicus";

function useTitle(overrideTitle: string | null = null) {
  const title = ref<string | null>(isString(overrideTitle) ? overrideTitle : document.title);
  const observer = new MutationObserver((m) => {
    title.value = m[0].target.textContent;
  });
  watch(
    title,
    (t, o) => {
      if (isString(t) && t !== o) {
        document.title = t;
      }
    },
    {
      immediate: true,
    },
  );
  const titleElement = document.querySelector("title")!;
  observer.observe(titleElement, { childList: true });

  return title;
}

export const useApp = defineStore("app", () => {
  // DATASTORE PROPERTY: Title
  const appTitle = useTitle(APP_TITLE);
  const title = ref("");

  watch(title, (t) => {
    appTitle.value = [t, APP_TITLE].filter(Boolean).join(" | ");
  });

  function updateTitle(t: string) {
    title.value = t;
  }

  // DATASTORE PROPERTY: Modules Menu Items
  const modulesMenuItems = ref<MenuItem[]>([]);
  const { currentTenant } = useAuth();

  async function fetchModulesMenuItems() {
    if (modulesMenuItems.value.length === 0) {
      const response = await load<{ items: MenuItem[][] }>("navigation/ui/main-menu", {
        showError: false,
      }).then((x) => x.data.items[0]);

      //INFO: Assume route has permission to prevent having to call permission-check API — pre-condition on response returning permitted items only
      modulesMenuItems.value = response.map((item) => ({ ...item, permission: true }));
    }
  }

  function clearModulesMenuItems() {
    modulesMenuItems.value = [];
  }

  // DATASTORE PROPERTY: Keyboard shortcuts modal visibility
  const isKeyboardShortcusModalVisible = ref(false);
  function setKeyboardShortcusModalVisible(isOpen: boolean) {
    isKeyboardShortcusModalVisible.value = isOpen;
  }

  watch(
    () => currentTenant,
    (tenant) => {
      if (!tenant) return;
      clearModulesMenuItems();
    },
    { immediate: true, flush: "sync" },
  );

  // DATASTORE PROPERTY: Is Docked Modal Open
  const isDockedModalOpen = ref<boolean>(false);
  const dockedModalTitle = ref<string>();

  function setIsDockedModalOpen(isOpen: boolean) {
    isDockedModalOpen.value = isOpen;
  }

  function setDockedModalTitle(title?: string) {
    dockedModalTitle.value = title;
  }

  return {
    title,
    updateTitle,

    modulesMenuItems,
    fetchModulesMenuItems,
    clearModulesMenuItems,

    isKeyboardShortcusModalVisible,
    setKeyboardShortcusModalVisible,

    dockedModalTitle,
    isDockedModalOpen,
    setIsDockedModalOpen,
    setDockedModalTitle,
  };
});
