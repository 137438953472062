<script lang="ts" setup>
import { QBtn, QTooltip } from "quasar";
import { markRaw } from "vue";
import { usePromise } from "vue-composable";
import { showDialog } from "../../../utils/dialog";
import SearchComponent from "./SearchComponent";
import { hasPermission } from "../../../utils/permission";
import { useKeyboardShortcut } from "../../../composables/keyboardShortcut";

let openDialog: ReturnType<typeof showDialog> | null = null;

useKeyboardShortcut("patient-finder", () => {
  if (openDialog) return;
  openSearch();
});

function openSearch() {
  openDialog = showDialog({
    title: "Find a Patient",
    fillHeight: true,
    noButtons: true,
    component: markRaw(SearchComponent),
    type: "small",
    class: "app-search-modal",

    contentClass: "h-full",

    transient: true,
  }).onFinally(() => {
    openDialog = null;
  });
}

const { loading: checkingPermission, result: hasAccess } = usePromise(() =>
  hasPermission("/staff/recent-patients"),
);
</script>

<template>
  <q-btn
    flat
    no-caps
    dense
    data-testid="app-search"
    :disable="checkingPermission || !hasAccess"
    @click="openSearch"
  >
    <q-tooltip v-if="!hasAccess"> You don’t have permission to perform this action. </q-tooltip>
    <m-layout-stack horizontal center full>
      <q-icon name="fa-regular fa-magnifying-glass" />
      <span>Patient Finder</span>
    </m-layout-stack>
  </q-btn>
</template>
<style lang="scss">
.modal-wrapper .app-search-modal.modal {
  background-color: #fff;

  > .modal-header {
    box-shadow: none;
    padding: 10px 21px 0px 21px;

    > .modal-title {
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;

      margin: auto 0;
    }
  }
}
</style>
