import { isArray, isObject } from "vue-composable";
import axios from "./axios";
import { showDialog } from "./dialog";
import { pinia, useAuth } from "../store";

export function isValidNhsNumber(value: any) {
  if (!value || isArray(value) || isObject(value)) {
    return false;
  }
  // cast to string and replace whitespace
  const str = `${value}`.replace(/\s/g, "");

  // NHS number have 10 digits or not a number
  if (str.length !== 10 || Number.isNaN(+str)) {
    return false;
  }

  const total = str
    .split("")
    .slice(0, 9)
    .reduce((p, c, i) => {
      const check = +c * (11 - (i + 1));
      return p + check;
    }, 0);

  let checkDigit = 11 - (total % 11);
  if (checkDigit === 11) {
    checkDigit = 0;
  }

  return checkDigit === +str[9];
}

if (import.meta.vitest) {
  const { describe, test, expect } = import.meta.vitest;

  describe("NHS Util", () => {
    describe("should return false for falsy values", () => {
      test.each([undefined, null, "", false])("on %s", (v) => {
        expect(isValidNhsNumber(v)).toBe(false);
      });
    });

    describe("return true for valid value", () => {
      test.each(["9727028373", "9727028365", 9727028365])("on %s", (v) => {
        expect(isValidNhsNumber(v)).toBe(true);
      });
    });

    describe("return false for invalid value", () => {
      test.each(["9727028372", "9727028364", "972702837", "string", 1515, true, [], {}])(
        "on %s",
        (v) => {
          expect(isValidNhsNumber(v)).toBe(false);
        },
      );
    });
  });
}

export async function cis2Check(uri: string) {
  const response = await axios.get(uri).then((res) => res.data);

  if (false === response.hasValidCis2Session && null !== response.cis2ErrorMessage) {
    openCis2AccessDeniedDialog(response.cis2ErrorMessage);

    return false;
  }

  return true;
}

export function processCis2Response(response: any) {
  const notLoggedIn =
    "A NHS Smartcard login session is required to perform this action. Please log out and log in again with your Smartcard.";
  const activityNotGranted =
    "You do not have the correct Smartcard Activity Code to perform this action.";

  if (
    response?.data?.errors?.command[0] === notLoggedIn ||
    response?.data?.errors?.command[0]?.substring(0, activityNotGranted.length) ===
      activityNotGranted
  ) {
    openCis2AccessDeniedDialog(response.data.errors.command[0]);

    return true;
  }

  return false;
}

function openCis2AccessDeniedDialog(cis2ErrorMessage) {
  const auth = useAuth(pinia);

  showDialog({
    title: "Access Denied",
    text: cis2ErrorMessage,
    noCancel: true,
    okLabel: "Logout",
    okColor: "secondary",
  }).onOk(() => auth.logout());
}
