import { defineStore } from "pinia";
import { computed } from "vue";
import { useAuth } from "./auth";

export const useTenant = defineStore("tenant", () => {
  const authStore = useAuth();

  const isNHS = computed(() => authStore.config?.jurisdiction === "england-nhs");
  const isHSE = computed(() => authStore.config?.jurisdiction === "ireland-hse");
  const isGP = computed(() => authStore.config?.organisationTypes.includes("gp-practice"));
  const isOOH = computed(
    () => authStore.config?.organisationTypes.includes("out-of-hours-service"),
  );
  const isCommunity = computed(
    () => authStore.config?.organisationTypes.includes("community-care-service"),
  );
  const isSafeguardingService = computed(
    () => authStore.config?.organisationTypes.includes("safeguarding-service"),
  );
  const isDemo = computed(() => authStore.config?.demo);
  const isPfsApiEnabled = computed(() => authStore.config?.patientFacingServicesApiEnabled);

  return {
    isNHS,
    isHSE,
    isGP,
    isOOH,
    isCommunity,
    isSafeguardingService,
    isDemo,
    isPfsApiEnabled,
  };
});
