<script lang="ts" setup>
import { QTooltip } from "quasar";
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useDebouncedThrottledFunction } from "../../../composables/throttleDebounce";

const props = defineProps<{
  text: string;
  scrollTarget: Element;
  delay?: number;
  noTransition?: boolean;
}>();

const showTooltip = ref<boolean>(false);
const isArrowUp = ref<boolean>(true);
const timerId = ref<ReturnType<typeof setTimeout> | null>(null);
const tooltipCmp = ref<QTooltip | null>(null);
const delay = ref(500);
const OFFSET: number = 6;

type MutationCallback = {
  (mutations: MutationRecord[], observer: MutationObserver): void;
};

const handleMutations: MutationCallback = (mutations, _observer) => {
  mutations.forEach((mutation) => {
    if (mutation.attributeName === "style") {
      const el = mutation.target as HTMLDivElement;
      isArrowUp.value = el.style.maxHeight === "";
    }
  });
};

const { debouncedThrottled: debouncedHandleMutations } =
  useDebouncedThrottledFunction<MutationCallback>(handleMutations, 100, 1000);

const observer: MutationObserver = new MutationObserver(debouncedHandleMutations);

function handleMouseover() {
  if (timerId.value !== null) {
    clearTimeout(timerId.value);
    timerId.value = null;
  }

  showTooltip.value = true;
}

function handleMouseleave() {
  const newTimerId: ReturnType<typeof setTimeout> = setTimeout(() => {
    showTooltip.value = false;
  }, delay.value);

  timerId.value = newTimerId;
}

function handleKeydown(e: KeyboardEvent) {
  if (e.key === "Escape" && showTooltip.value === true) {
    showTooltip.value = false;
  }
}

function handleTargetFocus() {
  showTooltip.value = true;
}

function handleTargetBlur() {
  showTooltip.value = false;
}

function addListeners() {
  props.scrollTarget.addEventListener("mouseover", handleMouseover);
  props.scrollTarget.addEventListener("mouseleave", handleMouseleave);
  props.scrollTarget.addEventListener("focus", handleTargetFocus);
  props.scrollTarget.addEventListener("blur", handleTargetBlur);

  window.addEventListener("keydown", handleKeydown);
}

function removeListeners(el: Element) {
  if ("removeEventListener" in el) {
    el.removeEventListener("mouseover", handleMouseover);
    el.removeEventListener("mouseleave", handleMouseleave);
    el.removeEventListener("focus", handleTargetFocus);
    el.removeEventListener("blur", handleTargetBlur);
  }
}

onMounted(() => {
  if (props.scrollTarget) {
    addListeners();
  }

  if (props.delay !== undefined && props.delay !== null && props.delay >= 0) {
    delay.value = props.delay;
  }
});

watch(
  () => props.scrollTarget,
  (newScrollTarget, oldScrollTarget) => {
    if (newScrollTarget) {
      addListeners();
    }

    if (oldScrollTarget) {
      removeListeners(oldScrollTarget);
    }
  },
  { deep: true },
);

watch(showTooltip, (newShowTooltip) => {
  if (newShowTooltip === true) {
    nextTick(() => {
      if (tooltipCmp.value) {
        observer.observe(tooltipCmp.value.contentEl, {
          childList: false,
          characterData: false,
          subtree: false,
          attributes: true,
          attributeFilter: ["style"],
          attributeOldValue: false,
        });
      }
    });
  } else {
    observer.disconnect();
  }
});

onBeforeUnmount(() => {
  props.scrollTarget?.removeEventListener("mouseover", handleMouseover);
  props.scrollTarget?.removeEventListener("mouseleave", handleMouseleave);
  props.scrollTarget?.removeEventListener("focus", handleTargetFocus);
  props.scrollTarget?.removeEventListener("blur", handleTargetBlur);

  window.removeEventListener("keydown", handleKeydown);

  observer.disconnect();
});
</script>

<template>
  <q-tooltip
    ref="tooltipCmp"
    :model-value="showTooltip"
    :delay="0"
    :hide-delay="0"
    :scroll-target="scrollTarget"
    :offset="[0, OFFSET]"
    :class="{
      'arrow-up': isArrowUp === true,
      'arrow-down': isArrowUp === false,
    }"
    :transition-show="props.noTransition ? 'none' : undefined"
    :transition-hide="props.noTransition ? 'none' : undefined"
    no-parent-event
    class="tooltip"
    @mouseover="handleMouseover"
    @mouseleave="handleMouseleave"
  >
    <div class="triangle" />
    <div class="text">{{ text }}</div>
  </q-tooltip>
</template>

<style lang="scss">
.tooltip {
  position: relative;
  max-width: 250px !important;
  font-size: 12px;
  border: 0;
  padding: 2px 6px;
  margin-top: 20px;
  background: var(--theme-grey);
  overflow: visible;
  pointer-events: all !important;

  .triangle {
    position: absolute;
    left: 50%;
    background: var(--theme-grey);
    width: 6px;
    height: 6px;
    transform: rotate(45deg);
  }

  &.arrow-up .triangle {
    top: 0;
    translate: -50% -50%;
  }

  &.arrow-down .triangle {
    bottom: 0;
    translate: -50% 50%;
  }

  .text {
    white-space: pre-line;
  }
}
</style>
