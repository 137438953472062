<script>
import { Transition, defineComponent, h } from "vue";
// based on https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/

export default defineComponent({
  name: "TransitionExpand",
  // functional: true,
  props: {
    name: {
      type: String,
      default: "expand",
    },
  },

  setup(props, { slots, attrs }) {
    const data = {
      onAfterEnter(element) {
        element.style.height = "auto";
      },
      onEnter(element) {
        const width = getComputedStyle(element).width;
        element.style.width = width;
        element.style.position = "absolute";
        element.style.visibility = "hidden";
        element.style.height = "auto";

        const height = getComputedStyle(element).height;

        element.style.width = null;
        element.style.position = null;
        element.style.visibility = null;
        element.style.height = 0;

        // Force repaint to make sure the
        // animation is triggered correctly.
        getComputedStyle(element).height;

        requestAnimationFrame(() => {
          element.style.height = height;
        });
        attrs.onEntered?.();
      },
      onLeave(element) {
        const height = getComputedStyle(element).height;
        element.style.height = height;
        // Force repaint to make sure the
        // animation is triggered correctly.
        getComputedStyle(element).height;

        requestAnimationFrame(() => {
          element.style.height = 0;
        });
        attrs.onEnded?.();
      },
    };

    return () => h(Transition, { ...data, ...attrs, name: props.name }, slots);
  },
});
</script>

<style>
.expand-enter-active,
.expand-leave-active {
  transition: height 0.5s ease-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}
</style>
