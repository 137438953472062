<script setup lang="ts">
import { ComponentPublicInstance } from "vue";
import axios from "../../../../utils/axios";
import Contacts from "./Contacts.vue";
import { Patient } from "./helpers";

defineProps<{
  disableNavigation?: boolean;
  target?: HTMLElement | ComponentPublicInstance | null;

  onSelect?: (patient: Patient) => void;
}>();

const items = await axios
  .get<{ recentPatients: Array<Patient> }>("/staff/data/recent-patients")
  .then((x) => x.data.recentPatients);
</script>
<template>
  <Contacts
    key="contact-list"
    :items="items"
    :disable-navigation="disableNavigation"
    title="Recent Patients"
    empty-text="No recent patients."
    :target="target"
    :on-select="onSelect"
  />
</template>
