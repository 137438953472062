<script setup lang="ts">
import { ComponentPublicInstance, Ref, computed, inject, ref, watch } from "vue";
import { QIcon, QSeparator } from "quasar";
import { useRoute } from "vue-router";
import MLayoutStack from "../../MLayoutStack";
import { GroupItem, Icon } from "./types";
import MExpander from "../../MExpander";
import { randomID } from "../../../../utils/helpers";
import { usePermission } from "../../../../composables/permission";
import SidebarGroupItem from "./SidebarGroupItem";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  to: [String, Object],
  icon: {
    type: Object as () => Icon,
    default: undefined,
  },
  items: {
    type: Array as () => GroupItem[],
    required: true,
  },
  selected: Boolean,
  id: String,
  maxShowItems: {
    type: Number,
    default: undefined,
  },
  moreText: {
    type: String,
    default: "Show {0} more items",
  },
  display: {
    type: Boolean,
    default: true,
  },
  small: Boolean,
  collapsable: Boolean,
  notification: Number,
});

const renderedComponent = inject<
  Ref<ComponentPublicInstance & { subnavItem?: string }> | undefined
>("renderedComponent")!;
const expanded = ref<boolean>();

const notificationCount = computed(() => {
  return (
    props.notification || props.items.map((x) => x.notification ?? 0).reduce((a, b) => a + b, 0)
  );
});

const route = useRoute();

watch(
  [renderedComponent, () => route.fullPath.replace(`${route.params.tenant}/`, "")] as const,
  ([comp, path]) => {
    if (expanded.value === undefined) {
      expanded.value = !!props.items.find(
        (x) =>
          x.selected ||
          (x.to && ~path.indexOf(x.to)) ||
          (comp?.subnavItem && x.id && comp.subnavItem === x.id),
      );
    }
  },
  { immediate: true },
);

const { hasLink, disabled, onClick } = usePermission(props, false);

const ariaId = randomID("sidebar-group-");

function handleClick(ev: MouseEvent) {
  expanded.value = !expanded.value;

  if (!hasLink.value) return;

  return onClick(ev);
}

defineExpose({
  expanded,
});
</script>
<template>
  <div
    v-show="display"
    class="group"
    :class="{
      'group-with-title': !!title,
      'group-small': small,
      'group-collapsable': items.length && collapsable,
    }"
    :role="collapsable && items.length ? 'button' : undefined"
  >
    <component
      :is="to ? 'router-link' : 'button'"
      v-if="title"
      class="group-title"
      :class="{
        'group-item-title': items.length === 0,
        'item-collapsable': items.length && collapsable,
        'router-link-active':
          selected || (id && renderedComponent && id === renderedComponent.subnavItem),
      }"
      :to="to"
      :aria-expanded="collapsable && items.length ? expanded : undefined"
      :aria-controls="collapsable && items.length ? ariaId : undefined"
      tabindex="0"
      :disable="disabled"
      @click="handleClick"
    >
      <m-layout-stack horizontal class="items-center" gap="2">
        <q-icon
          v-if="collapsable && items.length"
          class="q-select__dropdown-icon"
          name="fa-solid fa-chevron-right"
          :class="{ 'rotate-90': expanded }"
        ></q-icon>
        <span>{{ title }}</span>
      </m-layout-stack>

      <div
        v-if="notificationCount && !expanded && (collapsable || items.length === 0)"
        class="group-item-info-container"
      >
        <div v-if="icon" class="group-item-icon">
          <q-icon :name="icon.name" />
          <span class="sr-only">{{ icon.description }}</span>
        </div>
        <div class="group-item-notification" data-testid="parent-group-item-notification">
          {{ notificationCount > 99 ? "99+" : notificationCount }}
        </div>
      </div>
    </component>
    <m-expander
      v-if="collapsable && items.length"
      :id="ariaId"
      v-model:expanded="expanded"
      auto
      transition-name="expand-medicus-fast"
      borderless
      dense
    >
      <template #head>
        <span />
      </template>
      <div>
        <template v-for="(item, i) in items" :key="i">
          <SidebarGroupItem
            v-bind="item"
            :selected="
              item.selected ||
              (item.id && renderedComponent && item.id === renderedComponent.subnavItem)
            "
          />

          <q-separator v-if="item.separator" :key="`separator` + i" />
        </template>
      </div>
    </m-expander>
    <template v-else>
      <template v-for="(item, i) in items" :key="i">
        <SidebarGroupItem
          v-bind="item"
          :selected="
            item.selected ||
            (item.id && renderedComponent && item.id === renderedComponent.subnavItem)
          "
        />
        <q-separator v-if="item.separator" :key="`separator` + i" />
      </template>
    </template>
  </div>
</template>

<style lang="scss">
$hoverColour: #263751;
$activeColour: var(--theme-grey);
$bgItem: var(--theme-darker-blue);

.group {
  // -webkit-link: #fff;

  // padding-top: 8px;

  position: relative;
  // border-bottom: 1px solid rgba(132, 141, 156, 0.2);

  &.group-with-title .group-item {
    padding-top: 16px;

    padding-bottom: 13px;
    padding-left: 12px;
  }

  &.group-with-title::after {
    content: " ";
    position: absolute;
    width: 85%;
    left: 20px;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  &.group-with-title > a {
    &:hover {
      background: $hoverColour;
      text-decoration: underline;
    }
  }

  &:not(.group-small) {
    .router-link-active {
      .group-item-title {
        // font-weight: bold;
      }
      background: var(--theme-grey);
    }
  }

  .item-collapsable {
    cursor: pointer;
    width: 100%;

    &:hover,
    &:focus {
      background: $hoverColour;

      .group-item-notification {
        background: var(--theme-grey);
      }
    }
  }

  a {
    text-decoration: none;

    color: var(--grey-lightest) !important;

    &:hover {
      background: $hoverColour;
    }
  }

  .m-expander > * {
    padding: 0;
  }
}

.group-collapsable {
  .group-item-title {
    padding-left: 30px !important;
  }
}

.group-item {
  display: flex;

  padding: 0.9em 0;

  align-items: center;

  justify-content: space-between;
  text-decoration: none;

  padding-left: 24px;

  border-top: 1px solid rgba(132, 141, 156, 0.2);

  border-radius: 4px;

  &.router-link-active {
    background: $activeColour;

    .group-item-notification {
      background: $bgItem;
    }
  }

  &:hover {
    background: $hoverColour;

    .group-item-title {
      color: var(--grey-lightest);
      text-decoration: underline;
    }

    .group-item-notification {
      background: #46556a;
    }
  }
}

.group-item-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  align-items: center;
  border-radius: 4px;

  display: flex;

  /* White */

  color: var(--grey-lightest);

  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;

  &.router-link-active {
    .group-item-notification {
      background: $bgItem;
    }
  }

  &:hover {
    .group-item-notification {
      background: #46556a;
    }
  }
}

.group-title {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.2;
  border-radius: 4px;

  padding: 10px;

  // align-items: center;
  // gap: 0.5rem;
  justify-content: space-between;
}

.group-small {
  .group-item {
    // height: 26px;
    border-top: none;
    padding: 0.43em 0 !important;
  }

  .group-item-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;

    padding-left: 30px;
  }
}

.group-item-info-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 8px;
  margin-left: 0.3em;

  &:empty {
    display: none;
  }
}

.group-item-icon {
  display: flex;
  align-items: center;
  font-size: 20px;
}

.group-item-notification {
  padding: 0px 7.5px;

  background: var(--theme-grey);
  border-radius: 10px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: right;

  color: var(--grey-light);

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: center;
}

.show-more {
  cursor: pointer;
  // margin-top: 8px;

  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height */

  text-underline-offset: 3px;
  text-decoration-line: underline;

  /* White */

  color: var(--grey-lightest);

  display: flex;
  // justify-content: center;

  span {
    padding-left: 32px;
  }
}
</style>
