import { Router, parseQuery } from "vue-router";
export interface ParsedRoute {
  slideover: boolean;

  tenant?: string;

  /**
   * Path before UUID
   */
  componentPath: string;
  /**
   * First UUID
   */
  id?: string;

  /**
   * Everything before `?`
   */
  rawPath: string;

  /**
   * Fullpath passed to the parseRoute
   */
  fullPath: string;

  /**
   * everything after the first UUID
   */
  remainingPath: string;

  /**
   * Query object
   */
  query: Record<string, string | Array<string> | undefined>;
  /**
   * Query string
   */
  rawQuery?: string;

  child: {
    /**
     * Full child path equivalent to `remainingPath`
     */
    rawPath: string;
    /**
     * Everything after the first UUID
     */
    remainingPath: string;

    /**
     * Everything before the fist UUID
     */
    path: string;
    /**
     * First UUI
     */
    id?: string;
  };
}

export function parseRoute(fullPath: string): ParsedRoute {
  if (typeof fullPath !== "string") {
    return {} as any;
  }
  const [rawPath, rawQuery] = fullPath.replace(globalThis.location?.hash || "", "").split("?");
  if (IS_APP && import.meta.env.NODE_ENV !== "production") {
    if ((fullPath.match(/\?/g) || []).length > 2) {
      console.error("Invalid url provided, please only use 1 `?`", fullPath);
    }
  }

  const query = parseQuery(rawQuery ?? "");
  const slideover = query.slideover || query.modal;
  if (Array.isArray(slideover)) {
    throw new Error("unnexpected array on slideover/modal query");
  }

  const {
    tenant,
    id,
    left: componentPath,
    right: childRawPath,
  } = extractIdPath(slideover ? slideover : rawPath);

  const { id: childId, left: childPath, right: remainingPath } = extractIdPath(childRawPath);

  return {
    tenant,

    slideover: !!slideover,

    componentPath,
    id,

    rawPath,
    fullPath,
    remainingPath: childRawPath,

    query,
    rawQuery,

    child: {
      rawPath: childRawPath,
      path: childPath,
      remainingPath,

      id: childId,
    },
  };
}

export function buildMedicusUrl(url: string, overrideComponentPath?: string) {
  const { componentPath = "", id, child, query } = parseRoute(url);
  const fragments = componentPath.split("/").filter((x) => !!x);

  const context = fragments[0];
  const route = fragments.slice(1).join("/");

  const custom = (middle: string) => {
    let r = route;
    // fix to stop duplication of data in route e.g. tasks/data/data/.. & tasks/ui/data/...
    if (route.startsWith("data/")) {
      r = route.replace("data/", "");
    }
    return [context, middle, r].join("/");
  };

  const dataQuery = Object.assign({}, query, {
    noData: undefined,
    slideover: undefined,
    modal: undefined,
  });

  const dataRaw =
    `${custom("data")}${id !== undefined ? "/" + id : ""}` +
    `${child?.rawPath ? "/" + child.rawPath : ""}`;

  const ui = `${(IS_APP && import.meta.env.OVERRIDE_UI_URL) || ""}${custom("ui")}.vue`;
  return {
    custom,
    ui,
    componentPath: overrideComponentPath ?? componentPath,

    subnav: `${context}/subnav.vue`,
    data: dataRaw,
    query: dataQuery,
  };
}

export function extractIdPath(path: string) {
  path = path || "";
  const fragments = path.split("/");

  // tenant should always be the first `/{tenant}/**`
  const tenant = /^([0-9a-f]{6}|[0-9a-f]{16})$/.test(fragments[1]) ? fragments[1] : undefined;

  // remove tenant from code
  if (tenant) {
    fragments.splice(1, 1);
  }

  // after the first id
  const leftside = [];
  const rightside = [];

  let id: string | undefined = undefined;

  for (const f of fragments) {
    if (id) {
      rightside.push(f);
    } else {
      if (isUUID(f)) {
        id = f;
        continue;
      }
      leftside.push(f);
    }
  }

  return {
    id,

    tenant,

    left: leftside.join("/"),
    right: rightside.join("/"),
  };
}

// based on https://stackoverflow.com/a/14368860
export function parseQueryString(queryString: string) {
  queryString = queryString || "";
  const params: Record<string, string | string[] | undefined> = {};
  // remove preceding non-querystring, correct spaces, and split
  const qs = queryString
    .substring(queryString.indexOf("?") + 1)
    .replace(/\+/g, " ")
    .split("&");

  // march and parse
  for (let i = qs.length; i > 0; ) {
    const p = qs[--i];
    // allow equals in value
    const j = p.indexOf("=");
    // what if no val?
    if (j === -1) params[decodeURIComponent(p)] = undefined;
    else {
      let name = decodeURIComponent(p.substring(0, j));
      const value = decodeURIComponent(p.substring(j + 1));
      console.log();
      if (name.endsWith("[]")) {
        name = name.slice(0, -2);
        const a: string[] = Array.isArray(params[name]) ? (params[name] as any) : [];
        a.push(value);
        params[name] = a;
      } else {
        params[name] = decodeURIComponent(p.substring(j + 1));
      }
    }
  }

  return params;
}

export function toQueryString(obj: Record<string, any>) {
  const str = [];
  for (const p in obj)
    if (Object.prototype.hasOwnProperty.call(obj, p) && obj[p] !== undefined) {
      const v = obj[p];
      // php similar query
      if (Array.isArray(v)) {
        str.push(
          ...v.map((x) => {
            const param = p.endsWith("[]") ? p.slice(0, -2) : p;
            return encodeURIComponent(param) + "[]" + "=" + encodeURIComponent(x);
          }),
        );
      } else {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(v));
      }
    }
  return str.join("&");
}

export function isUUID(s: string) {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(s);
}

if (import.meta.vitest) {
  const { describe, it, expect } = import.meta.vitest;
  describe("isUUID", () => {
    it.each([
      "0189f4b3-02d7-7134-96a4-1a569330f6c6",

      //v1
      "ecd759fc-3aba-11ee-a826-3641a45e2da4",
      //v4
      "c87c0e72-9494-4a6d-aff6-4e86cc16db4d",
      //v6
      "1ee3abae-cd76-685c-80c3-3641a45e2da4",
      //v7
      "0189f4c1-fad5-72cb-af7e-3a2ab8400210",
      //v8
      "61626364-3132-8334-a162-636431323334",
    ])("should be valid %s", (uuid) => {
      expect(isUUID(uuid)).toBe(true);
    });

    it.each([
      // it should be case sensitive!
      "ABCD1234-ABCD-0000-0000-000011112222",
      "ecd759fc-3aba-11ee-a826-3641a45e2d",
      "ecd759fc3aba11eea8263641a45e2da4",
    ])("should be invalid %s", (uuid) => {
      expect(isUUID(uuid)).toBe(false);
    });
  });

  describe("toQueryString", () => {
    it("should convert to query string", () => {
      expect(toQueryString({ a: 1, b: 2, c: [1, 2, 3, 4] })).toBe(
        "a=1&b=2&c[]=1&c[]=2&c[]=3&c[]=4",
      );
    });
  });

  describe("parseQueryString", () => {
    it("should parse correctly", () => {
      expect(parseQueryString("a=1&b=2&c[]=1&c[]=2&c[]=3&c[]=4")).toStrictEqual({
        a: "1",
        b: "2",
        c: ["4", "3", "2", "1"],
      });
    });
  });
}

export function extractTenant(path: string) {
  return path.match(/^\/([0-9a-f]{6}|[0-9a-f]{16})\//)?.[0].slice(1, -1) ?? null;
}

export function toTenant(path: string, tenant: string) {
  const withTenant = path.indexOf(tenant + "/") >= 0;
  if (withTenant) {
    return path;
  }

  // tenant switcher
  if (extractTenant(path)) {
    return path;
  }

  if (path.startsWith("/")) {
    return `/${tenant}${path}`;
  }

  return path;
}

export function injectTenantToRoute(to: any, tenant: string, router: Router) {
  let path = typeof to === "string" ? to : typeof to === "object" ? to.path : undefined;
  if (path) {
    path = toTenant(path, tenant);

    if (typeof to === "string") {
      to = path;
    } else {
      to = {
        ...to,
        path,
      };
    }
  } else if (!to) {
    return to;
  } else if (to && "name" in to && to.name === "Home") {
    to.name = `${tenant}-${to.name}`;
  } else if (to.query) {
    to.path = router.currentRoute.value.path;
  }
  return to;
}
