<script lang="ts" setup>
import { computed, getCurrentInstance, provide, ref, watch } from "vue";
import { isObject, isString, promisedTimeout } from "vue-composable";
import { getFocussableElements } from "../../../utils/misc";
import MButton from "../MButton";
import MLayoutStack from "../MLayoutStack";

const props = defineProps<{
  items: Array<string | { label?: string; code: string; backText: string | (() => string) }>;

  hideBackButton?: boolean;
  headerClass?: string;
}>();

const emit = defineEmits({
  pageChange: (_page: string) => true,
});

const currentIndex = ref(0);

let state: any[] = [];

function goNext(s: any, currentState: any) {
  state[currentIndex.value] = currentState;
  ++currentIndex.value;
  state[currentIndex.value] = s;
}
function goPrev() {
  delete state[currentIndex.value];
  --currentIndex.value;
}

const current = computed(() => props.items[currentIndex.value]);

const currentPage = computed(() => {
  const s = current.value;
  if (isString(s)) {
    return s;
  }
  return s.code;
});

const backButtonText = computed(() => {
  const s = current.value;
  if (isString(s)) {
    return "Back";
  }
  return `${(isObject(s) && s.backText) || "Back"}`;
});
provide("goNextPage", goNext);
provide("goPrevPage", goPrev);

const proxy = getCurrentInstance()!.proxy!;

watch(
  currentPage,
  async (v) => {
    emit("pageChange", v);

    // we need to wait because it might take some time for everything to render
    await promisedTimeout(150);
    getFocussableElements(proxy.$el)
      .slice(currentIndex.value === 0 ? 0 : 1)
      .find((x) => !!x)
      ?.focus({
        focusVisible: true,
      });
  },
  {
    flush: "post",
  },
);
</script>
<template>
  <MLayoutStack>
    <slot :name="`pre:${currentPage}`" :state="state[currentIndex]" />
    <div
      v-if="currentIndex > 0 && !hideBackButton"
      class="flex flex-auto justify-between stepper--header"
      :class="[headerClass]"
    >
      <!-- <slot name="main:header" :next="goNext" :prev="goPrev" :name="currentPage" :item="current"> -->
      <MButton ghost @click="goPrev">
        <span aria-hidden="true">&lt; </span>{{ backButtonText }}
      </MButton>
      <!-- </slot> -->
      <slot :name="`action:${currentPage}`">
        <slot name="action" :current-page="currentPage" :current-index="currentIndex">
          <div />
        </slot>
      </slot>
    </div>
    <slot name="legend"></slot>
    <slot :name="currentPage" :next="goNext" :prev="goPrev" :state="state[currentIndex]" />
    <slot :name="`post:${currentPage}`" :state="state[currentIndex]" />
  </MLayoutStack>
</template>
