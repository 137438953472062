import { ComputedRef, WritableComputedRef, toRef } from "@vue/reactivity";
import { NOOP } from "@vue/shared";
import {
  InjectionKey,
  Ref,
  computed,
  getCurrentInstance,
  inject,
  onMounted,
  onUnmounted,
  provide,
  ref,
  useAttrs,
  watch,
} from "vue";
import { RefTyped } from "vue-composable";
import { validAttribute } from "../utils/misc";
import { useNamedValue } from "./namedValue";

export const namedValidators: Record<string, (a: any) => string | boolean> = {
  date: (v: any) => {
    return !v || !!Date.parse(v) || "Invalid date";
  },
  time: (v: any) => {
    return !v || /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(v) || "Invalid time";
  },
  minutes: (v: any) => {
    return !v || /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(v) || "Invalid time";
  },
};

const InGroupKey: InjectionKey<Ref<boolean>> = "inInputGroup" as any;
export function useInGroup() {
  return inject(InGroupKey, ref(false));
}
export function setInGroup(inGroup: Ref<boolean>) {
  return provide(InGroupKey, inGroup);
}

export function useInputWidth(props: { labelWidth?: string }): Ref<number | string | undefined> {
  const provided = inject<string>("labelWidth", "");
  return computed(() => {
    if (props.labelWidth) return props.labelWidth;
    return provided;
  });
}

export function useHorizontalLabel<
  T extends Readonly<{
    horizontal?: boolean;
    labelWidth?: string;
    forceHorizontalLabel?: boolean;
  }>,
>(props: T, horizontalGetter = (p: T) => p.horizontal) {
  const provided = inject<boolean>("labelHorizontal", false);
  const width = useInputWidth(props);
  const inGroup = useInGroup();
  return computed(() => {
    if (props.forceHorizontalLabel) return true;
    if (inGroup?.value) return false;
    const horizontal = horizontalGetter(props);
    if (typeof horizontal === "boolean") return horizontal;
    if (width.value) return true;
    return provided;
  });
}

export function usePlaceholder(props: { placeholder?: string }) {
  return toRef(props, "placeholder");
}

export interface ValidationItem {
  name?: string;
  // if the component is parent everything that starts with `name` will be sent
  parent?: boolean;
  validation: () => boolean;
  setErrors: (errors: string[], name?: string) => void;
  scrollIntoView: () => void;
}

export interface GroupValidationItem {
  name?: string;
  scrollIntoView: () => void;

  errors: ComputedRef<any[] | undefined>;
}

function hasRequiredValue(v: any) {
  if (v === null || v === "" || v === undefined) {
    return false;
  }
  if (Array.isArray(v) && v.length === 0) {
    return false;
  }
  return true;
}

export type InputRule = (v: any) => boolean | string;
export type InputRules = Array<InputRule>;

export function useInputRules<T = any>(
  props: {
    modelValue?: T;
    rules?: InputRules;
    requiredText?: string;
    showAllErrors?: boolean;
    name?: string;
    min?: string | number;
    max?: string | number;
    maxText?: string;
    minText?: string;
    uppercase?: boolean;

    maxlength?: number | string;
    maxlengthText?: string;

    debounce?: number | string;

    noValidation?: boolean;
  },
  onFocus?: () => void,
  rules: InputRules = [],
  isParent = false,
  requiredTextCb = () => props.requiredText,
  debounceMs?: RefTyped<number | string | undefined>,
  scrollIntoView?: () => void,
) {
  const attrs = useAttrs();
  const instance = getCurrentInstance();

  const value: WritableComputedRef<T> = useNamedValue(props, debounceMs);

  const validationController = inject<ValidationItem[]>("validationController", []);
  const groupController = inject<GroupValidationItem[] | undefined>(
    "groupValidationController",
    undefined,
  );
  const formTouched = inject<(touched: boolean) => void>("formTouched", NOOP);

  const customErrors = ref<string[]>([]);
  const touched = ref(false);

  const validationErrors = computed(() => {
    //there's some usages where noValidation is important,
    // eg: MSelect, will handle the validation
    if (props.noValidation) return [];
    if (validAttribute(attrs.required) && !hasRequiredValue(value.value)) {
      return [requiredTextCb()];
    }

    const minMaxValidations = [
      props.min && !Number.isNaN(+props.min)
        ? (v: string | number) => {
            if (!v) return true;
            const m = +props.min!;
            const vv = +v;

            return m > vv ? props.minText || `Value must be greater than ${m}` : "";
          }
        : false,
      props.max && !Number.isNaN(+props.max)
        ? (v: string | number) => {
            if (!v) return true;
            const m = +props.max!;
            const vv = +v;

            return m < vv ? props.maxText || `Value must be less than ${m}` : "";
          }
        : false,

      props.maxlength && !Number.isNaN(+props.maxlength)
        ? (v: string | number) => {
            if (!v) return true;
            const m = +props.maxlength!;
            if (Number.isNaN(m)) return true;
            const vv = String(v).length;

            return vv > m ? props.maxlengthText || `Character limit exceeded.` : "";
          }
        : false,
    ].filter(Boolean);

    const prules = props.rules || [];

    const errors = [...rules, ...prules, ...minMaxValidations]
      .map((x: any) => {
        return typeof x === "function"
          ? x(value.value)
          : typeof x === "string"
          ? namedValidators[x](value.value)
          : x;
      })
      .filter((x) => x === false || (!!x && x !== true));

    return props.showAllErrors ? errors : errors.slice(0, 1);
  });

  const errors = computed(() => {
    if (customErrors.value.length) {
      return customErrors.value;
    }
    if (touched.value) {
      return validationErrors.value;
    }
    return undefined;
  });

  const displayErrors = computed(() => {
    if (groupController) return [];
    return errors.value;
  });

  const valid = computed(() => {
    if (customErrors.value.length) {
      return false;
    }
    if (!touched.value) return true;
    if (attrs.required && !value.value) return false;
    return !errors.value?.length;
  });

  const validationItem: ValidationItem = {
    name: props.name,
    parent: isParent,
    validation() {
      touched.value = true;
      return !validationErrors.value?.length;
    },
    setErrors(e) {
      customErrors.value = e;
    },
    scrollIntoView() {
      if (scrollIntoView) {
        scrollIntoView();
      } else {
        instance?.proxy?.$el?.scrollIntoView?.();
      }
    },
  };
  validationController?.push(validationItem);

  const groupItem: GroupValidationItem = {
    name: props.name,
    scrollIntoView() {
      if (scrollIntoView) {
        scrollIntoView();
      } else {
        instance?.proxy?.$el?.scrollIntoView?.();
      }
    },
    errors: errors,
  };
  groupController?.push(groupItem);

  onMounted(() => {
    // const requestFocus = traverseParentProvided(instance?.proxy, (x) => x.requestFocus)?.requestFocus;
    const requestFocus = inject<(cb: () => void) => void>("requestFocus", false as any);
    if (requestFocus && onFocus) {
      requestFocus(onFocus);
    }
  });

  onUnmounted(() => {
    validationController?.splice(validationController?.indexOf(validationItem), 1);
    groupController?.splice(groupController?.indexOf(groupItem), 1);
  });

  watch(touched, () => {
    if (formTouched) {
      formTouched(true);
    }
  });

  // const removeTouchedWatch = watch(value, (v, e) => {
  //   if (v === e) {
  //     console.log("no actual changes");
  //     return;
  //   }
  //   console.log("changes", v, e);

  //   touched.value = true;
  //   removeTouchedWatch();
  // });

  return {
    value,
    valid,
    errors,
    displayErrors,

    formTouched,
    touched,

    validationErrors,
    customErrors,
  };
}
