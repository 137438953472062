import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import * as APIIam from "../api/iam";
import { usePromiseLazy } from "vue-composable";

export const useSetupAccount = defineStore("setupAccount", () => {
  const password = ref("");
  const passwordConfirm = ref("");

  const route = useRoute();

  const validResetKey = ref(true);

  const resetKey = computed(() =>
    validResetKey.value ? (route.params.resetKey as string) : undefined,
  );

  function validatePassword() {
    return APIIam.validatePassword({
      password: {
        password: password.value,
        passwordConfirm: passwordConfirm.value,
      },
    });
  }

  function resetPassword(otpCode: string) {
    return APIIam.resetPassword({
      password: {
        password: password.value,
        passwordConfirm: passwordConfirm.value,
      },
      resetKey: resetKey.value,
      otpCode,
    });
  }

  function validateResetKey() {
    return APIIam.resetPasswordQuery(resetKey.value)
      .then(({ status }) => {
        validResetKey.value = status === 200;
      })
      .catch(() => {
        validResetKey.value = false;
      });
  }

  return {
    password,
    passwordConfirm,

    resetKey,

    validatePassword: usePromiseLazy(validatePassword),
    resetPassword: usePromiseLazy(resetPassword),
    validateResetKey: usePromiseLazy(validateResetKey),

    mfaSecret: usePromiseLazy(APIIam.getMfaSecret),
    setupUser: usePromiseLazy(APIIam.setupUser),
  };
});
