import { alpha3ToAlpha2Map } from "./country";
import { parsePhoneNumberWithError } from "libphonenumber-js";
import { pinia, useAuth } from "../store";

export function formatPhone(e: string) {
  if (!e) return "";
  const auth = useAuth(pinia);
  // TODO this should be driven by organisation locale
  const country = alpha3ToAlpha2Map[auth.config?.country ?? "GBR"];
  const parsed = parsePhoneNumberWithError(e, country);
  if (parsed.country === country) {
    return parsed.formatNational();
  }
  return parsed.formatInternational({});
}
if (import.meta.vitest) {
  const { describe, test, expect, beforeEach } = import.meta.vitest;

  // zephyr test: Frontend: Correctly format phone number depending on the tenant
  describe("Correctly format phone number depending on the tenant", () => {
    let auth = useAuth(pinia);
    beforeEach(() => {
      auth = useAuth(pinia);
    });
    test.each([
      ["+441214960530", "GBR", "0121 496 0530"],
      ["+447755500767", "GBR", "07755 500767"],
      ["+448081570189", "GBR", "0808 157 0189"],
      ["+449098790971", "GBR", "0909 879 0971"],
      ["+443069990571", "GBR", "0306 999 0571"],
      ["+353835556299", "IRL", "083 555 6299"],
      ["+353895550833", "IRL", "089 555 0833"],
      ["+353895552821", "IRL", "089 555 2821"],
      ["+353855554008", "IRL", "085 555 4008"],
    ])("National %s on %s results %s", (input, country, result) => {
      auth.config!.country = country;
      expect(formatPhone(input)).toBe(result);
    });

    test.each([
      ["+441214960530", "IRL", "+44 121 496 0530"],
      ["+447755500767", "IRL", "+44 7755 500767"],
      ["+448081570189", "IRL", "+44 808 157 0189"],
      ["+449098790971", "IRL", "+44 909 879 0971"],
      ["+443069990571", "IRL", "+44 306 999 0571"],
      ["+353835556299", "GBR", "+353 83 555 6299"],
      ["+353895550833", "GBR", "+353 89 555 0833"],
      ["+353895552821", "GBR", "+353 89 555 2821"],
      ["+353855554008", "GBR", "+353 85 555 4008"],
      ["+351922555198", "GBR", "+351 922 555 198"],
    ])("International %s on %s results %s", (input, country, result) => {
      auth.config!.country = country;
      expect(formatPhone(input)).toBe(result);
    });

    test(" invalid phone number", () => {
      expect(() => formatPhone("+999878514245")).toThrow();
    });
  });
}
