<script setup lang="ts">
import { QIcon, QItemSection } from "quasar";
import { computed, getCurrentInstance, onMounted, watch } from "vue";
import { useFocusList } from "../../../../composables/focus-list";
import { randomID } from "../../../../utils/helpers";
import MItem from "../../MItem";
import SelectItem from "./SelectItem.vue";
import { GroupOption, ItemOption } from "./_types";

const props = defineProps<{
  modelValue?: string;
  items: Array<GroupOption | ItemOption>;

  name?: string;
  multiple?: boolean;

  value: any;

  selectedOption: ItemOption | null;
  selectedOptions: Array<ItemOption>;

  disabled?: boolean;
}>();

const emit = defineEmits({
  select: (_options: ItemOption) => true,
  escape: () => true,
  keydown: (_ev: KeyboardEvent) => true,

  "update:keydown": (_ev: (ev: KeyboardEvent) => void) => true,

  "update:modelValue": (_value: string) => true,
});

const { focusedItem, onKeydown, onRef, reset } = useFocusList(
  // just pay attention to ItemOption, we don't want to select the group options
  computed(() => {
    const items: Array<GroupOption | ItemOption> = [];
    for (const it of props.items) {
      if ("action" in it && it.action) {
        items.push(it);
      }
      if ("items" in it && it.items) {
        items.push(...it.items);
      } else {
        items.push(it);
      }
    }

    return items;
  }),
  {
    onSelect,
    onEscape,
    onKeydown(ev) {
      emit("keydown", ev);
    },
  },
  true,
  0,
);

onMounted(() => {
  emit("update:keydown", onKeydown);
});

function onSelect(option: ItemOption) {
  emit("select", option);
}

function onEscape() {
  emit("escape");
}

const { proxy } = getCurrentInstance();
watch(
  () => props.items,
  () => {
    reset();
    (proxy.$el as HTMLElement)?.parentElement?.scroll?.({ top: 0 });
  },
);

watch(focusedItem, (f) => {
  for (let gi = 0; gi < props.items.length; ++gi) {
    const item = props.items[gi];

    if ("items" in item && item.items) {
      for (let index = 0; index < item.items.length; ++index) {
        const it = item.items[index];
        if (it === f) {
          emit("update:modelValue", `${renderListId}:${gi}:${index}`);
          return;
        }
      }
    } else {
      if (item === f) {
        emit("update:modelValue", `${renderListId}:${gi}`);
        return;
      }
    }
  }
});

const renderListId = randomID("select-list-");
</script>
<template>
  <div class="select-render-list">
    <template v-for="(item, gi) in items" :key="`item-outer-${item.title}${item.label}`">
      <MItem v-if="'title' in item" dense class="m-select--group-title" no-clickable role="heading">
        {{ item.title }}
      </MItem>
      <MItem
        v-if="'action' in item && item.action"
        :ref="onRef"
        v-bind="item.action"
        clickable
        :disabled="props.disabled === true"
        dense
      >
        <q-icon v-if="item.action.icon" color="black" class="q-mr-sm" :name="item.action.icon" />
        <q-item-section> {{ item.action.label }} </q-item-section>
      </MItem>
      <template v-if="'items' in item">
        <SelectItem
          v-for="(option, index) in item.items"
          :id="`${renderListId}:${gi}:${index}`"
          :key="`${renderListId}:${gi}:${index}`"
          :ref="onRef"
          :item="option"
          :selected="
            multiple
              ? selectedOptions.findIndex((x) => x.value === option.value) !== -1
              : option.value === value
          "
          :focused="option === focusedItem"
          :disabled="props.disabled === true || option.disabled === true"
          :data-testid="
            $attrs['data-testid'] || name
              ? `${$attrs['data-testid'] || name}:${gi}:${index}`
              : undefined
          "
          @click="onSelect(option)"
        >
          <template v-if="$slots['option-section']" #section>
            <slot name="option-section" v-bind="{ option: item }" />
          </template>
          <template v-if="$slots['option-action']" #action>
            <slot name="option-action" v-bind="{ option: item }" />
          </template>
        </SelectItem>
      </template>
      <SelectItem
        v-else
        :id="`${renderListId}:${gi}`"
        :ref="onRef"
        :item="item"
        :disabled="props.disabled === true || item.disabled === true"
        :selected="
          multiple
            ? selectedOptions.findIndex((x) => x.value === item.value) !== -1
            : item.value === value
        "
        :focused="item === focusedItem"
        :data-testid="
          $attrs['data-testid'] || name ? `${$attrs['data-testid'] || name}:${gi}` : undefined
        "
        @click="onSelect(item)"
      >
        <template v-if="$slots['option-section']" #section>
          <slot name="option-section" v-bind="{ option: item }" />
        </template>
        <template v-if="$slots['option-action']" #action>
          <slot name="option-action" v-bind="{ option: item }" />
        </template>
      </SelectItem>
    </template>
  </div>
</template>
<style>
.select-render-list {
  .m-select--group-title {
    font-family: Arial, sans-serif;
    font-style: normal;
    font-size: 14px;

    font-weight: bold;
    color: var(--text-color);
    padding-left: var(--gap-4);
    margin-top: var(--gap-2);
  }

  &:has(.m-select--group-title) .q-item:not(.m-select--group-title) {
    padding-left: var(--gap-6);
  }
}
</style>
