import { debounce } from "quasar";
import axios from "../utils/axios";
import { buildMedicusUrl } from "./route";

interface PermissionItem {
  url: string;
  method: string;
}
interface PermissionResultItem {
  url: string;
  authorised: boolean;
  method: string;
}

const requesting = new Map<
  string,
  { item: PermissionItem; callback: (authorised: boolean) => void }
>();

function makePermissionRequest(items: PermissionItem[]): Promise<PermissionResultItem[]> {
  return axios.post("access-check", items).then((x) => x.data);
}

const doRequest = debounce(async () => {
  const items = new Map(requesting);
  requesting.clear();
  const r = await makePermissionRequest(Array.from(items.values()).map((x) => x.item));

  for (const item of r) {
    const key = buildKey(item.url, item.method);
    const t = items.get(key)!;
    t.callback(item.authorised);
  }
}, 10);

/**
 *  Clears the url from unwanted values
 * @param url
 */
function cleanUrl(url: string) {
  const route = buildMedicusUrl(url);
  return route.data;
}

function buildKey(url: string, method: string) {
  return `${url}:${method}`;
}

function checkPermission(url: string, method: string = "GET") {
  if (IS_APP === false) {
    return Promise.resolve(true);
  }
  return new Promise<boolean>((res) => {
    // if the URL does not require data we shouldn't check for permission
    if (~url.indexOf("noData=true")) return res(true);
    if (url.startsWith("http")) return res(true);

    url = method === "GET" ? cleanUrl(url) : url;
    const key = buildKey(url, method);
    let r = requesting.get(key);
    if (!r) {
      r = {
        item: { url, method },
        callback: res,
      };
      requesting.set(key, r);
    } else {
      const c = r.callback;
      r.callback = (x) => {
        c(x);
        res(x);
      };
    }

    doRequest();
  });
}

export function hasPermission(url: string | undefined, method: string = "GET"): Promise<boolean> {
  if (!url) return Promise.resolve(false);
  return checkPermission(url, method);
}
