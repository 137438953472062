<script setup lang="ts">
import { ref, watch } from "vue";
import { deepClone, usePromiseLazy } from "vue-composable";
import axios from "../../../../utils/axios";
import MCreateForm from "../../../medicus/MCreateForm";
import MInputGroup from "../../../medicus/MInputGroup";
import MDate from "../../../medicus/inputs/MDate";
import MDateInput from "../../../medicus/inputs/MDateInput/MDateInput.vue";
import MInput from "../../../medicus/inputs/MInput";
import MSelect from "../../../medicus/inputs/MSelect";
import MCheckbox from "../../../medicus/inputs/MCheckbox";
import { Patient } from "../../../medicus/scheduling/MAppointmentCard";
import { AdvanceSearch } from "./helpers";

const props = defineProps<{
  search: AdvanceSearch;
}>();

const emit = defineEmits({
  submit: (_items: any[], _state: any) => true,
  cancel: () => true,
});

const state = ref<AdvanceSearch>({});

watch(
  () => props.search,
  (p) => (state.value = deepClone({}, p)),
  { immediate: true },
);

const ageRange = ref(!!props.search.fromAge || !!props.search.toAge);

const isValid = ref(true);

function onBeforeSubmit() {
  const validKeys = Object.keys(state.value);
  return (isValid.value = !!validKeys.find((x) => !!(state.value as any)[x]));
}

const { exec } = usePromiseLazy((search: AdvanceSearch) =>
  axios
    .get<{ patients: Array<Patient> }>("/patient/advanced-patient-finder", {
      cancelRequestId: "contact-advanced-search",
      params: search,
    })
    .then((x) => x.data),
);

// function cleanState(obj: Record<string, any>) {
//   Object.keys(obj).forEach((key) => {
//     if (!obj[key]) {
//       delete obj[key];
//     }
//   });
//   return obj;
// }
function doSearch() {
  return exec(state.value).then((x) => emit("submit", x?.patients || [], state.value));
}

const data = await axios
  .get<{
    genderOptions: Array<{ value: string; label: string }>;
    staffOptions: Array<{ value: string; label: string }>;
    isSafeGuardingService: boolean;
  }>("/patient/data/advanced-patient-finder")
  .then((x) => x.data);

function toggleDob() {
  ageRange.value = !ageRange.value;
  state.value.dob = "";
  state.value.fromAge = "";
  state.value.toAge = "";
}
</script>

<template>
  <MCreateForm
    class="scroll-area q-pb-md"
    submit-label="Find patient"
    :before-submit="onBeforeSubmit"
    no-close
    @submit="doSearch"
    @cancel="$emit('cancel')"
  >
    <MInput
      v-model="state.nhsNumber"
      label="NHS number"
      placeholder=""
      medium
      mask="### ### ####"
    />

    <MInput
      v-model="state.patientName"
      label="Patient name"
      data-testid="search-patient-input"
      placeholder=""
    />

    <MSelect
      v-model="state.gender"
      label="Gender"
      :options="data.genderOptions"
      placeholder=""
      clearable
    />

    <div v-if="ageRange" class="flex">
      <MInputGroup
        label="Age range"
        :actions="[{ label: 'Enter specific date of birth', click: toggleDob }]"
        actions-left
        horizontal
      >
        <MInput v-model="state.fromAge" type="number" decimal-places="0" placeholder="" />
        <span>to</span>
        <MInput v-model="state.toAge" type="number" decimal-places="0" placeholder="" />
      </MInputGroup>
    </div>

    <MDateInput
      v-else
      v-model="state.dob"
      label="Date of birth"
      placeholder=""
      :actions="[{ label: 'Search using age range', click: toggleDob }]"
      actions-left
    />

    <MInput v-model="state.postCode" label="Postcode" placeholder="" medium />
    <div v-if="!data.isSafeGuardingService" class="flex">
      <MInputGroup label="Seen between" horizontal>
        <MDate v-model="state.seenFrom" />
        <span>and</span>
        <MDate v-model="state.seenTo" />
      </MInputGroup>
    </div>

    <MSelect
      v-if="!data.isSafeGuardingService"
      v-model="state.seenBy"
      label="Seen by"
      :options="data.staffOptions"
      placeholder=""
      clearable
    />

    <MCheckbox v-model="state.includeInactivePatients" label="Include inactive patients" />

    <div v-if="!isValid" class="error-text">
      At least one filter criteria must be applied to search
    </div>
  </MCreateForm>
</template>
