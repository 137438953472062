import { pinia, useAuth } from "../store";
import CountryNameList from "./country";

export interface Address {
  country: string;
  line1: string;
  line2?: string;
  line3?: string;
  locality?: string;
  administrativeArea?: string;
  postalCode: string;
  pafAddressKey?: Address;
}

export function isValidPostcode(postcode: string) {
  const pattern = /^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$/;

  return pattern.test(postcode);
}

export function formatAddress(address: Address, separator = ",") {
  const auth = useAuth(pinia);

  const country =
    auth.config?.country === address.country
      ? undefined
      : CountryNameList.find((x: any) => x.value === address.country)?.label ?? address.country;

  return [
    address.line1,
    address.line2,
    address.line3,
    address.locality,
    address.administrativeArea,
    address.postalCode,
    country,
  ]
    .filter(Boolean)
    .join(separator);
}

if (import.meta.vitest) {
  const { describe, test, expect, beforeEach } = import.meta.vitest;

  describe("Address", () => {
    let auth = useAuth(pinia);
    let defaultAddress: Address;

    beforeEach(() => {
      auth = useAuth(pinia);

      defaultAddress = {
        country: "country",
        line1: "line1",
        line2: "line2",
        line3: "line3",
        locality: "locality",
        administrativeArea: "administrativeArea",
        postalCode: "postalCode",

        pafAddressKey: {
          country: "country",
          line1: "line1",
          line2: "line2",
          line3: "line3",
          locality: "locality",
          administrativeArea: "administrativeArea",
          postalCode: "postalCode",
        },
      };
    });

    test("Format the address correctly", () => {
      auth.config!.country = "GBR";

      expect(formatAddress(defaultAddress)).toMatchInlineSnapshot(
        '"line1,line2,line3,locality,administrativeArea,postalCode,country"',
      );
    });

    test("Format with a custom separator", () => {
      auth.config!.country = "GBR";

      expect(formatAddress(defaultAddress, "|")).toMatchInlineSnapshot(
        '"line1|line2|line3|locality|administrativeArea|postalCode|country"',
      );
    });

    describe("Currently hide or show country depending on the tenant", () => {
      test.each([
        ["United Kingdom", "GBR"],
        ["Ireland", "IRL"],
        ["Portugal", "PRT"],
        ["France", "FRA"],
      ])("Country %s on %s not show country", (country, tenantCountry) => {
        auth.config!.country = tenantCountry;
        expect(
          formatAddress({
            line1: "line1",
            line2: "line2",
            postalCode: "test",
            country: tenantCountry,
          }),
        ).not.contain(country);
      });

      test.each([
        ["United Kingdom", "GBR", "ITL"],
        ["Ireland", "IRL", "ITL"],
        ["Portugal", "PRT", "ITL"],
        ["France", "FRA", "ITL"],
      ])("Country %s on %s show country", (countryDescription, country, tenantCountry) => {
        auth.config!.country = tenantCountry;
        expect(
          formatAddress({
            line1: "line1",
            line2: "line2",
            postalCode: "test",
            country,
          }),
        ).contain(countryDescription);
      });
    });
  });
}
