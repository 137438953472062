import { App } from "vue";

import { ClosePopup, Loading, Quasar, Ripple } from "quasar";
import langGB from "quasar/lang/en-GB";
import quasarIconSet from "quasar/icon-set/fontawesome-v6";

// Import icon libraries
// import "@quasar/extras/fontawesome-v6/fontawesome-v6.css";
import "@fortawesome/fontawesome-pro/css/fontawesome.css";
import "@fortawesome/fontawesome-pro/css/light.css";
import "@fortawesome/fontawesome-pro/css/solid.css";
import "@fortawesome/fontawesome-pro/css/regular.css";

// Import Quasar css
import "quasar/src/css/index.sass";

// overrides
import "./../css/app.css";

import { router } from "../router";
import { pinia } from "../store";

import { componentPlugin } from "./component";
import { globalPlugin } from "./global";
import { thirdPartyPlugins } from "./thirdparties";
import { tenantPlugin } from "./tenant";
import { updatePlugin } from "./update";
import { browserVersionPlugin } from "./browserVersion";
import { warningHandlerPlugin } from "./warningHandler";
import { nhsSigningService } from "./nhsSigningService";

export default (loadComponents = true) => ({
  install(app: App) {
    app.use(pinia);
    app.use(globalPlugin());
    if (loadComponents) {
      app.use(router);
    }

    app.use(Quasar, {
      lang: langGB,
      iconSet: quasarIconSet,
      components: [],
      plugins: {
        Loading,
      }, // import Quasar plugins and add here
      directives: {
        ClosePopup,
        Ripple,
      },
    });

    if (loadComponents) {
      app.use(componentPlugin());
    }
    app.use(tenantPlugin());
    app.use(thirdPartyPlugins());

    if (IS_APP && !import.meta.env.DEV) {
      app.use(updatePlugin());
    }

    if (loadComponents) {
      app.use(browserVersionPlugin(router));
    }

    // only on dev and uat
    if (loadComponents && (import.meta.env.DEV || location.host.startsWith("uat."))) {
      app.use(warningHandlerPlugin());
    }

    app.use(nhsSigningService());
  },
});
