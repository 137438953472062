import { RouteRecordRaw } from "vue-router";
import { pinia, useAuth, usePanicButton, useTenant } from "../store";
import { showSnackbar } from "../utils/snackbar";
import { useSetupAccount } from "../store/resetPassword";

export const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: () => import("../layouts/AuthLayout"),

    beforeEnter(to) {
      const auth = useAuth(pinia);
      if (to.meta.mfa) {
        if (auth.hasMFA) {
          return true;
        } else {
          return {
            name: "login",
          };
        }
      }
      if (to.meta.onlyWhenLoggedOut && auth.isLoggedIn) {
        return auth.doRedirect();
      }
    },
    redirect: { name: "login" },
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("../pages/Login"),
        meta: { public: true, onlyWhenLoggedOut: true, title: "Log in to Medicus" },
      },
      {
        path: "/logout",
        name: "logout",
        redirect: () => {
          const auth = useAuth();
          auth.logout();
          return { path: "/login" };
        },
      },

      {
        path: "/choose-workspace",
        name: "tenants",
        component: () => import("../pages/Tenants.vue"),
        async beforeEnter() {
          const auth = useAuth(pinia);
          try {
            (await auth.settingsPromise) ?? auth.refreshUserInfo();
          } catch {
            //ignore
          }
          const tenants = Object.keys(auth.tenants ?? {});

          switch (tenants.length) {
            case 0: {
              return {
                name: "no-workspace-access",
              };
            }
            case 1: {
              if (
                auth.user.rooms?.length > 0 ||
                auth.user.sites?.length > 0 ||
                auth.user.adHocLocations?.length > 0
              ) {
                return true;
              }

              return {
                name: "homepage",
                params: {
                  tenant: tenants[0],
                },
              };
            }
            default:
              return true;
          }
        },
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          title: "Choose Your Workspace",
          redirect: false,
        },
      },
      {
        path: "/no-workspace-access",
        name: "no-workspace-access",
        component: () => import("../pages/NoWorkspaceAccess.vue"),
        meta: {
          title: "No Workspaces to Access",
          redirect: false,
        },
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("../pages/ForgotPassword"),
        meta: { public: true, onlyWhenLoggedOut: true, title: "Forgot Your Password?" },
      },
      {
        path: "/users/password/reset/:resetKey",
        name: "reset-password",
        component: () => import("../pages/ResetPassword.vue"),
        props: true,
        meta: { public: true, onlyWhenLoggedOut: true, title: "Change Your Password" },
      },
      {
        path: "/users/password/reset-otp/:resetKey",
        name: "reset-password-otp",
        component: () => import("../pages/ResetPasswordOtp"),
        beforeEnter(to) {
          if (!to.params.resetKey) {
            return {
              name: "forgot-password",
            };
          }
          const store = useSetupAccount();
          if (!store.password || !store.passwordConfirm) {
            return {
              name: "reset-password",
              params: to.params,
            };
          }

          return true;
        },
        meta: { public: true, onlyWhenLoggedOut: true, title: "Enter Token" },
      },
      {
        //users/setup/018adb38-ecde-701b-865b-a4186c114108/john@doe.com ?
        path: "/users/setup",
        name: "setup-account",
        component: () => import("../pages/SetupAccount"),
        props: (to) => to.query,
        meta: { public: true, onlyWhenLoggedOut: true, title: "Setup Account" },
        async beforeEnter(to) {
          const store = useSetupAccount();
          const r = await store.mfaSecret.exec(
            to.query.username as string,
            to.query.setupCode as string,
          );
          if (!r) {
            return {
              name: "link-expired",
            };
          }
          return true;
        },
      },

      {
        path: "/users/password/create/:resetKey",
        name: "create-password",
        component: () => import("../pages/CreatePassword"),
        props: true,
        meta: {
          public: true,
          onlyWhenLoggedOut: true,
          title: "Create a Password",
          forceLogout: true,
        },
      },
      {
        path: "/users/link-expired",
        name: "link-expired",
        component: () => import("../pages/LinkExpired"),
        meta: { public: true, onlyWhenLoggedOut: true, title: "Link Expired" },
      },

      {
        path: "/mfa",
        name: "mfa",
        component: () => import("../pages/Mfa"),
        meta: {
          mfa: true,
          public: true,
          title: "Enter Token",
        },
      },
      {
        path: "/recovery",
        name: "recovery-code",
        component: () => import("../pages/RecoveryCode"),
        meta: {
          public: true,
          mfa: true,
          title: "Enter Recovery Code",
        },
      },
    ],
  },

  {
    path: "/microsoft-oauth2-callback.html",
    name: "microsoft_oauth2_callback",
    redirect: (to) => {
      const state = JSON.parse(to.query.state as string);

      return {
        path: `/${state.tenantId}/communication/configuration/microsoft-graph-api-oauth2-callback`,
        query: {
          code: to.query.code,
          actionType: state.actionType,
          expectedAccountUsername: state.expectedAccountUsername,
        },
      };
    },
  },

  {
    path: "/unsupported-browser",
    name: "unsupported",
    component: () => import("../pages/Unsupported"),
    meta: { public: true, onlyWhenLoggedOut: false },
  },
  // {
  //   path: "/meeting",
  //   name: "meeting",
  //   component: () => import("../pages/Meeting.vue"),
  //   beforeEnter(_, _1, next) {
  //     const item = sessionStorage.getItem("meeting");
  //     if (item) {
  //       next();
  //     } else {
  //       next({
  //         name: "meeting-room",
  //       });
  //     }
  //   },
  //   meta: {
  //     public: true,
  //     // onlyWhenLoggedOut: true,
  //     redirect: false,
  //   },
  // },
  {
    path: "/:tenant",
    name: "tenant",
    meta: { public: false, onlyWhenLoggedOut: false, noSubnav: true, redirect: false },
    component: () => import("../layouts/MedicusLayout"),
    async beforeEnter(to) {
      const auth = useAuth(pinia);
      if (to.name === "403") return true;
      if (auth.tenants?.[`${to.params.tenant}`]) {
        auth.selectedTenant = to.params.tenant.toString();

        try {
          await auth.settingsPromise;
        } catch {
          // ignore
        }

        return true;
      }
      if (auth.isLoggedIn) {
        showSnackbar({
          message: "No access to the tenant.",
        });
        return {
          name: "tenants",
        };
      }
      return auth.logout(true);
    },
    redirect(to) {
      const auth = useAuth(pinia);
      // if (to.name === "403") return true;
      if (auth.tenants?.[`${to.params.tenant}`]) {
        auth.selectedTenant = to.params.tenant.toString();
        auth.refreshUserInfo();

        usePanicButton();

        return {
          name: "homepage",
          params: to.params,
        };
      }
      return {
        name: "tenants",
      };
    },
    children: [
      {
        path: "",
        name: "homepage",
        async beforeEnter(to) {
          const auth = useAuth(pinia);
          try {
            await auth.settingsPromise;
          } catch {
            // ignore
          }

          if (!to.params.tenant) {
            return {
              name: "tenants",
            };
          }

          const fallback = useTenant().isSafeguardingService
            ? `/${to.params.tenant}/safeguarding/cases-homepage`
            : `/${to.params.tenant}/scheduling/homepage`;

          return auth.config?.homepageUrl?.startsWith("/") ? auth.config.homepageUrl : fallback;
        },
        component: {},
      },
      {
        path: "patient/patient/care-record/:patientId",
        name: "patient-record",
        async beforeEnter(to) {
          const auth = useAuth(pinia);
          try {
            await auth.settingsPromise;
          } catch {
            // ignore
          }

          if (!to.params.tenant) {
            return {
              name: "tenants",
            };
          }

          if (useTenant().isSafeguardingService) {
            return `/${to.params.tenant}/patient/patient/administrative-record/${to.params.patientId}`;
          }

          return true;
        },
        component: () => import("../pages/Medicus"),
        props: (to) => ({
          tenant: to.params.tenant,
          componentPath: `patient/patient/care-record/${to.params.patientId}`,
          query: to.query,
        }),
      },
      {
        path: "tasks/:taskList/task-list",
        name: "task-list",
        async beforeEnter(to) {
          const auth = useAuth(pinia);
          try {
            await auth.settingsPromise;
          } catch {
            // ignore
          }

          if (!to.params.tenant) {
            return {
              name: "tenants",
            };
          }

          return true;
        },
        component: () => import("../pages/Medicus"),
        props: (to) => ({
          tenant: to.params.tenant,
          componentPath: `tasks/${to.params.taskList}/task-list`,
          query: to.query,
          absoluteURL: `tasks/ui/task-list.vue`,
        }),
      },
      {
        path: "chat/:chatType/list",
        name: "chat-list",
        async beforeEnter(to) {
          const auth = useAuth(pinia);
          try {
            await auth.settingsPromise;
          } catch {
            // ignore
          }

          if (!to.params.tenant) {
            return {
              name: "tenants",
            };
          }

          return true;
        },
        component: () => import("../pages/Medicus"),
        props: (to) => ({
          tenant: to.params.tenant,
          componentPath: `chat/${to.params.chatType}/list`,
          query: to.query,
          absoluteURL: `chat/ui/list.vue`,
        }),
      },
      {
        path: "403",
        name: "403",
        component: () => import("../pages/403.vue"),
      },
      {
        path: ":componentPath(.*)",
        name: "dynamic",
        component: () => import("../pages/Medicus"),
        async beforeEnter(to) {
          const auth = useAuth(pinia);
          if (!auth.isLoggedIn) {
            return auth.logout(to.fullPath);
          }

          try {
            await auth.settingsPromise;
          } catch {
            //ignore
          }
          if (!to.params.componentPath) {
            return {
              name: "homepage",
              params: to.params,
            };
          }
          return true;
        },
        props: (to) => ({
          tenant: to.params.tenant,
          componentPath: to.params.componentPath,
          query: to.query,
        }),
      },
    ],
  },

  // {
  //   path: "/403",
  //   name: "403",
  //   redirect: (to) => {
  //     const tenant = /*lastTenantId ||*/ location.pathname.split("/")[1];
  //     if (!tenant) {
  //       console.log("tenant not found on the url, sending to tenants");
  //       return {
  //         name: "tenants",
  //         redirect: location.pathname,
  //       };
  //     }

  //     if (typeof to === "string") return { name: `${tenant}-403` };
  //     return {
  //       ...to,
  //       path: undefined,
  //       name: `${tenant}-403`,
  //     };
  //   },
  // },
];

if (IS_APP && import.meta.env.DEV) {
  routes.push({
    path: "/__dev",
    component: () => import("../pages/__dev_types.vue"),
  });
}
