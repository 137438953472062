import { App } from "vue";

export default function () {
  return {
    install(app: App) {
      app.config.globalProperties.$dayGridPlugin = "dayGridPlugin";
      app.config.globalProperties.$listPlugin = "listPlugin";
      app.config.globalProperties.$resourceTimelinePlugin = "resourceTimelinePlugin";
      app.config.globalProperties.$resourceTimeGridPlugin = "resourceTimeGridPlugin";
      app.config.globalProperties.$resourceDayGridPlugin = "resourceDayGridPlugin";
      app.config.globalProperties.$timeGridPlugin = "timeGridPlugin";
      app.config.globalProperties.$interactionPlugin = "interactionPlugin";
    },
  };
}
