import { Component, Ref, ref } from "vue";
import { Router } from "vue-router";
export interface DialogObject {
  title: string;
  text: string;

  type: string;
  class?: string;

  router: Router;
  contentClass?: string;
  wrapperClass?: string;
  bgGrey?: boolean;

  zIndex?: number | string;

  noButtons?: boolean;
  noCancel?: boolean;
  transient?: boolean;
  fillHeight?: boolean;

  cancelLabel: string;
  okLabel: string;
  okColor?: string;

  component?: string | Component;
  componentProps?: Record<string, any>;

  resolve: (a?: any) => void;
  reject: (a?: any) => void;
}

let r: Ref<DialogObject> | undefined = undefined;

export function useDialog(): Ref<DialogObject | undefined> {
  return r || (r = ref() as any);
}
