<script lang="ts" setup>
import { Key } from "ts-key-enum";
import { getCurrentInstance, nextTick, onMounted } from "vue";
import { useHorizontalLabel, useInputRules } from "../../../composables/horizontalLabel";
import { getFocussableElements } from "../../../utils/misc";
import MLabeledText from "./../MLabeledText";
import MRadio from "./../inputs/MRadio";
import MValidationComponent from "./../inputs/MValidationComponent";
import type { RadioItem } from "./types";

const props = defineProps({
  label: String,
  name: String,
  helper: String,
  instructions: String,

  horizontal: {
    type: Boolean,
    default: undefined,
  },

  twoColumns: {
    type: Boolean,
  },

  labelWidth: String,

  rules: Array,

  horizontalLabel: {
    type: Boolean,
    default: undefined,
  },

  modelValue: {
    type: null,
  },
  items: Array as () => Array<RadioItem>,

  disable: Boolean,
  showAllErrors: Boolean,

  requiredText: String,

  fullWidth: Boolean,
});

const { value: actualValue, displayErrors } = useInputRules(
  props,
  () => {
    nextTick(() => {
      // (proxy.$el as HTMLElement).querySelector<HTMLElement>(".q-radio")?.focus();
    });
  },
  undefined,
  undefined,
  () => props.requiredText || `Choose an option for '${props.label}'`,
);
const horizontalLabel = useHorizontalLabel(props, (x) => x.horizontalLabel);

function onKeyDown(ev: KeyboardEvent) {
  const element = (ev.target as HTMLElement).parentElement!;
  const parent = element.parentElement;

  const first = parent?.firstElementChild?.querySelector(".q-radio") as HTMLElement | null;
  const last = parent?.lastElementChild?.querySelector(".q-radio") as HTMLElement | null;

  switch (ev.key) {
    case Key.Tab: {
      ev.preventDefault();
      ev.stopPropagation();
      const focussabled = getFocussableElements(document.body, true);

      if (ev.shiftKey) {
        const index = focussabled.indexOf(first);
        focussabled[index - 1]?.focus();
      } else {
        const index = focussabled.indexOf(last);
        focussabled[index + 1]?.focus();
      }
      break;
    }
    case Key.ArrowUp:
    case Key.ArrowLeft: {
      ev.preventDefault();
      const sibbling = element.previousElementSibling as HTMLElement;
      if (!sibbling?.parentElement) {
        last?.focus();
      } else {
        // @ts-expect-error
        sibbling.querySelector(".q-radio")?.focus();
      }
      break;
    }
    case Key.ArrowDown:
    case Key.ArrowRight: {
      ev.preventDefault();
      const sibbling = element.nextElementSibling as HTMLElement;
      if (!sibbling?.parentElement) {
        first?.focus();
      } else {
        // @ts-expect-error
        sibbling.querySelector(".q-radio")?.focus();
      }
      break;
    }
  }
}

const proxy = getCurrentInstance().proxy!;
onMounted(() => {
  const el = proxy.$el as HTMLElement;
  const items = Array.from(el.querySelectorAll(`[role="radio"]`));
  items.forEach((x, i) => {
    x.setAttribute("radio-index", i.toString());
  });
});
</script>
<template>
  <m-validation-component :errors="displayErrors">
    <m-labeled-text
      class="m-radio-group"
      v-bind="$attrs"
      container="fieldset"
      label-container="legend"
      text-container="div"
      :horizontal="horizontalLabel"
      :label-width="labelWidth"
      :label="label"
      :required="$attrs.required"
      :helper="helper"
      :instructions="instructions"
      :data-testid="$attrs['data-testid'] || name || undefined"
    >
      <div
        class="flex flex-col radio-group-content"
        :class="{ horizontal, 'w-full': fullWidth, 'two-columns': props.twoColumns }"
        @keydown.capture="onKeyDown"
      >
        <slot>
          <m-radio
            v-for="(item, i) in items"
            :key="i"
            v-model="actualValue"
            :val="item.value"
            :label="item.label"
            :class="item.class"
            :style="item.style"
            :disable="disable || item.disable"
            :data-testid="
              item['data-testid'] || $attrs['data-testid'] || name
                ? item['data-testid'] || `${$attrs['data-testid'] || name}:${item.value}`
                : undefined
            "
          />
        </slot>
      </div>
    </m-labeled-text>
  </m-validation-component>
</template>

<style lang="scss">
.m-radiogroup {
  min-height: 56px;
}
.m-radio-group {
  border: none;
  margin: 0;
  padding: 0;
  .radio-group-content {
    // > *:not(:first-child) {
    //   // margin-top: 0.5em;
    // }

    &.horizontal {
      flex-direction: row;
      justify-content: space-evenly;
      > * {
        flex: 1 1 auto;
        // margin-top: 0.5em;
      }
    }
  }

  &.horizontal {
    align-items: initial;

    > .label {
      margin-top: 2px !important;
    }

    > .text {
      flex: 0 0 auto;
      margin-top: 0 !important;
    }
  }

  &.horizontal > .label {
    align-self: flex-start;
    margin: 0;
  }
}
</style>
