import { Router } from "vue-router";
import { pinia } from "../store";
import useBrowser from "../store/browser";

export function browserVersionPlugin(router: Router) {
  return {
    install() {
      const browser = useBrowser(pinia);
      let isValid = browser.supported.supported;
      if (!isValid) {
        router.push({
          name: "unsupported",
        });
      }

      router.beforeEach((to) => {
        if (isValid || to.name === "unsupported") return;

        isValid = browser.supported.supported;
        if (isValid) return;
        setTimeout(() => {
          router.push({
            name: "unsupported",
          });
        }, 10);
      });
    },
  };
}
