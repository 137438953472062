import {
  ComponentCustomOptions,
  ComponentOptions,
  RenderFunction,
  compile,
  h,
  inject,
  markRaw,
  provide,
  ref,
  watch,
} from "vue";
import { NO_OP, isFunction, isPromise, usePromiseLazy, isUndefined } from "vue-composable";
import { buildMedicusUrl, toQueryString } from "./route";
import * as GLOBAL_API from "../api/medicus";
import merge from "deepmerge";
import axios from "axios";
import { NOOP } from "@vue/shared";
import { router } from "../router";
import { showDialog } from "./dialog";
import { pathAccessor, randomID } from "./helpers";
import type { AxiosResponse } from "axios";
import { startLoading, stopLoading } from "../composables/focus";
import bus from "../utils/bus";

const componentPromises = new Map<string, Promise<any>>();

const localComponent: Record<string, () => Promise<any>> = {
  "clinical/ui/prescription/modal/create-prescription.vue": () =>
    import("../components/dynamic/prescription/CreatePrescription/CreatePrescription.vue"),
  "clinical/ui/prescription/issue-one-off.vue": () =>
    import("../components/dynamic/prescription/IssueOneOff/IssueOneOff.vue"),
  "clinical/ui/prescription/prescribe-again.vue": () =>
    import("../components/dynamic/prescription/PrescribeAgain/PrescribeAgain.vue"),
  "clinical/ui/prescription/re-authorise.vue": () =>
    import("../components/dynamic/prescription/ReAuthorise/ReAuthorise.vue"),
  "clinical/ui/prescription/modify.vue": () =>
    import("../components/dynamic/prescription/ModifyPrescription/ModifyPrescription.vue"),
};

// export const ErrorCodeEnum = {
//   InactivePatientAccess: "inactive-patient-access",
// } as const;

// export type ErrorCode = typeof ErrorCodeEnum[keyof typeof ErrorCodeEnum];
export enum ErrorCode {
  InactivePatientAccess = "inactive-patient-access",
  RestrictedPatientRecord = "restricted-patient-record",
}

export interface ErrorBase {
  errorCode: ErrorCode;
  errorReason: string;
}

export interface InactivePatientAccessError extends ErrorBase {
  errorCode: ErrorCode.InactivePatientAccess;
  patientId: string;
}

export interface RestrictedPatientAccessError extends ErrorBase {
  errorCode: ErrorCode.RestrictedPatientRecord;
  patientId: string;
}

export type MedicusError = ErrorBase | InactivePatientAccessError | RestrictedPatientAccessError;

// mainly to be used with dialog, to set the zIndex to be higher than
// component loading
export let handlingError = false;

// just to prevent showing multiple dialogs
let isShowingPermission = false;

async function handleForbiddenError(
  _err: MedicusError,
  response: AxiosResponse,
  appGlobalProperties?: Record<string, any>,
) {
  if (response.status !== 403) return false;
  const error = response.data as MedicusError;

  async function goBack(awaitResolve: boolean = false) {
    if (history.length === 0) {
      await router.push({ name: "homepage" });
    } else {
      if (false === awaitResolve) {
        router.back();

        return;
      }

      // wait until the route is actually resolved
      const p = new Promise((res) => {
        const remove = router.afterEach(() => {
          remove();
          res(true);
        });
      });
      router.back();
      await p;
    }
  }

  switch (error.errorCode) {
    case ErrorCode.InactivePatientAccess: {
      await new Promise(() => {
        appGlobalProperties.$openModal(
          `/tasks/patient-privacy-officer/request-access/${response.data.patientId}`,
          {
            props: {
              onClose: goBack,
            },
          },
        );

        bus.on("dialog:incorrect-permissions:close", () => {
          bus.off("dialog:incorrect-permissions:close");
          goBack();
        });
      });

      return true;
      break;
    }
    case ErrorCode.RestrictedPatientRecord:
      await new Promise<void>((res) =>
        showDialog({
          title: "Restricted Patient Record",
          text: error.errorReason,
          noCancel: true,
          okLabel: "Dismiss",
          okColor: "secondary",
          type: "dialog",
          zIndex: 9999,
        }).onFinally(res),
      );
      break;
  }

  return await goBack(true);
}

export async function loadComponent(
  url: string,
  noData = false,
  overrideComponentPath?: string,
  absoluteUrl?: string,
  cancelRequestId?: string,
  appGlobalProperties?: Record<string, any>,
  handleError?: (
    error: MedicusError,
    response: AxiosResponse,
    appGlobalProperties?: Record<string, any>,
  ) => PromiseLike<boolean | void> | boolean | void,
): Promise<(ComponentOptions & ComponentCustomOptions) | undefined> {
  const medicusUrl = buildMedicusUrl(url, overrideComponentPath);
  const uiEndpointURL = absoluteUrl || medicusUrl.ui;

  const isLocal = uiEndpointURL in localComponent && localComponent[uiEndpointURL];

  const dataPromise =
    noData || isLocal
      ? Promise.resolve(NO_OP)
      : GLOBAL_API.load<object>(medicusUrl.data, {
          params: medicusUrl.query,
          showError: true,
          cancelRequestId,
        }).then((x) => x.data);

  function getUI(uiUrl: string) {
    let p = componentPromises.get(uiUrl);
    if (!p) {
      // if is local component we just don't make the query
      p = isLocal
        ? isLocal()
        : GLOBAL_API.load<string>(uiUrl, { showError: true, cancelRequestId }).then((x) => x.data);
      componentPromises.set(uiEndpointURL, p);

      p.then(() => {
        componentPromises.delete(uiEndpointURL);
      }).catch(() => {
        componentPromises.delete(uiEndpointURL);
      });
    }

    return p;
  }

  try {
    startLoading();
    const [pComponent, pData] = await Promise.allSettled([getUI(uiEndpointURL), dataPromise]);

    if (pComponent.status === "rejected") {
      throw pComponent.reason;
    }
    const component = pComponent.value;

    // if is local we don't need to patch the component, because
    // that's already handled by the build
    if (isLocal) {
      // this component should be already be a valid component to be used
      return markRaw(component.default);
    }

    const id = uiEndpointURL;
    const hash = hashCode(component);

    const { script, styles } = createScript(component, id);
    if (styles) {
      // if the file is updated we should clear it
      if (hashedComponents.get(id) !== hash) {
        clearStyles(id);
      }
      hashedComponents.set(id, hash);

      // Do cleanup
      const _beforeMount = script.beforeMount;
      script.beforeMount = function () {
        _beforeMount?.call(this);
        createStyle(id, styles);
      };

      const _beforeUnmount = script.beforeUnmount;
      script.beforeUnmount = function () {
        removeStyle(id);
        _beforeUnmount?.call(this);
      };
    }

    const _beforeMount = script.beforeMount;
    script.beforeMount = async function () {
      const x = _beforeMount?.call(this);
      if (isPromise(x)) {
        await x;
      }
      this.$emit("before-mount");
    };

    script.emits = Array.isArray(script.emits)
      ? [...script.emits, "before-mount"]
      : Object.assign({}, script.emits, { "before-mount": () => true });

    // script.inheritAttrs = "inheritAttrs" in script ? script.inheritAttrs : false;

    // in the case where the data promise is rejected,
    // we call the handleError
    // if it returns `false` it should go back history
    // if it returns `true` it should request the data
    // if it returns an object it should use that object instead
    let data = pData.status === "fulfilled" ? pData.value : {};
    if (pData.status === "rejected") {
      const response = pData.reason.response as AxiosResponse;
      let handled = false;

      handleError = handleError ?? handleForbiddenError;

      if (handleError && response.data.errorCode) {
        handlingError = true;
        const d = await handleError(response.data, response, appGlobalProperties);
        if (d === true) {
          handled = true;
          data = await GLOBAL_API.load<object>(medicusUrl.data, {
            showError: true,
            cancelRequestId,
          }).then((x) => x.data);
        }

        if (d === void 0) {
          return undefined;
        }
      }

      if (!handled) {
        if (response?.status === 401) {
          // Unauthorized should be handled in the axios response interceptor and trigger a logout
          return;
        }

        if (response?.status === 403) {
          if (isShowingPermission || handlingError) return;
          isShowingPermission = true;
          showDialog({
            title: "Incorrect Permissions",
            text: "Sorry, you don't have the permission to perform this action.",
            noButtons: true,
            type: "dialog",
          }).onFinally(() => {
            isShowingPermission = false;
            router.back();
          });
        }

        throw pData.reason;
      }
    }

    if (script.patientInfo) {
      // @todo i dont think this is used as we have the actual patient banner component
      //    this has a bug where it wont correctly apply the badges
      //    should be removed for the existing component if unnecessary for other UI elements..
      // get the correct accessor
      const accessor =
        typeof script.patientInfo === "string"
          ? pathAccessor(script.patientInfo)
          : {
              get: script.patientInfo as () => string,
            };

      const {
        result: patient,
        exec,
        promise,
      } = usePromiseLazy((patientId?: string) =>
        patientId
          ? GLOBAL_API.load(`/patient/data/patient/patient-banner/${patientId}`, {
              cancelRequestId: randomID("patient-info-id"),
            }).then((x) => x.data)
          : undefined,
      );
      const patientProxy = ref({ ...data, ...appGlobalProperties });
      const removePatientInfoWatch = watch(
        () => accessor.get.call(patientProxy.value, patientProxy.value),
        exec,
        {
          immediate: true,
        },
      );

      const _unmounted = script.unmounted;
      script.unmounted = function () {
        removePatientInfoWatch();
        _unmounted?.call(this);
      };

      const _beforeMount = script.beforeMount;
      script.beforeMount = async function () {
        patientProxy.value = this;
        provide("patientBanner", patient);

        return _beforeMount.call(this);
      };

      if (promise.value) await promise.value;
    }

    script.__data = script.data;
    script.data = function () {
      const d = script.__data?.call(this);
      return merge(d, data);
    };

    if (!noData) {
      const _provide = script.provide;
      script.provide = function () {
        const formTouched = inject<(touched: boolean) => void>("formTouched", NOOP);
        const renderTree = inject<string[]>("renderTree", []);
        renderTree.push(url);
        return {
          ignoreFields: "",
          ...(typeof _provide === "function" ? _provide.call(this) : _provide),
          formData: this.$data,

          formTouched,

          renderTree: [...renderTree],
        };
      };
    }

    // inject openPage by default
    const _inject = script.inject;
    script.inject =
      Array.isArray(_inject) || !_inject
        ? [...((_inject as Array<any>) ?? []), "openPage"]
        : {
            ..._inject,
            openPage: "openPage",
          };

    return markRaw(script);
  } catch (e) {
    handlingError = false;
    window.captureException?.(e);

    console.error("Error loading component", url, e);
    throw e;
  } finally {
    stopLoading();
  }
}

export async function loadComponentData(url: string, overrideComponentPath?: string) {
  startLoading();
  const medicusUrl = buildMedicusUrl(url, overrideComponentPath);

  const queryString = toQueryString(medicusUrl.query);
  const dataEndpointURL = medicusUrl.data + (queryString != "" ? `?${queryString}` : "");
  const cancelRequestId = medicusUrl.data;

  return GLOBAL_API.load(dataEndpointURL, {
    showError: false,
    cancelRequestId,
  })
    .then((x) => x.data)
    .catch((e) => {
      if (!isUndefined(e.response)) {
        handleForbiddenError({}, e.response as AxiosResponse);
      }
    })
    .finally(() => {
      stopLoading();
    });
}

if (import.meta.vitest) {
  const { describe, test, vi, expect, beforeEach } = import.meta.vitest;
  describe("loadComponentData", () => {
    const spy = vi.spyOn(GLOBAL_API, "load").mockResolvedValue({ data: "test" } as any);

    beforeEach(() => {
      spy.mockClear();
    });

    test("Frontend: General: loadComponentData: Component data should create cancelRequestId ", async () => {
      await expect(loadComponentData("test")).resolves.toBe("test");

      expect(spy).toHaveBeenCalledWith(
        "test/data/",
        expect.objectContaining({
          cancelRequestId: "test/data/",
          showError: false,
        }),
      );

      await loadComponentData("test?id=test");
      expect(spy).toHaveBeenNthCalledWith(
        2,
        "test/data/?id=test",
        expect.objectContaining({
          cancelRequestId: "test/data/",
          showError: false,
        }),
      );
    });
  });
}

/**
 * parses SFC into groups
 */
export function getFoundGroups(data: string | null) {
  // in test sometimes data.match is not a function
  if (!isFunction(data?.match))
    return {
      script: "",
      template: "",
      styles: [],
    };
  const regex = /<template>(?<template>[^]*)<\/template>[^]*?<script>(?<script>[^]*?)<\/script>/;
  const found = data.match(regex)!;

  const styles: string[] =
    data
      .match(/<style>([\0-\uFFFF]*?)<\/style>/g)
      ?.map((x: any) => x.replace("<style>", "").replace("</style>", "")) || [];

  const groups = found.groups!;
  return {
    ...groups,
    styles,
  } as { styles: string[] } & Record<string, string>;
}

export function createScript(
  data: any,
  filePath: string,
): {
  template: string;
  styles: string[];
  groups: Record<string, string>;
  script: ComponentOptions & ComponentCustomOptions;
} {
  const found = getFoundGroups(data);

  const { template, script: foundScript, styles, ...groups } = found;

  let rawScript = foundScript.replace("export default", "").trim();
  if (rawScript.endsWith(";")) {
    rawScript = rawScript.slice(0, -1);
  }
  const script: ComponentOptions = Function('"use strict";return (' + rawScript + ")")();

  const modifiers = ["", "computed", "methods"];
  const ignoredFunctions = new Set(["subnavData", "patientBanner"]);

  for (const modifier of modifiers) {
    const o = modifier ? script[modifier] : script;

    // it doesn't contain the modifier
    if (!o) continue;

    for (const key in o) {
      const iterator: () => void = o[key];
      if (!isFunction(iterator)) continue;
      if (ignoredFunctions.has(key)) continue;
      o[key] = function (...args: any[]) {
        try {
          const r = iterator.call(this, ...args);
          if (isPromise(r)) {
            r.catch((e: any) => {
              // if is a cancel request, do not log
              if (axios.isCancel(e)) return;
              throw new Error(
                `${filePath}:${modifier}${(modifier && ":") || ""}${key} - ${e?.toString()}`,
              );
            });
          }
          return r;
        } catch (e: any) {
          // if is a cancel request, do not log
          if (axios.isCancel(e)) {
            return;
          }

          const exception = new Error(
            `${filePath}:${modifier}${(modifier && ":") || ""}${key} - ${e?.toString()}`,
          );
          throw exception;
        }
      };
    }
  }

  if ("params" in script && script.watch) {
    const keys = new Set(Object.values(script.params));
    const newWatches = new Map<string, any>();
    for (const key of Object.keys(script.watch)) {
      if (!keys.has(key)) {
        continue;
      }
      const _watch = script.watch[key];
      // @ts-expect-error immediate is not declared
      if (_watch.immediate) continue;
      delete script.watch[key];

      // There's an odd interaction with $watch and `params`
      // to prevent bugs and the watch being called multiple times
      // This just moves them after the `mount`
      console.info(`moved "${key}" watch to "mount" because found it on the params`);
      newWatches.set(key, _watch);
    }

    if (newWatches.size > 0) {
      const _mounted = script.mounted;
      script.mounted = function () {
        // @ts-expect-error newWatches tsconfig fixable issue
        for (const [key, f] of newWatches) {
          const [fun, opts] = typeof f === "object" && "handler" in f ? [f.handler, f] : [f, {}];
          if (typeof fun === "function") {
            this.$watch(key, fun.bind(this), opts);
          } else if (typeof fun === "string") {
            this.$watch(key, this[fun], opts);
          }
        }
        _mounted?.call(this);
      };
    }
  }

  let _render: RenderFunction = () => h("div", "error");
  try {
    _render = compile(template, {});
  } catch (e) {
    console.error("Error compiling template", template);
  }

  script.render = _render;

  return {
    script,
    template,
    styles,
    groups,
  };
}

/**
 * Loaded styles per component
 * key: component-path
 * value: styles-loaded
 */
const loadedStyles = new Map<string, string[]>();
/**
 * Get the component count, this will be used to clear old components
 */
const componentCount = new Map<string, number>();
/**
 * Get the hash per components this is used to use the cached or to override
 */
const hashedComponents = new Map<string, number>();

export function createStyle(_id: string, styles: string[]) {
  const created: Array<string> = [];
  let i = 0;

  const count = (componentCount.get(_id) ?? 0) + 1;
  componentCount.set(_id, count);

  loadedStyles.set(_id, created);

  for (const content of styles) {
    const style = document.createElement("style");
    const id = `${_id}-${i++}`;
    style.setAttribute("data-id", id);
    created.push(id);

    style.innerText = content;

    document.head.append(style);
  }
  return created;
}

export function removeStyle(_id: string) {
  const count = componentCount.get(_id);
  if (!count) return;
  if (count === 1) {
    clearStyles(_id);
    componentCount.delete(_id);
    return;
  }
  componentCount.set(_id, count - 1);
}

export function clearStyles(_id: string) {
  const styles = loadedStyles.get(_id);
  if (!styles) return;

  const els = styles.map((x) => document.querySelector(`[data-id="${x}"]`));
  for (const el of els) {
    el?.remove();
  }
  loadedStyles.delete(_id);
}

function hashCode(str: string) {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
