<template>
  <m-validation-component
    :errors="parentGroupValidation ? undefined : errors"
    :class="$attrs.class"
    :actions="$attrs.actions"
    :actions-left="$attrs['actions-left']"
  >
    <m-labeled-text
      class="m-input-group"
      v-bind="{ ...$attrs, actions: undefined, actionsLeft: undefined, class: undefined }"
      :class="{
        'horizontal-content': horizontal,
        'horizontal-label': horizontalLabel,
        'flex-items': flex,
        'flex-start': left,
        'no-label': noLabel,
      }"
      container="fieldset"
      label-container="legend"
      text-container="div"
      :helper="helper"
      :instructions="instructions"
      :label="label"
      :force-horizontal-label="forceHorizontalLabel"
      :no-override-width="!forceHorizontalLabel"
      :content-class="[horizontal && 'items-center', noLabel && 'span-pad-label', 'gap-3']"
    >
      <template v-if="$slots.helper" #helper>
        <slot name="helper" />
      </template>
      <template v-if="grid" #text>
        <div
          class="text"
          :style="{
            display: 'grid',
            'grid-column-gap': '0.75rem',
            'grid-row-gap': '0.75rem',
            'grid-template-columns': `repeat(${grid === true || grid === '' ? '2' : grid}, 1fr)`,
          }"
          @blur="$emit('blur', $event)"
        >
          <slot />
        </div>
      </template>
      <slot v-if="!grid" />
    </m-labeled-text>
  </m-validation-component>
</template>
<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<script lang="ts" setup>
import { computed, inject, provide, reactive, ref, watch } from "vue";
import MLabeledText from "./../MLabeledText";

import { debounce } from "quasar";
import MValidationComponent from "../inputs/MValidationComponent";
import {
  GroupValidationItem,
  setInGroup,
  useHorizontalLabel,
} from "./../../../composables/horizontalLabel";

const props = defineProps({
  label: String,

  horizontal: {
    type: Boolean,
    default: undefined,
  },

  forceHorizontalLabel: Boolean,

  flex: Boolean,
  left: Boolean,

  noInGroup: Boolean,

  grid: [Boolean, String],

  /**
   * Removes the label from the child inputs
   */
  noLabel: Boolean,

  helper: String,

  instructions: String,
});

const inInputGroup = computed(() => !props.noInGroup);
setInGroup(inInputGroup);

const horizontalLabel = useHorizontalLabel(props);

const parentGroupValidation = inject<GroupValidationItem[] | undefined>(
  "groupValidationController",
  undefined,
);
const groupValidation: GroupValidationItem[] = parentGroupValidation
  ? parentGroupValidation
  : reactive([]);
// if (parentGroupValidation === empty) {
// }

// const groupValidation = inject("groupValidationController", reactive<GroupValidationItem[]>([]));
const errors = ref();

if (groupValidation) {
  provide("groupValidationController", groupValidation);

  watch(
    groupValidation,
    debounce((g) => {
      errors.value = Array.from(new Set(g.map((x) => x.errors).flat()).values()).filter(Boolean);
    }, 1),
    {
      flush: "post",
    },
  );
}
</script>
<style lang="scss">
.m-input-group {
  > .text {
    margin-top: 0 !important;

    word-wrap: normal;
    white-space: normal;
  }

  &.no-label > .text {
    .label {
      display: none;
    }
  }

  &.horizontal > .label {
    // margin-top: 12px;
    padding-right: 1em;
    margin-top: 0 !important;
  }

  &.horizontal-label {
    display: flex;

    // flex-direction: column;
    align-items: unset;

    .text {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      flex-wrap: wrap;
    }

    > .label {
      align-self: flex-start !important;
    }

    &.text > * {
      flex: 1 1 auto;

      margin: 0.5em;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.horizontal-content {
    // align-items: center;
    & > * {
      display: flex;
      flex-direction: row !important;

      justify-content: space-between;

      // > * {
      //   margin: 0.5em;
      // }
    }
    .label span:empty {
      &::before {
        content: "-";
        color: transparent;
      }
    }

    .label {
      align-self: flex-start !important;
    }

    &.flex-items > * > * {
      flex: 1 1 auto;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &.flex-start > * {
      gap: 0.5em;
      justify-content: flex-start;

      > span {
        flex: 0 0 auto;
      }
    }

    > .text > span {
      // height: 56px;
      // justify-self: center;
      // margin-top: 45px !important;
      // margin-top: 25px;

      // bottom: 0;
      align-self: flex-end;
      margin-bottom: 10px;

      // flex: 0 0 auto !important;

      font-size: 14px;
      line-height: 150%;
      color: var(--text-color);
    }

    .span-pad-label > span {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .q-field__native {
    padding-top: 23px;
    height: 45px;
  }

  .q-field__label {
    margin-left: 12px;
    top: 12px;

    color: var(--grey-darkest);

    transform: translateY(-40%);

    .label {
      font-size: 12px;
    }
  }

  .m-select .m-input--input-box {
    padding-top: 0px;
  }
}
</style>
