<script setup lang="ts">
import { useFormValue } from "../../../../composables/namedValue";
import { QCheckbox } from "quasar";
import { randomID } from "../../../../utils/helpers";
import { ref } from "vue";

const props = defineProps<{
  modelValue?: any;
  name?: string;
  label?: string;
}>();
const templateValue = useFormValue(props);

const isFocus = ref(false);

function handleKeyup() {
  isFocus.value = true;
}
function handleBlur() {
  isFocus.value = false;
}

const forId = randomID("checkbox");
</script>
<template>
  <q-checkbox
    v-model="templateValue"
    class="m-checkbox"
    :class="{ 'medicus-outline': isFocus }"
    :name="name"
    :disable="$attrs.disabled"
    v-bind="{ ...$attrs, 'onUpdate:modelValue': undefined }"
    :aria-labelledby="$attrs['aria-labelledby'] || forId"
    @keyup="handleKeyup"
    @blur="handleBlur"
  >
    <slot :id="forId">
      <label :id="forId" class="checkbox-label">
        {{ label }}
      </label>
    </slot>
  </q-checkbox>
</template>
<style lang="scss">
.m-checkbox {
  border: 3px solid transparent;

  // pointer-events: none;
  .checkbox-label {
    cursor: default;
    user-select: none;
    margin-left: 8px;
    display: flex;
  }

  .q-checkbox__inner {
    pointer-events: initial;
    align-self: flex-start;
    margin-top: 2px;
  }

  .q-checkbox__inner--falsy > .q-checkbox__bg {
    border: 1px solid var(--grey-lightest-non-text);
    background: white;
  }

  .q-checkbox__bg {
    border-radius: 4px;
    // padding: 2px 0;

    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    color: var(--theme-blue);
    border: 1px solid var(--theme-accent-blue);
  }

  &:not(.disabled):focus {
    .q-checkbox__inner:before {
      background: none !important;
    }

    .q-checkbox__bg {
      color: var(--theme-blue);
    }
  }

  &[disabled="disabled"],
  &.disabled {
    opacity: 1 !important;
    .q-checkbox__label {
      color: var(--grey);
    }
    .q-checkbox__inner--truthy {
      color: var(--theme-blue) !important;
      opacity: 0.4;
    }

    .q-checkbox__inner:not(.q-checkbox__inner--truthy):not(.q-checkbox__inner--falsy) {
      .q-checkbox__bg {
        color: var(--theme-blue) !important;
        background: var(--theme-blue) !important;
      }
    }
  }

  .q-checkbox__label {
    font-size: 14px;
    line-height: 150%;
    font-weight: 400;
    color: var(--text-color);
  }

  .q-checkbox__inner {
    width: 18px;
    height: 18px;
    min-width: 18px;
    padding: 3px;

    .q-checkbox__svg {
      width: 10px !important;
      height: 10px !important;
      margin: auto;
    }
  }
}
</style>
