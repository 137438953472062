<script lang="ts" setup>
import { QBtn, QIcon } from "quasar";
import { computed, inject, onUpdated, ref, watch } from "vue";
import { NO_OP, useEvent } from "vue-composable";
import type { MenuType } from "../../../layouts/MedicusLayout/SidebarMenu";
import MedicusLogo from "../../../svg/MedicusLogo.vue";
import AppSearch from "../../misc/AppSearch";
import Chat from "../../misc/Chat";
import Calendar from "../../misc/Calendar";
import { usePanicButton } from "../../../store/panicButton";
import MLayoutStack from "../MLayoutStack";
import SidebarGroup from "./SidebarGroup";
import SidebarUser from "./SidebarUser";
import { useDebouncedThrottledFunction } from "../../../composables/throttleDebounce";
import { useTenant } from "../../../store";

const props = withDefaults(
  defineProps<{
    items?: Array<any>;
    groups?: Array<any>;
    scrollText?: string;
    maxShowItems?: number;
    collapsable?: boolean;

    title: string;
  }>(),
  {
    scrollText: "More items",
  },
);

defineEmits({
  open: (_menu: MenuType) => true,
});

const panicButtonStore = usePanicButton();

const canScrollTop = ref(false);
const canScrollBottom = ref(false);
const opened = ref(false);

const refPanicButton = ref<QBtn | null>(null);

const supportHref = computed(() => {
  if (useTenant().isSafeguardingService) {
    return "https://medicus-safeguarding.zendesk.com/hc/en-gb"; // Safeguarding Support URL
  } else {
    return "https://medicus-health.zendesk.com/hc/en-gb"; // Default Support URL
  }
});

const visibleGroups = computed(() => {
  if (props.groups) return props.groups;

  const groupObject = (props.items ?? []).reduce((p, c) => {
    const group = c.group || c.title || "";
    if (group) {
      if (p[group]) {
        p[group].items.push(c);
      } else {
        p[group] = {
          maxShowItems: props.maxShowItems,
          items: [],
          ...c,
        };
      }
    } else if (!group) {
      p[c.label] = {
        ...c,
        items: [],
        title: c.label,
      };
      return p;
    }

    return p;
  }, {});

  return Object.values(groupObject) as any[];
});
const hasGrouping = computed(
  () => !!visibleGroups.value.find((x) => x.title && x.items && x.items.length),
);

const contentEl = ref<HTMLDivElement>();

function checkScroll(scroller?: HTMLElement) {
  if (!scroller) return;

  const isTooSmall = scroller.clientHeight <= 200;
  if (isTooSmall) {
    canScrollBottom.value = canScrollTop.value = false;
    return;
  }
  canScrollBottom.value =
    scroller.scrollTop === 0 && scroller.scrollHeight === scroller.clientHeight
      ? false
      : scroller.scrollHeight > scroller.clientHeight + scroller.scrollTop + 10;

  canScrollTop.value =
    scroller.scrollTop < 20 && scroller.scrollHeight === scroller.clientHeight
      ? false
      : scroller.scrollTop > 20;
}

useEvent(contentEl, "scroll", (e) => checkScroll(e.target as HTMLElement), {
  passive: true,
});
useEvent(window, "resize", () => contentEl.value && checkScroll(contentEl.value), {
  passive: true,
});

watch(contentEl, checkScroll);

onUpdated(() => checkScroll(contentEl.value));

function scroll(top: boolean) {
  contentEl.value?.scrollBy({
    top: 200 * (top ? -1 : 1),
    behavior: "smooth",
  });
}

const openMenu = inject<(menu: MenuType) => void>("openMenu", NO_OP);
const isOnline = inject<boolean>("isOnline", true);

function onWheel(e: WheelEvent) {
  contentEl.value?.scrollBy({
    top: e.deltaY,
    behavior: "smooth",
  });
}

const {
  debouncedThrottled: sendPanicAlertDebouncedThrottled,
  cancelDebouncedThrottled: _cancelSendPanicAlertDebouncedThrottled,
} = useDebouncedThrottledFunction(panicButtonStore.sendPanicAlert, 800, 800, true);
</script>
<template>
  <teleport to="#sidenav">
    <nav :class="['m-sidenav', { opened }]" role="navigation" aria-label="Sub-navigation">
      <div class="sidenav-logo">
        <medicus-logo white />
      </div>
      <section class="top-nav">
        <m-layout-stack gap="0" class="overflow-auto">
          <q-btn :to="{ name: 'homepage' }" flat no-caps dense>
            <m-layout-stack horizontal center full>
              <q-icon name="fa-solid fa-house" />
              <span>Homepage</span>
            </m-layout-stack>
          </q-btn>

          <AppSearch />

          <Chat />

          <Calendar />

          <q-btn flat no-caps dense @click="openMenu('menu')">
            <m-layout-stack horizontal center full>
              <q-icon name="fa-solid fa-grid" />
              <span>Modules</span>
            </m-layout-stack>
          </q-btn>
        </m-layout-stack>
      </section>
      <div class="sidenav-title">
        <slot name="title">
          <div v-if="title" class="sidenav-title--title">{{ title }}</div>
          <div v-else>
            <!-- SPACING  -->
          </div>
        </slot>
      </div>
      <div ref="contentEl" class="sidenav-content">
        <div v-if="$slots.prepend" class="subnav-prepend">
          <slot name="prepend" />
        </div>
        <sidebar-group
          v-for="g in visibleGroups"
          :key="g.title"
          v-bind="g"
          :small="!!(g.items && g.items.length) && hasGrouping"
          :collapsable="collapsable"
        />
      </div>

      <section class="bottom-nav">
        <div class="sidenav-support">
          <q-btn title="Support" :href="supportHref" target="_bank" flat no-caps dense>
            <m-layout-stack horizontal full>
              <q-icon name="fa-regular fa-circle-question" />
              <span>Support</span>
            </m-layout-stack>
          </q-btn>
        </div>
        <div class="sidenav-user">
          <sidebar-user @click="openMenu('user')" />
        </div>
        <div class="sidenav-panic-button">
          <q-btn
            ref="refPanicButton"
            flat
            no-caps
            dense
            :disable="
              !!panicButtonStore.serverData?.panicButtonDisabledReason || isOnline === false
            "
            @click="sendPanicAlertDebouncedThrottled"
          >
            <m-layout-stack horizontal full center>
              <div
                class="panic-button"
                :class="{
                  activated: panicButtonStore.isPanicButtonFlashing,
                  'has-responders': panicButtonStore.isSomeoneResponding,
                }"
              />
              <span>Panic Button</span>
            </m-layout-stack>
          </q-btn>
          <m-tooltip
            v-if="
              refPanicButton &&
              (panicButtonStore.serverData?.panicButtonDisabledReason || isOnline === false)
            "
            :text="
              panicButtonStore.serverData?.panicButtonDisabledReason ||
              'Panic button disabled because you are offline.'
            "
            :scroll-target="refPanicButton.$el"
          />
        </div>
      </section>

      <div
        v-if="canScrollTop"
        class="scroll-container top cursor-pointer q-mini-drawer-hide"
        aria-hidden="true"
        @click="scroll(true)"
        @wheel="onWheel"
      >
        <p>
          <q-icon name="fa-solid fa-arrow-up" class="q-mr-sm" /> <span>{{ scrollText }}</span>
        </p>
      </div>
      <div
        v-if="canScrollBottom"
        class="scroll-container bottom cursor-pointer q-mini-drawer-hide"
        aria-hidden="true"
        @click="scroll(false)"
        @wheel="onWheel"
      >
        <p>
          <q-icon name="fa-solid fa-arrow-down" class="q-mr-sm" /> <span>{{ scrollText }}</span>
        </p>
      </div>
    </nav>
  </teleport>
</template>
<style lang="scss">
// .q-drawer--mini > .sidenav {
//   padding: 0;
// }
.m-sidenav {
  color: var(--grey-lightest);

  background: var(--theme-darkest-blue);

  height: 100%;
  max-height: max(100vh, 100%);

  width: 100%;
  // transition: width 0.2s;

  // width: 0;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  .sidenav-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    flex: 0 0 auto;
    margin: 0 15px;

    > svg {
      height: 22px;
    }
  }
  .sidenav-title {
    margin: 0px 10px 8px 10px;
    display: flex;
    align-items: center;

    .sidenav-title--title {
      color: var(--grey-darker);

      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
    }
  }

  .top-nav {
    margin: 0px 10px 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid var(--theme-darker-blue);
  }

  .sidenav-content {
    position: relative;
    flex: 1 1 auto;

    overflow: auto;

    padding: 0 10px;

    .subnav-prepend {
      margin-bottom: 0.5rem;
      .m-input {
        border-radius: 4px;
        color: var(--grey-darker);
        .q-field__control::before {
          background-color: var(--theme-grey);
        }
        .q-icon {
          color: var(--grey-darker);
        }

        input {
          color: var(--grey-lightest) !important;
        }
      }
    }
  }

  .bottom-nav {
    justify-self: flex-end;
    border-top: 2px solid var(--theme-darker-blue);
    padding-top: 10px;
    margin: 0px 10px 10px;
  }

  .sidenav-support {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidenav-panic-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .sidenav-user {
    display: flex;
    align-items: center;

    // justify-content: center;
  }
  .sidenav--content {
    padding: 0 15px;
    min-width: 230px;
  }

  .group-with-title:not(:first-child) {
    padding-top: 0;
  }

  .top-nav,
  .sidenav-support,
  .sidenav-panic-button {
    .q-btn {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      border-radius: 4px;

      flex: 1 1 auto;

      display: flex;
      .q-icon {
        font-size: 18px !important;
      }

      .panic-button {
        width: 24px;
        aspect-ratio: 1 / 1;
        background: var(--panic-button-ready);
        border-radius: 50%;
        border: 2px solid var(--grey-lightest);

        &.activated {
          animation: blink 500ms step-end infinite;
        }

        &.has-responders {
          animation-duration: 2000ms;
        }

        @keyframes blink {
          50% {
            background-color: var(--panic-button-activated);
          }
        }
      }

      &[disabled] {
        .panic-button {
          background: none;
        }
      }
    }
  }

  .top-nav {
    .q-btn {
      padding: 8px;
    }
  }

  .bottom-nav {
    .q-btn {
      padding: 8px;
    }
  }

  .sidenav-support {
    .q-btn__content > div {
      align-items: center;
    }

    .q-btn .q-icon {
      font-size: 24px !important;
    }
  }
}

.q-drawer--left.q-drawer--bordered {
  border-right: none !important;
}

.scroll-container {
  scrollbar-width: thin;

  z-index: 2;

  position: absolute;
  display: flex;
  align-items: center;
  transform: matrix(1, 0, 0, -1, 0, 0);
  margin: 0 !important;
  padding: 0 !important;

  width: 250px;

  height: 53px;

  p {
    transform: matrix(1, 0, 0, -1, 0, 0);

    margin: auto;
    padding: 3px 13px;

    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 169% */

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    color: var(--grey-lightest);
    // /* Primary / Blue Dark / 75 */

    background: var(--theme-grey);
    border-radius: 20px;
  }

  &.bottom {
    bottom: 150px;
    left: 30px;
  }

  &.top {
    top: 220px;
    left: 30px;
  }
}
</style>
