<script setup lang="ts">
import { usePermission } from "../../../../../composables/permission";
import { Icon } from "../types";

const props = withDefaults(
  defineProps<{
    id?: string;

    label: string;
    notification?: number | undefined;
    icon?: Icon | undefined;
    to?: string;
    display?: boolean;

    bold?: boolean;
    separator?: boolean;

    exact?: boolean;
    selected?: boolean;
  }>(),
  {
    display: undefined,
  },
);

const { disabled } = usePermission(props, false);
</script>

<template>
  <router-link
    v-show="display != undefined ? display : true"
    class="group-item"
    :class="{
      'router-link-active': selected,
    }"
    :to="to || ''"
    :title="label"
    :exact="exact !== false"
    :data-testid="$props['data-testid'] || undefined"
    :disable="disabled"
  >
    <div class="group-item-title" :class="{ bold: bold }" :title="label">
      {{ label }}
    </div>

    <div v-if="notification || icon" class="group-item-info-container">
      <div v-if="icon" class="group-item-icon">
        <q-icon :name="icon.name" />
        <span class="sr-only">{{ icon.description }}</span>
      </div>

      <div v-if="notification" class="group-item-notification">
        {{ notification > 99 ? "99+" : notification }}
      </div>
    </div>
  </router-link>
</template>
