import { defineStore } from "pinia";
import { BrowserInfo, detect } from "detect-browser";

const BrowserVersions: Record<string, number> = {
  chrome: +MIN_VERSION_CHROME,
  "edge-chromium": +MIN_VERSION_EDGE,
  safari: +MIN_VERSION_SAFARI,
  ios: +MIN_VERSION_IOS,
  crios: +MIN_VERSION_CHROME,
};

const SupportedDesktop = new Set(["chrome", "edge-chromium", "safari"]);
const SupportedMobile = new Set(["chrome", "safari", "ios", "crios"]);

function checkVersion(browser: string, version?: string | null) {
  if (!version) return false;
  const minVersion = BrowserVersions[browser];
  if (!minVersion) return false;
  const v = +version.toString().split(".").slice(0, 2).join(".");
  return v >= minVersion;
}

function supportedDesktop(info: BrowserInfo) {
  const browser = SupportedDesktop.has(info.name);
  const version = checkVersion(info.name, info.version);

  return {
    supported: browser && version,
    browser,
    version,
  };
}

function supportedMobile(info: BrowserInfo) {
  const browser = SupportedMobile.has(info.name);
  const version = checkVersion(info.name, info.version);

  return {
    supported: browser && version,
    browser,
    version,
  };
}

function isMobileCheck(): boolean {
  // from http://detectmobilebrowsers.com/
  let check = false;

  (function (a) {
    if (
      // eslint-disable-next-line no-useless-escape
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      // eslint-disable-next-line no-useless-escape
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || (window as any).opera);
  // @ts-expect-error userAgentData there's no type
  return check || navigator.userAgentData?.mobile || false;
}

if (import.meta.vitest) {
  const { describe, expect, beforeEach, test } = import.meta.vitest;

  describe("Browser: Block access to unsupported browsers and versions more than 1 year old", () => {
    describe("checkVersion", () => {
      test.each([
        // failed
        ["chrome", "59.9", false],
        ["chrome", null, false],
        ["chrome", undefined, false],
        ["safari", "1.0.21561", false],
        ["edge-chromium", "1.0.21561", false],
        ["ios", "1", false],

        // not valid
        ["random", "99999", false],
        ["firefox", "9999", false],
        ["opera", "9999", false],

        // latest
        ["chrome", MIN_VERSION_CHROME, true],
        ["edge-chromium", MIN_VERSION_EDGE, true],
        ["safari", MIN_VERSION_SAFARI, true],
        ["ios", MIN_VERSION_IOS, true],

        // latest + minor
        ["chrome", MIN_VERSION_CHROME + ".159841321", true],
        ["edge-chromium", MIN_VERSION_EDGE + ".159841321", true],
        ["safari", MIN_VERSION_SAFARI + ".159841321", true],
        ["ios", MIN_VERSION_IOS + ".159841321", true],
      ] as const)("%s with %s to be %s", (browser, version, expected) => {
        expect(checkVersion(browser, version)).toBe(expected);
      });
    });

    describe("supportedDesktop", () => {
      test.each([
        // failed
        ["chrome", "59.9", { supported: false, browser: true, version: false }],
        ["chrome", null, { supported: false, browser: true, version: false }],
        ["chrome", undefined, { supported: false, browser: true, version: false }],
        ["safari", "1.0.21561", { supported: false, browser: true, version: false }],
        ["edge-chromium", "1.0.21561", { supported: false, browser: true, version: false }],

        // not valid
        ["random", "99999", { supported: false, browser: false, version: false }],
        ["firefox", "9999", { supported: false, browser: false, version: false }],
        ["opera", "9999", { supported: false, browser: false, version: false }],

        // latest
        ["chrome", MIN_VERSION_CHROME, { supported: true, browser: true, version: true }],
        ["edge-chromium", MIN_VERSION_EDGE, { supported: true, browser: true, version: true }],
        ["safari", MIN_VERSION_SAFARI, { supported: true, browser: true, version: true }],

        // latest + minor
        [
          "chrome",
          MIN_VERSION_CHROME + ".159841321",
          { supported: true, browser: true, version: true },
        ],
        [
          "edge-chromium",
          MIN_VERSION_EDGE + ".159841321",
          { supported: true, browser: true, version: true },
        ],
        [
          "safari",
          MIN_VERSION_SAFARI + ".159841321",
          { supported: true, browser: true, version: true },
        ],
      ] as const)("%s with %s to be %s", (browser, version, expected) => {
        expect(
          supportedDesktop({
            name: browser,
            version,
          } as any),
        ).toEqual(expected);
      });
    });

    describe("supportedMobile", () => {
      test.each([
        // failed
        ["chrome", "59.9", { supported: false, browser: true, version: false }],
        ["chrome", null, { supported: false, browser: true, version: false }],
        ["chrome", undefined, { supported: false, browser: true, version: false }],
        ["safari", "1.0.21561", { supported: false, browser: true, version: false }],

        // not valid
        ["edge-chromium", "1.0.21561", { supported: false, browser: false, version: false }],
        ["random", "99999", { supported: false, browser: false, version: false }],
        ["firefox", "9999", { supported: false, browser: false, version: false }],
        ["opera", "9999", { supported: false, browser: false, version: false }],

        // latest
        ["chrome", MIN_VERSION_CHROME, { supported: true, browser: true, version: true }],
        ["safari", MIN_VERSION_SAFARI, { supported: true, browser: true, version: true }],

        ["edge-chromium", MIN_VERSION_EDGE, { supported: false, browser: false, version: true }],

        // latest + minor
        [
          "chrome",
          MIN_VERSION_CHROME + ".159841321",
          { supported: true, browser: true, version: true },
        ],
        [
          "edge-chromium",
          MIN_VERSION_EDGE + ".159841321",
          { supported: false, browser: false, version: true },
        ],
        [
          "safari",
          MIN_VERSION_SAFARI + ".159841321",
          { supported: true, browser: true, version: true },
        ],
      ] as const)("%s with %s to be %s", (browser, version, expected) => {
        expect(
          supportedMobile({
            name: browser,
            version,
          } as any),
        ).toEqual(expected);
      });
    });

    describe("isMobileCheck", () => {
      beforeEach(() => {
        // @ts-expect-error not the correct type
        navigator.userAgentData = {};
      });

      test("navigator.userAgentData.mobile", () => {
        // @ts-expect-error not the correct type
        navigator.userAgentData.mobile = true;

        expect(isMobileCheck()).toBe(true);
      });

      test("default to false", () => {
        expect(isMobileCheck()).toBe(false);
      });
    });
  });
}

const useBrowser = defineStore("browser", () => {
  const info = detect() as BrowserInfo | null;
  const isMobile = isMobileCheck();

  const supported = info
    ? isMobile
      ? supportedMobile(info)
      : supportedDesktop(info)
    : { supported: false, browser: false, version: false };

  return {
    ...info,

    supported,
    isMobile,
  };
});

export default useBrowser;
