<script lang="ts" setup>
import { provide, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { isString } from "vue-composable";
import { pusher } from "./plugins/thirdparties/pusher";
import { useAuth } from "./store";
import { useOnline } from "./composables/online";
import { defineFocusHistory } from "./composables/focus";
import { showSnackbar } from "./utils/snackbar";

const router = useRouter();

const store = useAuth();

provide("iamBaseUrl", import.meta.env.IAM_BASE_URL);

provide(
  "openPage",
  async (
    url: ({ url: string; replace?: boolean } & Record<string, any>) | string,
    replace?: boolean,
  ) => {
    const [u, r] = isString(url)
      ? [url, replace]
      : isString(url.url)
      ? [url.url, url.replace]
      : [url, replace];
    if (r) {
      await router.replace(u);
    } else {
      await router.push(u);
    }
  },
);

// just to ignore the warning
provide("inDrilldownPage", ref(false));

watch(
  () => store.user,
  (user) => {
    if (user?.username) {
      const channel = pusher.subscribe(`update-tenants-${user.username}`);

      channel.bind("update-tenants-available", async () => {
        const info = await store.refreshUserInfo();
        const tenants = info.tenants ?? {};
        const tenantIds = Object.keys(tenants);
        if (tenantIds.length) {
          return store.logout();
        }
        if (tenants[store.selectedTenant!]) return;

        if (tenantIds.length === 1) {
          // NOTE probably do something here
          return;
        }
        return router.push({
          name: "tenants",
        });
      });
    }
  },
  {
    immediate: true,
  },
);

const { isOnline } = useOnline();
provide("isOnline", isOnline);
watch(isOnline, (online, _, onInvalidate) => {
  if (online) {
    const remove = showSnackbar({
      message: "You are connected to the internet.",
      type: "success",
      icon: "fa-regular fa-wifi",
      // position: "top",
    });
    onInvalidate(remove);
  } else {
    const remove = showSnackbar({
      title: "You are not connected to the internet.",
      type: "danger",
      message:
        "This means that no actions can be taken and no updates will be displayed on screen until you are re-connected again.",
      icon: "fa-regular fa-wifi-slash",
      persistant: true,
    });
    onInvalidate(remove);
  }
});

// enableFocusTrap(ref(document.body));
defineFocusHistory();
</script>
<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  <div id="modal" />
</template>

<style lang="scss">
.flex-auto {
  flex: 1 1 auto;
}

.flex-half {
  flex: 1 0;
}

.flex-0 {
  flex: 0 0 auto;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.h-full {
  height: 100%;
}

.max-h-full {
  max-height: 100%;
}

.w-full {
  width: 100%;
}

.flex-row {
  flex-direction: row;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.flex-col {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.self-center {
  align-self: center;
}

.mt-none {
  margin-top: none !important;
}

.ma-auto {
  margin: auto;
}

.justify-self-start {
  justify-self: flex-start;
}

.justify-self-end {
  justify-self: flex-end;
}

.inline {
  display: inline;
}

.inline-flex {
  display: inline-flex;
}

.gap-0 {
  gap: 0px;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.row-gap-1 {
  row-gap: 0.25rem;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-full {
  grid-column: 1/-1;
}

.row-span-full {
  grid-row: 1/-1;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.relative {
  position: relative;
}

.bold {
  font-weight: bold;
}

.underline-hover:hover {
  text-decoration: underline;
}

.title {
  font-family: Georgia;
  font-weight: 400;
  line-height: 150%;
  font-size: 21px;
  color: var(--text-color);
}

.empty-text {
  font-family: Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--text-color-lightest);
}

.form-required-text {
  margin-bottom: 6px;
}

.secondary-text {
  color: var(--grey-darkest);
  font-weight: normal;
}

.medicus-box-shadow {
  box-shadow: var(--box-shadow);
}

.medicus-grey-background {
  background: var(--medicus-background);
}
.medicus-white-background {
  background: #fff;
}

.medicus-blue-header {
  font-size: 16px;
  background: var(--theme-darkest-blue);
  border-radius: 4px;
  padding: 2px 0.75rem;
  color: var(--grey-lightest);
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.text-cursor {
  cursor: text;
}

.care-record-element-presenter {
  border-left: 3px solid var(--status-blue);
  padding: 3px 0 3px 10px;

  .supplementary-text {
    color: var(--grey-darkest);
  }
}

.care-record-element-presenter.clickable {
  cursor: pointer;
}

.care-record-element-presenter.clickable:hover {
  background-color: var(--hover-background);
}

.medicus-outline {
  position: relative;

  // some of the component have overflow: hidden, so we need to set it to visible
  overflow: visible !important;

  // &:focus-within::before:not(.m-radio),
  &.force-focus::before,
  &:focus-visible::before,
  &.q-radio:focus::before,
  &.m-card:focus::before,
  &.m-card:focus-within::before,
  &.q-btn:focus-visible:before,
  &.q-btn-group>.q-btn:focus-visible:before,
  &.medicus-button:focus::before,
  &button:focus::before,
  &button:focus-within::before,
  &button:focus-visible::before,
  &.m-expander-head:focus-visible:before,
  &.m-input:not(.m-select) .m-input--input-box:focus-within:before,
  &.m-select.use-input .m-input--input-box:focus-within:before,
  &.m-select:not(.use-input) .input:focus:before,
  .q-focusable:focus-visible:before,
  .medicus-button:focus-visible:before,
  // &.m-radio:focus-visible:before,
  &.focus-component::before {
    position: absolute;
    content: " ";

    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;

    display: block;
    pointer-events: none;
    background: none;

    margin: 0;
    padding: 0;

    border-radius: var(--__adaptive-focus-ring-radius, 4px);
    box-shadow: 0 0 0 3px var(--__adaptive-focus-ring-color, var(--focus-ring, #007acc));

    z-index: 8000;
    overflow: visible;
  }
}

pre {
  font-family: Arial, sans-serif;
}

h1 {
  font-family: Georgia;
  font-weight: 400;
  line-height: 150%;
  font-size: 21px;
  letter-spacing: 0.01em;
  margin: 0;
}

h2 {
  font-size: 18px;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin: 0;
}

h3 {
  font-size: 16px;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin: 0;
}

h4 {
  font-size: 14px;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin: 0;
}

p,
ul {
  margin: 0;
}

.border-none {
  border-style: none;
}

.q-item {
  align-items: center;

  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;

  color: var(--text-color);

  &:not(.q-item--dense) {
    min-height: 32px;
  }
}

.q-icon {
  &.error-icon {
    color: var(--status-red);
  }
}

.q-menu {
  // border: 1px solid var(--border-colour);
  box-shadow:
    0px 4px 6px rgba(0, 0, 0, 0.06),
    0px 2px 4px rgba(0, 0, 0, 0.06);
  z-index: 9002;
}

.error-text {
  color: var(--status-red);

  font-size: 14px;

  margin-top: 5px;
  // margin-left: 5px;

  white-space: normal;
}

.scroll-area {
  max-height: 100%;
  flex: 1 1 auto;
  overflow: auto !important;
  // NOTE sometimes it shows the scroll-x, but most of the times we don't want that
  overflow-x: hidden;
}

.incorrect {
  text-decoration: line-through !important;
}

.m-border-bottom {
  border-bottom: 1px solid var(--grey-light);
}

.m-form-padding {
  padding-left: 2em;
  padding-right: 2em;
}

.marked-incorrect {
  text-decoration-line: line-through;
  color: var(--grey-dark) !important;

  span,
  td,
  label,
  pre,
  dd,
  dt,
  .table-cell,
  .header-cell,
  .property-list--title,
  .table-title {
    color: var(--grey-dark) !important;
  }
}

fieldset[disabled] {
  > * {
    pointer-events: none;
    opacity: 0.6 !important;
  }
}

.uppercase,
input[uppercase="true"] {
  text-transform: uppercase;

  &::placeholder {
    text-transform: none;
  }
}
</style>
<style lang="scss">
.medicus-loading-ball svg g,
.medicus-loading-ball g {
  fill: #e16e7c;
  color: #e16e7c;

  :first-child {
    fill: var(--status-green);
    color: var(--status-green);
  }

  :last-child {
    fill: var(--theme-blue);
    color: var(--theme-blue);
  }
}

.q-textarea {
  textarea {
    padding-top: 10px;
    // resize: none;
  }
}

// html,
// body,
// #q-app {
//   /* Disables pull-to-refresh and overscroll glow effect.
//      Still keeps swipe navigations. */
//   overscroll-behavior-y: none;

//   /** Disable overflow to prevent a bug where 2 scroll bars will appear */
//   overflow: hidden;

//   height: 100%;
// }

#page-container {
  height: 100%;
}

#modal > * {
  z-index: 9000;
}
</style>

<style lang="scss">
.slide-left-enter-active,
.slide-left-leave-enter {
  transform: translateX(0);
  transition: all 0.3s linear;
}

.slide-left-enter,
.slide-left-leave-to {
  transform: translateX(100%);
}
</style>

<style src="./css/colours.variables.scss" lang="scss" />
<style src="./css/variables.scss" lang="scss" />
