import { Ref, computed, h, inject, reactive, toRef } from "vue";
import { isObject } from "vue-composable";
import { createRouter, createWebHistory, useLink } from "vue-router";
import { pinia, useAuth } from "../store";
import { injectTenantToRoute } from "../utils/route";
import { routes } from "./routes";

export const router = createRouter({
  history: createWebHistory(),
  routes: routes ?? [],
});

if (IS_APP) {
  const auth = useAuth(pinia);

  const push = router.push.bind(router);
  const replace = router.replace.bind(router);
  router.push = (e: any) => {
    if (isObject(e) && "name" in e) {
      return push(e);
    }
    const tenant = auth.selectedTenant;
    if (tenant) {
      e = injectTenantToRoute(e, tenant, router);
    }
    return push(e).catch((e) => {
      if (e && e.name !== "NavigationDuplicated") {
        throw e;
      }
    });
  };

  router.replace = (e) => {
    if (isObject(e) && "name" in e) {
      return replace(e);
    }
    const tenant = auth.selectedTenant;
    if (tenant) {
      e = injectTenantToRoute(e, tenant, router);
    }
    return replace(e).catch((e) => {
      if (e && e.name !== "NavigationDuplicated") {
        throw e;
      }
    });
  };

  const _install = router.install.bind(router);
  // patch the install to override the RouterLink
  router.install = (app) => {
    app.config.globalProperties.$navigate = router.push;
    const r = _install(app);

    // override routerLink
    // append the tenant to the router-link
    const routerLink = app.component("RouterLink") as any;
    app.component("RouterLink", {
      props: {
        ...routerLink.props,
        ignoreTenant: Boolean,
        id: { type: String, default: undefined },
        exact: Boolean,
      },
      setup(props, { slots }) {
        const tenant = auth.selectedTenant;

        const to = computed(() =>
          tenant ? injectTenantToRoute(props.to, tenant, router) : props.to,
        );

        const fixedProps = reactive(
          Object.keys(props).reduce((p, c) => {
            p[c] = c === "to" ? to : toRef(props, c);
            return p;
          }, {} as any),
        );

        const link = reactive(useLink(fixedProps));

        const renderedComponent = inject<Ref<{ subnavItem?: string }> | undefined | null>(
          "renderedComponent",
          null,
        );

        const isActive = computed(() => {
          const path =
            "/" + router.currentRoute.value.fullPath.split("/").filter(Boolean).join("/");

          return (
            (to.value && (props.exact ? to.value === path : ~path.indexOf(to.value))) ||
            (renderedComponent?.value?.subnavItem &&
              props.id &&
              renderedComponent?.value.subnavItem === props.id)
          );
        });

        return () => {
          const children =
            slots.default &&
            slots.default({
              ...link,
              ignoreTenant: props.ignoreTenant,
              id: props.id,
              exact: props.exact,
            });
          return props.custom
            ? children
            : h(
                "a",
                {
                  "aria-current": link.isExactActive ? props.ariaCurrentValue : null,
                  href: link.href,
                  // this would override user added attrs but Vue will still add
                  // the listener, so we end up triggering both
                  onClick: link.navigate,
                  class: isActive.value ? "router-link-active" : "",
                },
                children,
              );
        };
      },
    });
    return r;
  };
}
