import { NOOP } from "@vue/shared";
import {
  WritableComputedRef,
  computed,
  getCurrentInstance,
  inject,
  nextTick,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { RefTyped, debounce, isObject, isString, unwrap } from "vue-composable";
import { pathAccessor } from "../utils/helpers";

export function useNamedValue<
  T extends { modelValue?: V; name?: string; uppercase?: boolean; debounce?: number | string },
  V,
>(props: T, debounceMs?: RefTyped<number | string | undefined>) {
  const preventNameUsage = inject("input-dont-use-name", false);
  const instance = getCurrentInstance()!;
  const form = inject<Record<string, any> | false>("form", false) || inject("formData", false);

  const accessor = computed(() =>
    props.name && !preventNameUsage ? pathAccessor(props.name) : undefined,
  );

  function setValue(v: V) {
    const p = value.value;

    if (props.uppercase && isString(v)) {
      // @ts-expect-error type is incorrect
      v = v.toUpperCase() as string;
    }
    if (form && accessor?.value) {
      accessor.value.set(form, v);
    }

    if (p === v || (isObject(p) && isObject(v) && JSON.stringify(p) === JSON.stringify(v))) return;
    instance?.emit("update:modelValue", v);
  }

  const setValueFn = computed(() => {
    const ms = unwrap(debounceMs) || props.debounce;
    if (ms) {
      return debounce(setValue, +ms);
    }
    return setValue;
  });

  const value = computed<V>({
    get: () => {
      if (props.modelValue) {
        return props.modelValue;
      }

      if (form && accessor?.value) {
        try {
          return accessor.value.get(form);
        } catch (e) {
          // usually caused by a bad path or index not found
          if (IS_APP && import.meta.env.NODE_ENV === "development") {
            // try again after the next tick, to allow it to refresh
            //@ts-expect-error invalid instance type
            nextTick(instance.proxy!).then(() => {
              if (!instance?.isUnmounted) {
                throw e;
              }
            });
          }
        }
      }
      return props.modelValue;
    },
    set(v: V) {
      return setValueFn.value(v);
    },
  });

  return value;
}

interface ValidationItem {
  name: string;
  validation(): void;
  setErrors(e: string[]): void;
  scrollIntoView(): void;
}

export function useFormValue<T extends { modelValue: V; name?: string }, V>(
  props: T,
): WritableComputedRef<V> {
  const instance = getCurrentInstance();

  const formTouched = inject<(touched: boolean) => void>("formTouched", NOOP);
  const controller = inject<ValidationItem[] | false>("validationController", false);

  const customErrors = ref<string[]>([]);
  const touched = ref(false);

  if (controller) {
    const validationItem: ValidationItem = {
      name: props.name!,
      validation() {
        touched.value = true;
        // @ts-expect-error TODO remove this, this should be handled by a composable
        return !(instance.proxy.validationErrors?.length > 0);
      },

      setErrors(errors) {
        customErrors.value = errors;
      },

      scrollIntoView() {
        instance!.proxy?.$el.scrollIntoView();
      },
    };

    controller.push(validationItem);
    onUnmounted(() => {
      controller.splice(controller.indexOf(validationItem), 1);
    });
  }

  // TODO remove me
  // @ts-expect-error this should be removed
  watch(touched, (v) => (instance.proxy.touched = v));
  //@ts-expect-error this should be removed
  watch(customErrors, (v) => (instance.proxy.customErrors = v));

  const value = useNamedValue(props);
  watch(value, () => formTouched(true));

  return value as any;
}
