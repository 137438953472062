import { defineStore } from "pinia";
import { ComponentOptions, nextTick, reactive, readonly, ref, shallowReadonly, watch } from "vue";

import { usePromiseLazy } from "vue-composable";
import { router } from "../../router";
import { loadComponent } from "../../utils/component";
import { toQueryString } from "../../utils/route";
import { useFocusHistory } from "../../composables/focus";
import { parseQuery } from "vue-router";

export interface DrawerOptions {
  title?: string;
  patientId?: string | null;

  params?: Record<string, any>;
  props?: Record<string, any>;
  additionalCreatePayloadData?: Record<string, any>;
  additionalDeletePayloadData?: Record<string, any>;

  noData?: boolean;

  /* deprecated property
   * use close instead
   * @deprecated
   */
  keepOpen?: boolean;

  close?: true;
  preventBackgroundClose?: boolean;

  onOpen?: Function;
  onLoaded?: Function;
  onCancel?: Function;
  onSuccess?: Function;

  stopEventPropagation?: boolean;
}

export interface DrawerUrlOptions extends DrawerOptions {
  url: string;
}

const openedUrl = ref<{ id: string; url: string; keepOpen?: boolean } | null>(null);

function defineDrawerStore(id: "modal" | "slideover") {
  return defineStore(`drawer.${id}`, () => {
    const {
      result,
      exec: open,
      loading,
      promise,
    } = usePromiseLazy((component: string, options?: DrawerOptions) => {
      opened.value = true;

      const [componentUrl, componentQuery] = component.split("?");
      const params = Object.assign({}, parseQuery(componentQuery ?? ""), options?.params);
      const url = `${componentUrl}?${toQueryString(params)}`;
      openedUrl.value = { id, url, keepOpen: options?.keepOpen };

      return loadComponent(url, options?.noData).then((x) => {
        return {
          ...options,
          Component: x,
          componentUrl: url,
          params,
        };
      });
    });

    const opened = ref(false);

    function close() {
      opened.value = false;
      result.value = null;

      openedUrl.value = null;
    }

    router.beforeEach(() => {
      close();
    });

    watch(openedUrl, async (v) => {
      if (!v || v.keepOpen) return;
      if (v.id === id && loading.value) {
        return await promise.value;
      }
      if (v.id !== id || v.url !== result.value?.componentUrl) {
        opened.value = false;
      }
    });

    return {
      options: readonly(result),
      loading,

      opened,

      open,
      close,
    };
  });
}

export const useSlideover = defineDrawerStore("slideover");
export const useModal = defineDrawerStore("modal");

export const DrawerTypeEnum = {
  modal: "modal",
  slideover: "slideover",
};

export type DrawerType = keyof typeof DrawerTypeEnum;

export type LoadDrawer = {
  component: null | ComponentOptions;
  componentUrl: string;
  params: Record<string, any>;
  promise: Promise<ComponentOptions>;
};

export type DrawerItem = DrawerOptions &
  LoadDrawer & {
    type: DrawerType;
  };

export const useDrawerStore = defineStore("drawer", () => {
  const queue = ref<Array<DrawerItem>>([]);
  const map = reactive(new Map(Object.keys(DrawerTypeEnum).map((x) => [x, [] as DrawerItem[]])));

  const focusQueue = useFocusHistory();

  watch(
    // generate a new array to get the differences
    () => [...queue.value],
    (n, p) => {
      const maxIndex = Math.max(n.length, p.length);
      for (let i = 0; i < maxIndex; i++) {
        if (n[i] === p[i]) continue;

        // removed
        if (p[i]) {
          map.get(p[i].type)?.pop();
        }
        // added
        if (n[i]) {
          map.get(n[i].type)?.push(n[i]);
        }
      }
    },
    {
      deep: false,
      flush: "sync",
    },
  );

  function loadDrawer(component: string, options?: DrawerOptions): LoadDrawer {
    const [componentUrl, componentQuery] = component.split("?");
    const params = Object.assign({}, parseQuery(componentQuery ?? ""), options?.params);
    const queryParams = toQueryString(params);
    const url = `${componentUrl}${queryParams ? `?${toQueryString(params)}` : ""}`;

    const promise = loadComponent(url, options?.noData);

    const o: LoadDrawer = {
      component: null as ComponentOptions | null,
      componentUrl: url,
      params,
      promise,
    };

    promise.then((x) => {
      options?.onLoaded?.();
      o.component = x;
    });

    return o;
  }

  function open(type: DrawerType, url: string, options?: DrawerOptions) {
    options?.onOpen?.();

    if (options?.close) {
      closeLast(false);
    }

    queue.value.push({
      type,
      ...options,
      ...loadDrawer(url, options),
    });
  }

  function closeLast(focusPrevious = true) {
    const last = queue.value.pop();
    // during testing focusQueue is not defined
    focusPrevious && nextTick(() => focusQueue?.focusLast());

    return last;
  }

  function clear() {
    queue.value[0];
    queue.value.length = 0;
    // during testing focusQueue is not defined
    focusQueue?.focusLast();
  }

  return {
    queue: shallowReadonly(queue),
    map: shallowReadonly(map),

    clear,
    open,
    closeLast,
  };
});
