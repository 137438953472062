import { defineStore } from "pinia";
import { ref } from "vue";
import { usePromiseLazy } from "vue-composable";
import {
  gbNhsCis2PairedUserRemove,
  gbNhsCis2PairedUserValidate,
  gbNhsCis2PairedUsers,
  nhsLogin,
} from "../api/iam";
import { useSnackbar } from "../composables/snackbar";
import { removeExpires, updateExpires } from "../utils/session";
import { useAuth } from "./auth";

export const useNHS = defineStore("nhs", () => {
  const authStore = useAuth();

  async function login(req: { code: string; state: string }, preventRedirect: boolean = false) {
    removeExpires();
    const { data, status } = await nhsLogin(req);

    if (status !== 200 || typeof data.userId === "undefined") {
      if ("error" in data) {
        if (data.error.indexOf("No user linked") !== -1) {
          shouldPair.value = true;
        } else if (data.error.indexOf("Account locked") === -1) {
          showSnackbar({
            title: data.error,
            message: data.error,
            type: "danger",
          });
        }
      }
      return { data, status, shouldPair: shouldPair.value };
    }
    updateExpires();
    authStore.user = data;

    if (preventRedirect === false) {
      authStore.doRedirect();
    }
  }

  const { add: showSnackbar } = useSnackbar();

  const shouldPair = ref(false);

  const { loading: isLoggingIn, exec: openNhsLogin } = usePromiseLazy(
    (preventRedirect: boolean = false) =>
      openWindow("user/gb-nhs-cis2-login-redirect", async (e) => {
        const parsed = parseSearchData(e.data);
        if (!parsed) return;

        return login(parsed, preventRedirect);
      }),
  );

  const { loading: isPairing, exec: openNhsPair } = usePromiseLazy(
    async (preventRedirect: boolean = false) =>
      openWindow("user/gb-nhs-cis2/paired-users/create", async (e) => {
        if (isLoggingIn.value) return;

        const parsed = parseSearchData(e.data);
        if (!parsed) return;

        await gbNhsCis2PairedUserValidate(parsed);

        shouldPair.value = false;
        await openNhsLogin(preventRedirect);
      }),
  );

  return {
    login,

    shouldPair,

    isLoggingIn,
    openNhsLogin,

    isPairing,
    openNhsPair,

    // expose API
    gbNhsCis2PairedUsers,
    gbNhsCis2PairedUserValidate,
    gbNhsCis2PairedUserRemove,
  };
});

function parseSearchData(search: string) {
  const urlParams = new URLSearchParams(search);
  const code = urlParams.get("code");
  const state = urlParams.get("state");
  if (!code || !state) {
    return undefined;
  }

  return {
    code,
    state,
  };
}

async function openWindow<T>(
  url: string,
  handleMessage: (e: MessageEvent) => Promise<T>,
): Promise<T | undefined> {
  const width = 600,
    height = 500,
    top = (window.screen.availTop || 0) + (window.screen.availHeight / 2 - height / 2),
    left = (window.screen.availLeft || 0) + (window.screen.availWidth / 2 - width / 2);

  let handlingPromise: Promise<T> | undefined;

  function messageHandler(e) {
    const p = handleMessage(e);
    if (p) {
      handlingPromise = p;
    }
  }

  window.addEventListener("message", messageHandler, { capture: false, passive: true });
  try {
    return await new Promise<T | undefined>((res, rej) => {
      const w = window.open(
        `${import.meta.env.IAM_BASE_URL}/${url}`,
        "nhs_login_window",
        `menubar=no, location=no, resizable=yes, scrollbars=no, status=no, width=${width}, height=${height}, top=${top}, left=${left}`,
      );
      if (!w) return rej(new Error("Couldn't open a new window"));

      function checkClosed() {
        if (w!.closed) {
          // There is a race condition with Chrome whereby finally can be called before the 'message' eventlistener
          setTimeout(async () => {
            // if we are handling a message, we need to wait before we resolve the promise
            if (handlingPromise) {
              return res(await handlingPromise);
            }
            return res(undefined);
          }, 200);
        }

        setTimeout(checkClosed, 100);
      }
      checkClosed();
    });
  } finally {
    window.removeEventListener("message", messageHandler);
  }
}
