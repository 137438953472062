<template>
  <q-form
    ref="form"
    class="m-form"
    :class="{ 'overflow-hidden max-h-full': noOverflow || scrollContent, 'h-full': scrollContent }"
    v-bind="$attrs"
    autocomplete="off"
    autofocus
    novalidate
    @submit.prevent="submitForm"
  >
    <p
      v-if="!hideRequiredMessage"
      :class="['form-required-text empty-text', scrollContent && 'q-px-md']"
    >
      Fields marked with an asterisk (<span style="color: var(--status-red)">*</span>) are required.
    </p>
    <m-layout-stack
      gap="4"
      class="form-content-wrapper"
      :class="{
        'overflow-hidden max-h-full': noOverflow || scrollContent,
        'h-full': scrollContent,
      }"
    >
      <m-layout-stack
        class="form-content max-h-full"
        :class="{ 'overflow-hidden': noOverflow || scrollContent, 'scroll-area': scrollContent }"
      >
        <slot />
      </m-layout-stack>

      <m-layout-stack class="form-footer">
        <div
          class="flex-col error-text-container"
          :class="{
            'sr-only': serverErrors.length === 0,
          }"
          role="status"
        >
          <div class="error-text">
            <m-layout-stack>
              <span v-for="e in serverErrors" :key="e" v-text="e" />
            </m-layout-stack>
          </div>
        </div>
        <div class="flex flex-row gap-3">
          <slot name="footer" v-bind="{ submitting, setSubmit, hasAccess }">
            <div class="flex flex-auto justify-end">
              <m-button class="" :label="submitLabel || 'Submit'" type="submit" color="primary" />
            </div>
          </slot>
        </div>
      </m-layout-stack>
    </m-layout-stack>
  </q-form>
</template>

<script setup lang="ts">
import { computed, inject, provide, ref, watch } from "vue";
import { usePromiseLazy } from "vue-composable";
import { Key } from "ts-key-enum";
import { QForm } from "quasar";
import { ignoreFields as ignoreFieldsUtil } from "../../../utils/string";
import { hasPermission } from "../../../utils/permission";
import MLayoutStack from "./../MLayoutStack";
import MButton from "./../MButton";
import { NOOP } from "@vue/shared";
import { showSnackbar } from "../../../utils/snackbar";
import axios from "../../../utils/axios";
import { dispatch } from "../../../utils/bus";
import { ENTRY_CREATED_EVENT_NAME, EntryCreatedEventData } from "../MInlineEntryList/types";
import { useKeyboardShortcut } from "../../../composables/keyboardShortcut";
import { unrefObject } from "../../../utils/misc";

const props = defineProps<{
  url?: string;
  noValidation?: boolean;
  enterSubmit?: boolean;
  submitLabel?: string;
  form?: Record<string, any>;
  beforeSubmit?: Function;
  onSubmit?: Function;
  onError?: Function;
  prefix?: string;
  noOverflow?: boolean;
  scrollContent?: boolean;
  hideRequiredMessage?: boolean;
  noClose?: boolean;
  preventReloadOnSubmit?: boolean;
  additionalCreatePayloadData?: Record<string, any>;
}>();

const emit = defineEmits({
  success: (_r: any) => true,
  submit: (_setSubmit: (v: boolean) => void) => true,
  cancel: () => true,
  error: (_e: any) => true,
  finally: () => true,
});

const ignoreFields = inject<[] | string>("ignoreFields", []);
const formData = inject<Object>("formData", {});
const onSuccess = inject<(close: boolean, reload?: boolean, data?: Record<"string", any>) => void>(
  "success",
  NOOP,
);

const hasAccess = computed(() => !loading.value && accessResult.value);
const submitting = ref<boolean>(false);
const serverErrors = ref<any[]>([]);
const validationController = ref([]);
const form = ref<QForm | null>(null);

provide("validationController", validationController.value);
provide("form", props.form);
provide("formData", formData);
provide("submit", submit);
provide("validate", validate);

defineExpose({
  submit,
  validate,
});

const {
  result: accessResult,
  loading,
  exec,
} = usePromiseLazy((url) => {
  if (!url) {
    return true;
  }
  return hasPermission(url, "POST");
});

watch(() => props.url, exec, { immediate: true });

useKeyboardShortcut("submit-form", (e) => {
  form?.value?.submit(e);

  if (e.target.tagName === "BUTTON") {
    return;
  }

  if (props.enterSubmit) return;

  if (e.key === Key.Enter && e.target.tagName !== "TEXTAREA") {
    e.preventDefault?.();
  }
});

function setSubmit(v) {
  submitting.value = v || false;
}

async function submitForm(_e) {
  const r = await submit();
  if (r) {
    if (props.onSubmit) {
      try {
        setSubmit(true);
        const result = await props.onSubmit(setSubmit);
        if (result === false) {
          return;
        }
      } catch (error) {
        return;
      } finally {
        setSubmit(false);
      }
    } else {
      emit("submit", setSubmit);
    }

    if (props.additionalCreatePayloadData) {
      const eventData: EntryCreatedEventData = {};

      if ("contextId" in formData || "contextType" in formData) {
        eventData.context = {};

        if ("contextId" in formData && typeof formData.contextId === "string") {
          eventData.context.contextId = formData.contextId;
        }

        if ("contextType" in formData && typeof formData.contextType === "string") {
          eventData.context.contextType = formData.contextType;
        }
      }

      // This is for the appointment creation forms when used in the document entry list
      if ("bookedFromDocumentId" in formData && typeof formData.bookedFromDocumentId === "string") {
        eventData.context = {
          contextId: formData.bookedFromDocumentId,
          contextType: "document",
        };
      }

      dispatch<EntryCreatedEventData>(ENTRY_CREATED_EVENT_NAME, eventData);
    }

    if (r.data) {
      onSuccess?.(!props.noClose, !props.preventReloadOnSubmit, r.data);
    } else {
      onSuccess?.(!props.noClose, !props.preventReloadOnSubmit);
    }
  }
}

async function submit(force = false) {
  if (submitting.value && !force) {
    return false;
  }
  try {
    submitting.value = true;

    if (!props.noValidation) {
      if (!(await validate())) {
        console.warn("failed to validate");
        return;
      }
    }

    if ((await props.beforeSubmit?.()) === false) {
      return;
    }

    if (props.url && formData) {
      return await axiosPost();
    }
    // this.$emit("submit");
    return true;
  } finally {
    submitting.value = false;
  }
}

async function validate() {
  const pFormValidation = form.value.validate();

  const inputValidations = validationController.value.map((x) => x.validation());

  const validations = await Promise.all([pFormValidation, ...inputValidations]);

  if (validations.every(Boolean)) {
    return true;
  }
  const failedIndex = inputValidations.findIndex(
    (x, i) => !x && validationController.value[i].scrollIntoView,
  );

  if (failedIndex >= 0) {
    validationController.value[failedIndex].scrollIntoView();
    console.warn(
      "validation failed for field:",
      validationController.value[failedIndex]?.name || failedIndex,
    );
  }

  return false;
}

function processBackendErrors(errors) {
  serverErrors.value = [];
  validationController.value.map((x) => x.setErrors([]));
  if (!errors) {
    return;
  }

  const validations = validationController.value
    .filter((x) => x.name)
    .filter((x) => !props.prefix || x.name?.startsWith(props.prefix));

  const validationMap = new Map(
    validations.map((x) => [props.prefix ? x.name.slice(props.prefix.length + 1) : x.name, x]),
  );

  const parentValidators = validations.filter((x) => x.parent);

  const messages = {};

  for (const prop of Object.keys(errors)) {
    const message = errors[prop];
    if (!validationMap.has(prop)) {
      if (parentValidators.length) {
        const parent = parentValidators.find((x) =>
          prop.startsWith(props.prefix ? x.name.slice(props.prefix.length + 1) : x.name),
        );
        if (parent) {
          const name = props.prefix ? parent.name.slice(props.prefix.length + 1) : parent.name;
          if (messages[name]) {
            messages[name].push(...message);
          } else {
            messages[name] = message;
          }
          continue;
        }
      }

      // this.serverErrors.push(`"${prop}": ${message}`);
      serverErrors.value = [
        ...serverErrors.value,
        ...(Array.isArray(message) ? message : [message]),
      ];
      continue;
    }

    if (messages[prop]) {
      messages[prop].push(...message);
    } else {
      messages[prop] = message;
    }
  }

  Object.keys(messages).forEach((k) => validationMap.get(k).setErrors(messages[k], k));
}

async function axiosPost() {
  try {
    const ignoredFields = Array.isArray(ignoreFields) ? ignoreFields : ignoreFields.split(",");

    let data = ignoreFieldsUtil(formData, ignoredFields, ".", props.prefix);

    if (
      props.additionalCreatePayloadData &&
      Object.entries(props.additionalCreatePayloadData).length > 0
    ) {
      const unreffedAdditionalCreatePayloadData = unrefObject(props.additionalCreatePayloadData);
      data = Object.assign(data, unreffedAdditionalCreatePayloadData);
    }

    if (~JSON.stringify(data).indexOf('"__file":{}')) {
      data = objectToFormData(data);
    }
    const r = await axios.post(props.url, data, { showError: false });
    emit("success", r);
    return r;
  } catch (e) {
    const handledError = !!props.onError;
    if (props.onError) {
      await props.onError(e);
    } else {
      emit("error", e);
    }

    if (e.response) {
      processBackendErrors(e.response.data.errors);
      if (e.response.status !== 400 && !handledError) {
        // If the error is not a validation error, rethrow it!
        // throw e;

        showSnackbar({
          title: `Error`,
          message: `
            <p>We’re sorry, but we’re experiencing a problem on our end.</p>
            <p>Please contact the Medicus Support Team for further information. Our Live Operations Team have also been notified.</p>
            <p>The error code was: ${e.response.status}</p>
          `,
          type: "danger",
          timeout: 9999999,
        });
      }
    } else if (!handledError) {
      throw e;
    }
    return false;
  } finally {
    emit("finally");
  }
}

// if the form as a file, it should make non-files to be under `formPayload` key
function objectToFormData(o) {
  const formData = new FormData();
  const formPayload = {};

  for (const k of Object.keys(o)) {
    const item = o[k];
    if (item === undefined) continue;

    if (item && typeof item === "object" && "__file" in item) {
      formData.set(k, item.__file);
    } else if (
      Array.isArray(item) &&
      !!item[0] &&
      typeof item[0] === "object" &&
      "__file" in item[0]
    ) {
      for (let i = 0; i < item.length; i++) {
        formData.set(`${k}[${i}]`, item[i].__file);
      }
    } else {
      formPayload[k] = item;
    }
  }

  formData.set("formPayload", JSON.stringify(formPayload));

  return formData;
}
</script>
<style lang="scss">
.m-form {
  display: flex;
  flex-direction: column;

  .form-required-text {
    margin-top: 1em;
    margin-left: 16px;
  }

  .form-content-wrapper {
    padding: 2em;

    &:has(.m-section) {
      padding: 10px 16px 16px;
    }
  }

  .form-required-text + .form-content-wrapper {
    padding-top: 4px;
  }

  .form-content {
    flex: 1 1 auto;
    display: flex;
  }
  .form-footer {
    flex: 0 0 auto;
  }
}
</style>
