import deepmerge from "deepmerge";
import { isPlainObject } from "is-plain-object";

/**
 * Returns new object with ignored fields
 * @param obj Object to ignore fields
 * @param ignoredFields ignore fields list, nested by "."
 */
export function ignoreFields(
  obj: Record<string, any>,
  ignoredFields: string[],
  separator = ".",
  prefix = "",
): Record<string, any> {
  const newObj = deepmerge({}, obj, { isMergeableObject: isPlainObject }); // create a copy of the object

  for (const fullPath of ignoredFields) {
    const paths = fullPath.split(separator);

    let o: Record<string, any> = newObj;

    for (let i = 0; i < paths.length; i++) {
      const current = paths[i];

      if (!!o && current in o) {
        // last path, remove from the object
        if (i === paths.length - 1) {
          delete o[current];
          break;
        }

        if (Array.isArray(o[current])) {
          const remainingPath = paths.slice(i + 1).join(separator);
          const arr = [];

          for (const nested of o[current]) {
            arr.push(ignoreFields(nested, [remainingPath], separator));
          }
          o[current] = arr;
        }
        o = o[current];
      } else {
        break;
      }
    }
  }

  if (prefix) {
    const paths = prefix.split(separator);
    let o: Record<string, any> = newObj;
    for (let i = 0; i < paths.length; i++) {
      const current = paths[i];

      if (!!o && current in o) {
        o = o[current];

        if (i === paths.length - 1) {
          return o;
        }
      } else {
        console.error(`Prefix not found "${prefix}" not found or ignored`, newObj);
        throw new Error("Prefix not found - check console");
      }
    }
  }

  return newObj;
}

if (import.meta.vitest) {
  const { describe, test, expect } = import.meta.vitest;

  describe("ignoreFields", () => {
    test("Frontend: Form: Ignore fields: Should allow keep instances of classes instead of copying them", () => {
      const file = new File([], "test");
      const a = {
        foo: "t",
        test: 1,

        file,
      };

      expect(ignoreFields(a, ["foo"])).toEqual({
        test: 1,
        file,
      });
    });
  });
}
