<script lang="ts" setup>
import { computed } from "vue";
import { useAuth } from "../../../../store";
import MAvatar from "../../MAvatar";
import MLayoutStack from "../../MLayoutStack";

const store = useAuth();
const staffName = computed(() => store.config?.staff.name);
const staffInitials = computed(() => store.config?.staff.initials);
// const tenants = computed(() => (store.tenants ? Object.keys(store.tenants) : []));
</script>

<template>
  <q-btn flat dense class="sidebar-user" no-caps>
    <m-layout-stack horizontal center full>
      <m-avatar :name="staffName" size="24px" :initials="staffInitials" />
      <div class="user-info">
        <div class="name" style="color: var(--grey-lightest)" :title="staffName">
          {{ staffName }}
        </div>
        <div class="tenant" style="color: var(--grey-dark)" :title="store.currentTenant?.name">
          {{ store.currentTenant?.name }}
        </div>
      </div>
    </m-layout-stack>
  </q-btn>
</template>
<style lang="scss">
.sidebar-user {
  overflow: hidden;
  flex: 1 1 auto;

  .user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    line-height: 130%;

    overflow: hidden;

    // width: 190px;

    // max-width: 100%;

    > div {
      width: 190px;

      text-align: left;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .name {
      font-weight: bold;
    }
  }
}
</style>
