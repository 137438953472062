import { defineStore } from "pinia";
import { ref } from "vue";

const DEFAULT_MESSAGE = "This action is not available on mobile devices.";

export const useMobileSplashScreen = defineStore("mobile-splash", () => {
  const open = ref(false);

  const message = ref(DEFAULT_MESSAGE);

  function show(_message = DEFAULT_MESSAGE) {
    message.value = _message;
    open.value = true;
  }

  function close() {
    open.value = false;
  }

  return {
    open,
    message,

    show,
    close,
  };
});
