<template>
  <q-expansion-item
    v-if="expand || expanded"
    :model-value="expanded"
    class="banner banner-expandable flex-auto medicus-outline"
    :class="[type]"
    :icon="icon"
  >
    <slot />
    <m-action v-if="actions" :model-value="actions" />
  </q-expansion-item>
  <div v-else class="banner" :class="[type]">
    <q-icon v-if="icon" :name="icon"> </q-icon>
    <span v-if="iconDescription" class="sr-only">{{ iconDescription }}:</span>

    <div class="banner-content">
      <slot />
    </div>

    <m-action v-if="actions" class="banner-action" :model-value="actions" :ghost="false" small />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { QExpansionItem, QIcon } from "quasar";
import MAction from "../MAction/MAction.vue";

const props = defineProps({
  expand: Boolean,
  expanded: Boolean,

  showClose: Boolean,

  type: {
    type: String,
    default: "system",
  },

  actions: Array,
});

const icon = computed(() => {
  switch (props.type) {
    case "info":
      return "fa-solid fa-info-circle";
    case "warning":
    case "danger":
      return "fa-solid fa-exclamation-triangle";
    case "success":
      return "fa-solid fa-check-circle";
    case "system":
      return "";
  }
  return "";
});

const iconDescription = computed(() => {
  switch (props.type) {
    case "info":
      return "Please note";
    case "warning":
      return "Warning";
    case "danger":
      return "Danger";
    case "success":
      return "Success";
    case "system":
      return "";
  }

  // TODO add warning when icon description is not passed
  return "";
});
</script>

<style lang="scss">
div.banner:not(.q-expansion-item) {
  padding: 10px 15px;
}

.banner.blank {
  border: 1px solid transparent;
}

.banner.banner-expandable {
  align-items: normal;
  flex-direction: column;

  .q-item__section--avatar {
    min-width: auto !important;
    padding-right: 6px;
    > .q-icon {
      font-size: 20px !important;
    }
  }

  &.info {
    .q-item__section--avatar {
      > .q-icon {
        color: var(--status-blue);
      }
    }
  }

  &.danger {
    .q-item__section--avatar {
      > .q-icon {
        color: var(--status-red);
      }
    }
  }

  &.warning {
    .q-item__section--avatar {
      > .q-icon {
        color: var(--status-yellow);
      }
    }
  }

  &.system {
    .q-item__section--avatar {
      > .q-icon {
        color: var(--grey-lightest-non-text);
      }
    }
  }
}

.banner {
  position: relative;
  background: #fff;

  box-sizing: border-box;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  flex: 0 1 auto;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.75rem;
  &.has-title {
    padding: 13px 13px 15px 24px;
  }

  &::before {
    content: " ";
    position: absolute;
    height: auto;
    width: 5px;
    left: 0px;
    top: -1px;
    bottom: -1px;
  }

  .banner-content {
    flex: 1 1 auto;
  }

  .banner-action {
    height: 32px;
  }

  > .q-icon {
    font-size: 20px !important;
  }

  &.system {
    border: 1px solid var(--grey-lightest-non-text);
    &::before {
      background: var(--grey-lightest-non-text);
    }

    > .q-icon {
      color: var(--grey-lightest-non-text);
      color: var(--theme-blue);
    }
  }

  &.info {
    border: 1px solid var(--theme-blue);
    &::before {
      background: var(--theme-blue);
    }

    > .q-icon {
      color: var(--theme-blue);
    }
  }
  &.warning {
    border: 1px solid var(--yellow-dark-non-text);
    &::before {
      background: var(--yellow-dark-non-text);
    }

    > .q-icon {
      color: var(--yellow-dark-non-text);
    }
  }
  &.danger {
    border: 1px solid var(--status-red);
    &::before {
      background: var(--status-red);
    }

    > .q-icon {
      color: var(--status-red);
    }
  }
  &.success {
    border: 1px solid var(--status-green);
    &::before {
      background: var(--status-green);
    }

    > .q-icon {
      color: var(--status-green);
    }
  }

  div.q-item {
    min-height: 40px;
  }
}
</style>
<style lang="scss">
.q-expansion-item__container .q-item__label,
.banner-header {
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
}
</style>
