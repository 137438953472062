import { ref, onUnmounted } from "vue";

/**
 * Utility function to throttle and debouce a callback
 * @param callback The function to call after the end of a throttle or debounce
 * @param debounceDelay The debounce delay in milliseconds
 * @param throttleDelay The throttle delay in millseconds
 * @param immediate Whether the callback should fire immediately
 */
export function useDebouncedThrottledFunction<T extends (...args: any[]) => void>(
  callback: T,
  debounceDelay = 1500,
  throttleDelay = 3000,
  immediate = false,
) {
  const timeoutId = ref<ReturnType<typeof setTimeout> | null>(null);
  const lastInvokeTime = ref<number | null>(null);
  const immediateInvoked = ref(false);

  const debouncedThrottled = ((...args: Parameters<T>) => {
    if (lastInvokeTime.value === null) lastInvokeTime.value = Date.now();

    // Clear debounce timeout on each call
    if (timeoutId.value !== null) {
      clearTimeout(timeoutId.value);
      timeoutId.value = null;
    }

    const currentTime: number = Date.now();
    const throttleDelayPassed = currentTime - lastInvokeTime.value > throttleDelay;

    if (immediate && immediateInvoked.value === false) {
      callback(...args);
      immediateInvoked.value = true;
      lastInvokeTime.value = currentTime;
    }

    if (throttleDelayPassed) {
      // If the last invocation was outside the throttle window, invoke immediately
      lastInvokeTime.value = currentTime;
      callback(...args);
      lastInvokeTime.value = null;

      timeoutId.value = setTimeout(() => {
        // Reset immediate property after delay
        immediateInvoked.value = false;
      }, debounceDelay);
    } else {
      // Debounce the invocation
      timeoutId.value = setTimeout(() => {
        callback(...args);
        lastInvokeTime.value = null;
        immediateInvoked.value = false;
        timeoutId.value = null;
      }, debounceDelay);
    }
  }) as T;

  const cancelDebouncedThrottled = () => {
    if (timeoutId.value !== null) {
      clearTimeout(timeoutId.value);
      timeoutId.value = null;
      lastInvokeTime.value = null;
      immediateInvoked.value = false;
    }
  };

  // Cleanup function to clear the timeout on component unmount
  onUnmounted(cancelDebouncedThrottled);

  return { debouncedThrottled, cancelDebouncedThrottled, timeoutId };
}
