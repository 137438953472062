import { computed } from "@vue/reactivity";
import { useAttrs } from "vue";
export function useInputDisable() {
  const attrs = useAttrs();

  return computed(() => {
    if ("disable" in attrs) {
      return attrs.disable !== false;
    }
    if ("disabled" in attrs) {
      return attrs.disabled !== false;
    }
    return false;
  });
}
