import { App } from "vue";
import axios from "axios";
// This has to be brought in before `signalr` so that window.jQuery can be patched before `signalr` is loaded
import "./nhs/helper";
import "signalr";

export function nhsSigningService() {
  return {
    install(app: App) {
      declare global {
        interface Window {
          signingService: SigningService;
        }
      }

      const globalProperties = app.config.globalProperties;
      window.signingService = globalProperties.$signingService = SigningService();
    },
  };
}

const SigningService = () => {
  const ports = [43487, 44213, 45031, 46478, 48853];
  // These two endpoints are HTTP by design - the NHS Credential Manager uses HTTP locally and not HTTPS.
  // No PII is sent as part of these requests
  const rootPortNumberUrl = "http://localhost:{0}/PRS/GetPortNumber";
  const rootSignalUrl = "http://localhost:{0}/signalr";

  const getConnection = async () => {
    const signingPort = await port();
    if (!signingPort) {
      throw new Error("Unable to determine NHS Signing Service port");
    }

    const connection = $.hubConnection(rootSignalUrl.replace("{0}", signingPort), {
      logging: false,
    });

    if (connection.state !== $.signalR.connectionState.disconnected) {
      throw new Error("Already connected");
    }

    return connection;
  };

  const getProxy = async (connection) => {
    const proxy = connection.createHubProxy("signingHub");
    await connection.start({ transport: "longPolling" });

    return proxy;
  };

  const version = async () => {
    const connection = await getConnection();

    try {
      const proxy = await getProxy(connection);

      const response = await proxy.invoke("getHubVersion");

      return response?.Version;
    } finally {
      connection?.stop();
    }
  };

  const sign = async (jwt: string) => {
    const connection = await getConnection();
    try {
      const proxy = await getProxy(connection);

      return await proxy.invoke("requestToSign", jwt);
    } finally {
      connection?.stop();
    }
  };

  const testPort = async (port) => {
    try {
      const response = await call(rootPortNumberUrl.replace("{0}", port));

      if (response.status === 200 && response.data?.portData?.portNumber) {
        return response.data.portData.portNumber;
      }
    } catch {
      // Nothing to do
    }

    return null;
  };

  const port = async () => {
    for (let i = 0; i < ports.length; i++) {
      const portResponse = await testPort(ports[i]);
      if (null !== portResponse) {
        return portResponse;
      }
    }

    return null;
  };

  const call = function (url: string, method?: string, config?: AxiosConfig) {
    return axios({
      url,
      method: method || "get",
      withCredentials: true,
      ...(config || {}),
    });
  };

  return {
    port,
    sign,
    version,
  };
};

type AxiosConfig = {
  url: string;
  method?: string;
  baseUrl?: string;
  transformRequest?: func;
  transformResponse?: func;
  headers?: object;
  params?: object;
  paramsSerializer?: func;
  data?: object;
  timeout?: number;
  withCredentials?: boolean;
  adapter?: func;
  auth?: {
    username?: string;
    password?: string;
  };
  responseType?: string;
  responseEncoding?: string;
  xsrfCookieName?: string;
  xsrfHeaderName?: string;
  onUploadProgress?: func;
  onDownloadProgress?: func;
  maxContentLength?: number;
  maxBodyLength?: number;
  validateStatus?: func;
  maxRedirects?: number;
  socketPath?: string;
  httpAgent?: object;
  httpsAgent?: object;
  proxy?: {
    protocol: string;
    host: string;
    port: number;
    auth?: {
      username?: string;
      password?: string;
    };
  };
  cancelToken?: func;
  decompress?: boolean;
};
