import { App } from "vue";
import Draggable from "./draggable";
import Fullcalendar from "./fullcalendar";
import Pusher from "./pusher";
import Sentry from "./sentry";

export function thirdPartyPlugins() {
  return {
    install(app: App) {
      Draggable().install(app);
      Fullcalendar().install(app);
      Pusher().install(app);
      if (IS_APP && import.meta.env.PROD) {
        Sentry().install(app);
      }
    },
  };
}
