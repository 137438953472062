import { AppContext, h, reactive, render } from "vue";

import { SnackbarMessage } from "./interfaces";

class SnackbarService {
  private messages: Array<SnackbarMessage & { id: string }>;
  private messageId = 0;

  constructor(appContext?: AppContext) {
    this.messages = reactive([]);

    this.add = this.add.bind(this);
    this.clear = this.clear.bind(this);
    this.remove = this.remove.bind(this);
    this.update = this.update.bind(this);

    import("./Snackbar.vue").then((m) => {
      if (m.default) {
        const a = h(m.default, {
          messages: this.messages,
          onRemove: (item: SnackbarMessage & { id: string }) => {
            this.messages.splice(this.messages.indexOf(item), 1);
          },
        });
        if (appContext) {
          a.appContext = appContext;
        }
        try {
          render(a, document.body);
        } catch {
          // ignore
        }
      }
    });
  }

  add(m: SnackbarMessage & { id?: string }) {
    m.id = m.id || (this.messageId++ && this.messageId.toString());

    // @ts-expect-error not the correct type
    this.messages.push(m);

    return () => {
      // @ts-expect-error not the correct type
      this.messages.splice(this.messages.indexOf(m), 1);
    };
  }

  clear() {
    this.messages.length = 0;
  }

  remove(id: string) {
    const index = this.messages.findIndex((message) => message.id === id);
    this.messages.splice(index, 1);
  }

  update(id: string, message: string) {
    const notification = this.messages.find((message) => message.id === id);

    if (!notification) return false;

    notification.message = message;
  }
}

let service: SnackbarService | undefined = undefined;
export function useSnackbar(appContext?: AppContext) {
  return service ?? (service = new SnackbarService(appContext));
}
