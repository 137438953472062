import "./store/pinia";
import { createApp } from "vue";

// import "uno.css";

import medicus from "./plugins";

import App from "./App.vue";

const app = createApp(App);
app.use(medicus());

app.mount("#m-app");
