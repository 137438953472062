import { ComputedRef, unref } from "@vue/reactivity";
import { Component, Ref, computed, onUpdated, ref, watch } from "vue";
import { Key } from "ts-key-enum";
import { RefTyped } from "vue-composable";

export function useFocusList<T>(
  items: ComputedRef<T[]> | Ref<T[]>,
  hooks: {
    onSelect: (item: T) => void;
    onEscape: () => void;
    onKeydown?: (ev: KeyboardEvent) => void;
  },
  allowSpace: RefTyped<boolean> = true,
  startPos = -1,
) {
  const enabled = ref(true);

  const focusedIndex = ref(Math.min(Math.max(startPos, -1), items.value.length - 1));
  const focusedItem = computed(() => items.value[focusedIndex.value]);

  function moveFocusItem(direction: -1 | 1) {
    const len = items.value?.length ?? -1;

    focusedIndex.value += direction;
    if (focusedIndex.value < 0) {
      focusedIndex.value = len - 1;
    } else if (focusedIndex.value >= len) {
      focusedIndex.value = 0;
    }
  }

  function onKeydown(ev: KeyboardEvent) {
    if (!enabled.value) return;

    switch (ev.code) {
      case Key.ArrowDown: {
        ev.preventDefault();
        moveFocusItem(1);
        break;
      }
      case Key.ArrowUp: {
        ev.preventDefault();
        moveFocusItem(-1);
        break;
      }
      case Key.Escape: {
        ev.preventDefault();
        hooks.onEscape();
        break;
      }
      case "Space": {
        if (unref(allowSpace)) {
          return;
        }
        ev.preventDefault();

        focusedItem.value && hooks.onSelect(focusedItem.value);
        break;
      }
      case Key.Enter:
      case "NumpadEnter": {
        ev.preventDefault();

        focusedItem.value && hooks.onSelect(focusedItem.value);
        break;
      }
      default: {
        focusedIndex.value = 0;
      }
    }
    hooks.onKeydown?.(ev);
  }

  function reset(overridePos = startPos) {
    focusedIndex.value = Math.min(Math.max(overridePos, -1), items.value.length - 1);
  }

  let refItems: Array<Component | HTMLElement | Element> = [];
  onUpdated(() => {
    refItems = [];
  });
  function onRef(r: HTMLElement | Component | Element | null) {
    if (r) {
      refItems.push(r);
    }
  }

  // handles the scrollinto component
  watch(
    focusedIndex,
    (i) => {
      const r = refItems[i];
      if (r) {
        const el: HTMLElement = "$el" in r ? r.$el : r.$el ?? r;
        requestAnimationFrame(
          () => el.scrollIntoView?.({ behavior: "instant", inline: "nearest", block: "nearest" }),
        );
      }
    },
    {
      flush: "post",
    },
  );

  watch(enabled, () => reset());

  return {
    enabled,
    items,
    refItems,

    focusedIndex,
    focusedItem,

    reset,

    onKeydown,
    onRef,
  };
}
