import { App, toRef } from "vue";
import { useAuth } from "../store/auth";
import { useTenant } from "../store/tenant";

// adds tenant specific config
// note this needs to be used on a component, because `useAuth` relies on router
export function tenantPlugin() {
  return {
    install(app: App) {
      const globalProperties = app.config.globalProperties;

      const auth = useAuth();
      const tenant = useTenant();

      globalProperties.$tenant = toRef(auth, "selectedTenant");
      globalProperties.$settings = toRef(auth, "config");

      Object.keys(tenant)
        .filter((x) => x.startsWith("is"))
        .forEach((k: any) => {
          globalProperties[`$${k}`] = toRef(tenant, k);
        });
    },
  };
}
