import { Action } from "../../MAction";

export interface ItemOption {
  label: string;
  value: any;

  color?: string;
  description?: string;
  photoUrl?: string;
  icon?: string;
}

export interface GroupOption {
  title: string;
  action?: Action;

  items: ItemOption[];
}

export const SelectLabelSymbol = Symbol("SelectLabelSymbol");
