import mitt, { Handler } from "mitt";
import { watch } from "vue";
import { NO_OP } from "vue-composable";
const bus = mitt();
export default bus;

export function listen<T>(eventName: string, cb: Handler<T>) {
  // if listen is called in the component context it
  // will be cleared on unmounted
  watch(
    NO_OP,
    (_, __, onInvalidate) => {
      bus.on(eventName, cb);

      onInvalidate(() => {
        bus.off(eventName, cb);
      });
    },
    {
      immediate: true,
    },
  );
}

export function dispatch<T>(eventName: string, arg: T) {
  return bus.emit(eventName, arg);
}
