import { ref, readonly, onUnmounted } from "vue";

export function useOnline() {
  const isOnline = ref<boolean>(true);
  if (navigator.onLine === false) isOnline.value = false;

  const handleOnline = () => {
    isOnline.value = true;
  };

  const handleOffline = () => {
    isOnline.value = false;
  };

  window.addEventListener("online", handleOnline);
  window.addEventListener("offline", handleOffline);

  onUnmounted(() => {
    window.removeEventListener("online", handleOnline);
    window.removeEventListener("offline", handleOffline);
  });

  const getHasConnection = async () => {
    // Check origin response is OK
    const url = new URL(window.location.origin);
    url.searchParams.set("check-network", Date.now().toString()); // Prevent cache

    try {
      const res = await fetch(url.toString(), {
        method: "HEAD",
      });
      return res.ok;
    } catch {
      return false;
    }
  };

  // Check connection to origin is OK
  getHasConnection()
    .then((hasConnection) => {
      isOnline.value = hasConnection;
    })
    .catch(() => {
      isOnline.value = false;
    });

  return { isOnline: readonly(isOnline) };
}
