import Pusher from "pusher-js";
import { App } from "vue";

export const pusher = new Pusher(IS_APP ? import.meta.env.PUSHER_APP_KEY : "", {
  cluster: IS_APP ? import.meta.env.PUSHER_APP_CLUSTER : "",
});
export default function () {
  return {
    install(app: App) {
      app.config.globalProperties.$pusher = pusher;
    },
  };
}
