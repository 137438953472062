import { defineStore } from "pinia";
import { NO_OP, useLocalStorage } from "vue-composable";
import { computed, ref, watch } from "vue";
import * as AuthAPI from "../api/iam";
import type { AuthUser } from "../api/iam";
import { isExpired, removeExpires, updateExpires } from "../utils/session";
import axios from "../utils/axios";
import { type TenantConfig, settings } from "../api/medicus";
import { extractTenant } from "../utils/route";
import { router } from "../router";
import { showDialog } from "../utils/dialog";
import { LocationQueryRaw, RouteLocationNamedRaw } from "vue-router";

// The goal for this composable is to only expose the last promise
// even when the method has been called many times,
// it also cancels previous requests
function useSettings() {
  const promise = ref<null | ReturnType<typeof settings>>(null);
  const result = ref<null | TenantConfig>(null);
  const error = ref<null | any>(null);

  let resolver = null as null | ((_: TenantConfig) => void);
  let rejects = null as null | ((_: any) => void);

  let lastRequest = 0;

  function exec() {
    if (!resolver || !rejects) {
      promise.value = new Promise<TenantConfig>((resolve, reject) => {
        resolver = resolve;
        rejects = reject;
      });
    }

    const currentRequest = ++lastRequest;

    settings({
      cancelRequestId: "app-settings",
    })
      .then((x) => {
        if (currentRequest === lastRequest) {
          result.value = x;
          error.value = null;
          resolver?.(x);
        }
      })
      .catch((e) => {
        if (currentRequest === lastRequest) {
          error.value = e;
          result.value = null;
          rejects?.(e);
        }
      })
      .finally(() => {
        if (currentRequest === lastRequest) {
          resolver = null;
          rejects = null;
        }
      });
    return promise.value;
  }

  return {
    exec,
    promise,
    result,
    error,
  };
}

export const useAuth = defineStore("auth", () => {
  const { storage: user, remove: removeUser } = useLocalStorage<AuthUser | null>("user", null);
  const { storage: selectedTenant } = useLocalStorage<string | null>("tenant", null);
  const { storage: tenants } = useLocalStorage<AuthUser["tenants"] | null>("tenants", null);
  const { storage: hasMFA, remove: removeMFA } = useLocalStorage<"true" | null>("mfa", null);

  const {
    exec: refreshSettings,
    result: config,
    error: configError,
    promise: settingsPromise,
  } = useSettings();

  const userId = computed(() => user.value?.userId);

  const isLoggedIn = computed(() => !!user.value && !isExpired.value);
  const loginUrl = AuthAPI.loginUrl;

  const currentTenant = computed(() =>
    isLoggedIn.value && selectedTenant.value && tenants.value
      ? tenants.value[selectedTenant.value]
      : null,
  );

  const currentLocationValue = computed(() => {
    if (isLoggedIn.value) {
      if (user.value.mostRecentRoomId) {
        return user.value.rooms.find((r) => r.value === user.value.mostRecentRoomId);
      }

      if (user.value?.mostRecentLocationId) {
        return (
          user.value.sites.find((s) => s.value === user.value.mostRecentLocationId) ||
          user.value.adHocLocations.find((l) => l.value === user.value.mostRecentLocationId) ||
          null
        );
      }
    }

    return null;
  });

  const hasLocations = computed(() => {
    if (isLoggedIn.value) {
      return (
        user.value.sites?.length > 0 ||
        user.value.rooms?.length > 0 ||
        user.value.adHocLocations?.length > 0
      );
    } else {
      return false;
    }
  });

  async function login(credentials: { username: string; password: string }) {
    removeExpires();
    localStorage.clear();

    const { data, status } = await AuthAPI.login(credentials);
    if (status !== 200) return;

    updateExpires();
    if (data.mfaRequired === true) {
      hasMFA.value = "true";

      const route: RouteLocationNamedRaw = {
        name: "mfa",
        query: router.currentRoute.value.query || {},
      };

      return router.push(route);
    }
    await refreshUserInfo();
    // do redirect
    return doRedirect();
  }

  async function validateMFA(otpCode: string) {
    const { status } = await AuthAPI.loginMfa({ otpCode });
    if (status !== 200) return;

    removeMFA();
    updateExpires();
    await refreshUserInfo();

    // do redirect
    return doRedirect();
  }

  async function validateRecoveryCode(code: string) {
    const { status } = await AuthAPI.loginRecoveryCode({ code });
    if (status !== 200) return;

    removeMFA();
    updateExpires();
    await refreshUserInfo();

    // do redirect
    return doRedirect();
  }

  function reset() {
    user.value = null;
    localStorage.clear();
  }

  async function logout(
    redirect: boolean | string = false,
    additionalQueryParameters: object | null = null,
  ) {
    user.value = null;
    localStorage.clear();

    // fire and forget
    await AuthAPI.logout().catch(NO_OP);

    if (!IS_APP) return;

    const redirectTo = redirect
      ? redirect === true
        ? router.currentRoute.value.meta.public // don't redirect to public
          ? ""
          : router.currentRoute.value.path
        : redirect
      : "";
    if (
      router.currentRoute.value.name !== "login" &&
      router.currentRoute.value.name !== "unsupported"
    ) {
      let query: LocationQueryRaw = {};
      if (redirectTo) query.redirect = redirectTo;
      if (additionalQueryParameters) query = { ...query, ...additionalQueryParameters };

      location.href = router.resolve({
        name: "login",
        query,
      }).fullPath;
    }
  }

  async function refreshUserInfo() {
    const { data } = await AuthAPI.info();
    user.value = data;
    return user.value;
  }

  async function doRedirect() {
    if (settingsPromise.value) {
      await settingsPromise.value.catch(() => {});
    }
    let redirect: string | { name: string; params: any } = decodeURIComponent(
      router.currentRoute.value.query.redirect?.toString() || "/",
    );
    let tenant = extractTenant(redirect) || selectedTenant.value;

    if (!tenant) {
      const tenantIds = Object.keys(tenants.value || {});
      if (tenantIds.length === 1 && !hasLocations.value) {
        tenant = tenantIds[0];
      } else if (tenantIds.length === 0) {
        return router.push("/no-workspace-access");
      } else {
        return router.push({ name: "tenants", query: router.currentRoute.value.query });
      }
    }

    if (redirect === `/` || !redirect) {
      redirect = {
        name: "homepage",
        params: {
          tenant,
        },
      };
    }

    if (tenant) {
      selectedTenant.value = tenant;
      return router.push(redirect);
    }
  }

  watch(
    user,
    (u) => {
      if (u) {
        tenants.value = u.tenants;
      } else {
        tenants.value = {};
        selectedTenant.value = "";
        removeUser();
      }
    },
    {
      immediate: true,
      flush: "sync",
    },
  );

  watch(
    configError,
    (e) => {
      if (!e) return;
      if (e.response?.status === 401) {
        return;
      }
      showDialog({
        title: "Error",
        text: `There was an error loading the organisation’s settings.\nPlease refresh the page.`,
        noCancel: true,
        okLabel: "Refresh page",
        okColor: "secondary",
      }).onFinally(() => {
        location.reload();
      });
    },
    {
      immediate: true,
    },
  );

  watch(
    () => currentTenant.value,
    (tenant) => {
      if (!tenant) return;
      axios.defaults.baseURL = tenant.url ?? "/";
      refreshSettings();
    },
    {
      immediate: true,
      flush: "sync",
    },
  );

  return {
    currentLocationValue,
    user,
    tenants,
    selectedTenant,
    config,
    hasMFA,

    userId,
    currentTenant,
    hasLocations,
    isLoggedIn,

    loginUrl,
    login,
    reset,
    logout,

    validateMFA,
    validateRecoveryCode,

    refreshUserInfo,

    doRedirect,

    settingsPromise,
  };
});
