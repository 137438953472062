<script setup lang="ts">
import { computed } from "@vue/reactivity";
import { QBtnDropdown, QItemLabel, QItemSection, QList } from "quasar";
import { PropType, ref } from "vue";
import { DrawerUrlOptions } from "../../../store/drawers";
import MItem from "../MItem";
import { usePermission } from "../../../composables/permission";
import { listen } from "../../../utils/bus";

const props = defineProps({
  label: String,

  ghost: Boolean,
  border: Boolean,
  outline: Boolean,

  loading: Boolean,

  click: Function,
  split: Boolean,
  type: String,
  color: String,
  disabled: Boolean,
  disable: Boolean,

  menu: Boolean,

  icon: String,
  iconOnly: Boolean,

  options: [Array, Object] as PropType<
    Array<{ label: string }> | Record<string, { label: string }>
  >,

  toSlideover: [String, Object, Boolean] as unknown as () => string | DrawerUrlOptions | false,
  toModal: [String, Object, Boolean] as unknown as () => string | DrawerUrlOptions | false,
});
const { disabled, onClick } = usePermission(props, true);

listen("close-menus", () => {
  isOpened.value = false;
});

const items = computed(() => {
  const options = props.options;
  if (!options) return [];
  if (Array.isArray(options)) {
    return [
      {
        name: "",
        items: options,
      },
    ];
  }
  return Object.keys(options).map((x) => ({
    name: x,
    items: options[x],
  }));
});

const isFocus = ref(false);

function handleKeyup() {
  isFocus.value = true;
}
function handleBlur() {
  isFocus.value = false;
}

const isOpened = ref(false);
const focusEl = ref<HTMLElement | null>(null);

// enableFocusTrap(focusEl);
</script>
<template>
  <q-btn-dropdown
    v-model="isOpened"
    class="m-dropdown-button"
    :split="split"
    :label="iconOnly ? undefined : label"
    :type="type"
    :color="color || type"
    :dropdown-icon="menu ? icon || 'fa-solid fa-ellipsis-v' : icon"
    :no-icon-animation="menu ? true : false"
    flat
    no-caps
    :loading="loading"
    :class="[
      { disabled: disabled },
      'medicus-outline',
      color || type,
      { 'menu-style': menu, ghost: ghost || menu, raised: !border && !ghost && !menu, outline },
    ]"
    v-bind="$attrs"
    content-class="m-dropdown-button-menu"
    auto-close
    :disable="disable || disabled"
    :toggle-aria-label="
      split
        ? `${isOpened ? 'Close' : 'Open'} more options`
        : `${isOpened ? 'Close' : 'Open'} ${label} menu`
    "
    @click="onClick"
    @keyup="handleKeyup"
    @blur="handleBlur"
  >
    <template v-if="$slots.label || iconOnly" #label>
      <slot name="label">
        <span class="sr-only">{{ label }}</span>
      </slot>
    </template>

    <div ref="focusEl">
      <slot v-bind="{ loading }">
        <q-list :class="{ 'grouped-list': !Array.isArray(options) }">
          <template v-for="(group, index) in items" :key="`option-${index}`">
            <div v-if="group.name" class="dropdown-button-list-title">
              {{ group.name }}
            </div>
            <m-item
              v-for="(option, index) in group.items"
              :key="`option-item-${group.name}-${index}`"
              v-bind="option"
              v-close-popup
              clickable
              role="menuitem"
            >
              <q-item-section>
                <q-item-label>{{ option.label }}</q-item-label>
              </q-item-section>
            </m-item>
          </template>
        </q-list>
      </slot>
    </div>
  </q-btn-dropdown>
</template>

<style lang="scss">
.m-dropdown-button {
  --q-secondary: var(--text-color);

  // text
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  height: 35px;
  /* identical to box height */

  text-align: center;

  border-radius: 4px;

  border: 1px solid var(--theme-accent-blue);
  background: var(--theme-blue);
  color: #ffffff;

  cursor: pointer;

  outline-color: var(--theme-blue);
  outline-width: 20px;

  outline: none;

  padding: 2px 0 2px 12px;

  &.q-btn-dropdown--simple > .q-btn__wrapper {
    padding-right: 9px;

    .q-btn-dropdown__arrow {
      margin-left: 4px;
      margin-right: 0;
    }
  }
  .q-icon {
    // font-size: 1.715em!important;
    margin: 0.35em;
  }

  &.q-btn-dropdown--split {
    // padding: 0 0 0 12px;
    padding: 0 !important;
    border: none !important;

    > :first-child {
      padding: 3px 0;
      border-right: 1px solid !important;

      .q-btn__content {
        padding: 0 12px;
      }
    }
    > :last-child {
      padding: 3px 0;
      border-left: 1px solid !important;
      min-width: 40px;
    }

    button {
      font-weight: bold;
    }
  }

  &.secondary,
  &[type="secondary"] {
    font-weight: bold;
    background: #ffffff;

    outline-color: var(--text-color-light);
    color: var(--text-color);
    border-color: var(--text-color-light);

    &:hover {
      background: #e7eaee;
    }
  }

  &:hover {
    background: var(--theme-accent-blue);
  }

  &.outline {
    color: #ffffff;
    border: 1px solid var(--grey);

    color: #90a0b7;

    &:hover {
      background: var(--grey);
    }
  }

  &.raised:not([type="danger"]):not(.secondary) {
    color: #ffffff;

    background: var(--theme-blue);
    border: 1px solid var(--theme-accent-blue);

    &:hover {
      background: var(--theme-accent-blue);
      border: 1px solid var(--theme-accent-blue);
    }
  }

  &.ghost {
    border: none;
    box-shadow: none;

    color: var(--theme-blue);
    background: transparent;
    font-weight: bold;

    // prevent size changes
    border: 2px solid transparent;

    span.q-btn__content > span {
      text-decoration-line: underline;
      text-underline-offset: 3px;
      &:hover:not(.disabled),
      &:focus-within {
        color: var(--theme-darker-blue);
        text-decoration-width: 3px;
        text-decoration-thickness: 3px;
      }
    }

    .button-content {
      display: contents;
      text-decoration-line: underline;
      text-underline-offset: 3px;
      text-decoration-thickness: 3px;

      &:hover,
      &:focus-within {
        text-decoration-thickness: 3px;
      }
    }

    i {
      text-decoration: none !important;
      text-decoration-line: none !important;
      text-decoration-color: white;
    }

    &:hover {
      background: transparent;

      color: #003078;
      &[type="secondary"] {
        color: var(--text-color);
      }
    }

    &:focus {
      border-radius: 4px;

      border-color: var(--theme-blue);
      outline-color: var(--theme-blue);
      &[type="secondary"] {
        color: var(--grey);
      }
    }
  }

  &.menu-style {
    padding: 0;
    color: var(--text-color);
  }

  &.disabled {
    font-weight: bold;
    background: #ffffff !important;
    color: var(--grey) !important;
    .q-focus-helper {
      background: #ffffff !important;
    }
    &.q-btn-dropdown--split {
      border: 1px solid var(--grey-light) !important;
      .q-btn-dropdown__arrow-container {
        pointer-events: none;
      }
      :last-child {
        border-left: none !important;
      }
    }
    .button-content {
      background: #ffffff !important;
      border-color: var(--grey-light) !important;
      color: var(--grey) !important;
      text-decoration-line: none !important;
    }
    &:hover {
      background: #ffffff !important;
      border-color: var(--grey-light) !important;
      color: var(--grey) !important;
    }
  }

  .q-focus-helper {
    background: transparent !important;
  }
}
.m-dropdown-button-menu {
  max-height: 90vh;
  .q-list {
    & > a {
      min-height: 40px;
    }

    &.grouped-list {
      .dropdown-button-list-title {
        min-height: 40px !important;
        // margin-top: 0.5rem;

        font-family: Arial, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;

        color: var(--grey-darkest);

        padding-left: 1rem;

        display: flex;
        align-items: center;
      }

      .q-item {
        // margin: 5;
        min-height: 30px !important;
        border-radius: 4px;

        font-size: 14px;
        padding: 6px 16px;

        // margin: 0 0.5rem;

        /*.q-focus-helper::after {
          background: var(--grey-light);
          opacity: 1;
        }*/
      }
    }
  }
}
</style>
