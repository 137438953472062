import { router } from "../router";
import { showSnackbar } from "../utils/snackbar";

export function updatePlugin() {
  return {
    install() {
      const currentVersion = import.meta.env.RELEASE_NUMBER;
      console.log("medicus version", currentVersion);
      const headers = new Headers();
      headers.append("pragma", "no-cache");
      headers.append("cache-control", "no-cache");

      const ms = 60 * 1 * 1000; // 1min

      const removeIntervalId = setInterval(async () => {
        const v = await fetch("/version", { cache: "no-cache", headers })
          .then((x) => x.text())
          .catch(() => {
            return currentVersion;
          });
        // currentVersion is stored as `"${version}"` instead of `${version}`
        if (v.indexOf(currentVersion) === -1) {
          console.log("[version] new version found", v);
          clearInterval(removeIntervalId);
          const autoRefresh = ["login", "tenants"];

          if (autoRefresh.find((x) => x === router.currentRoute.value.name)) {
            location.reload();
            return;
          }

          router.afterEach((to) => {
            // if is slideover or modal should not refresh
            if (to.query?.slideover || to.query?.modal) return;

            console.log("refresh because", to.query);
            // wait until the location is resolved
            setTimeout(() => location.reload(), 200);
          });

          showSnackbar({
            message: "Medicus has been updated. Please refresh the page to get the latest version.",
            type: "warning",
            persistant: true,
            actions: [
              {
                label: "Refresh",
                onClick() {
                  location.reload();
                },
              },
            ],
          });
        }
      }, ms);
    },
  };
}
