import { AxiosResponse } from "axios";
import axios from "../utils/axios";

const iamUrl = IS_APP ? import.meta.env.IAM_BASE_URL : process.env.IAM_BASE_URL;

export interface AuthUser {
  userId?: string;
  username?: string;
  tenants?: Record<string, AuthTenant>;
  sites?: Array<any>;
  rooms?: Array<any>;
  adHocLocations?: Array<any>;
  mostRecentLocationId?: string;
  mostRecentRoomId?: string;
  mostRecentLocationType?: string;
  // when used with SmartCard
  cis2Uuid?: string;
}

export interface Auth2FA {
  mfaRequired: true;
}

export interface AuthTenant {
  name: string;
  url: string;
}

export const loginUrl = `${iamUrl}/user/login`;

export function login(data: { username: string; password: string }) {
  return axios.post<AuthUser | Auth2FA>(loginUrl, data, {
    showError: false,
  });
}

export function loginMfa(data: { otpCode: string }) {
  return axios.post<AuthUser>(`${iamUrl}/user/login/mfa`, data, {
    showError: false,
  });
}
export function loginRecoveryCode(data: { code: string }) {
  return axios.post<void>(`${iamUrl}/user/recover-mfa`, data, {
    showError: false,
  });
}

export function nhsLogin(data: { code: string; state: string }): Promise<
  | (Omit<AxiosResponse<AuthUser>, "status"> & { status: 200 })
  // this is to provide a short on typescript, to infer the correct response type
  | (AxiosResponse<{ error: string }> & { status: 400 })
> {
  return axios.post(`${iamUrl}/user/gb-nhs-cis2-login`, data, {
    showError: false,
  });
}
export function logout() {
  return axios.get(`${iamUrl}/user/logout`);
}
export function info() {
  return axios.get<AuthUser>(`${iamUrl}/user/info`);
}
export function issuePasswordReset(data: { username: string }) {
  return axios.post(`${iamUrl}/user/send-password-reset`, data, {
    showError: false,
  });
}
export function resetPasswordQuery(resetKey: string) {
  return axios.get<{ resetKey: string }>(`${iamUrl}/user/password-reset/${resetKey}`, {
    cancelRequestId: "user-password-reset-query",
    showError: false,
  });
}
export function validatePassword(data: {
  password: { password: string; passwordConfirm: string };
}) {
  return axios.post(`${iamUrl}/user/validate-password`, data, {
    showError: false,
  });
}
export function resetPassword(data: {
  password: { password: string; passwordConfirm: string };
  resetKey: string;
  otpCode: string;
}) {
  return axios.post(`${iamUrl}/user/reset-password`, data, {
    showError: false,
  });
}

export function setupUser(data: {
  username: string;
  password: { password: string; passwordConfirm: string };
  setupCode: string;
  otpCode: string;
  secret: string;
}) {
  return axios.post(`${iamUrl}/user/setup`, data, {
    showError: false,
  });
}

export function gbNhsCis2PairedUsers() {
  return axios.get<AuthUser>(`${iamUrl}/user/gb-nhs-cis2/paired-users`);
}
export function gbNhsCis2PairedUserValidate(data: object) {
  // const { add: showSnackbar } = useSnackbar();

  return axios
    .post<AuthUser>(`${iamUrl}/user/gb-nhs-cis2/paired-users/validate`, data, {
      showError: false,
    })
    .then((_response) => {})
    .catch((response) => {
      const error = response.response.data?.errors?.command?.[0];
      if (error) {
        return error;
      }

      return response;
    });
}
export function gbNhsCis2PairedUserRemove(data: object) {
  return axios.post<AuthUser>(`${iamUrl}/user/gb-nhs-cis2/paired-users/remove`, data);
}

export function getMfaSecret(username: string, setupCode: string) {
  return axios.get<{ qrCode: string; secret: string }>(
    `${iamUrl}/user/setup/${encodeURIComponent(username)}/${setupCode}`,
    {
      showError: false,
    },
  );
}

export function updateUserCurrentLocation(data: object) {
  return axios.post(`${iamUrl}/user/update-current-location`, data);
}
