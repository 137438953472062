import MockAdapter from "axios-mock-adapter";
import { defineStore } from "pinia";
import { computed, nextTick, ref, watch } from "vue";
import axios from "../utils/axios";
import { useAuth } from "./auth";
import { pusher } from "../plugins/thirdparties/pusher";
import { useSnackbar } from "../composables/snackbar";
import {
  ActivateAlertPayload,
  ActiveAlertsResponse,
  DesktopData,
  PanicAlert,
  PanicAlertResponse,
  PanicButtonDesktopAppPairingPayload,
  PanicButtonLocationGroup,
  PanicButtonRespondToAlertPayload,
  ServerData,
} from "./panicButton.types";
import { Channel } from "pusher-js";
import { AxiosResponse, isAxiosError } from "axios";
import { randomID } from "../utils/helpers";

// This function is used to mock hardware panic button integration for testing
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function enableDesktopAPIMock() {
  const mock = new MockAdapter(axios, { onNoMatch: "passthrough" });
  const pairPathRegex = /http:\/\/localhost:\d{1,5}\/pair/;
  mock.onPost(pairPathRegex).reply(200, {
    staffId: "018e8499-06f0-79e3-9f67-1a67cd01857b",
    locationId: "018ea84c-5bf4-761d-a183-7345975e492f",
    windowsUserName: "asmith",
    windowsMachineName: "HPPV10723",
    windowsOperatingSystemVersion: "Microsoft Windows NT 10.0.19044.0",
    desktopAppVersion: "1.2.3.0",
  });
}

export const usePanicButton = defineStore("panic-button", () => {
  // enableDesktopAPIMock(); // Uncomment this function call to enable desktop integration mocking

  // Desktop Pairing Properties
  const isDesktopPaired = ref<boolean>(false);
  const isPusherChannelSubsribed = ref<boolean>(false);
  const pairedPort = ref<number | null>(null);
  const desktopData = ref<DesktopData | null>(null);
  const serverData = ref<ServerData | null>(null);

  // State
  const authStore = useAuth();
  const snackbarStore = useSnackbar();

  const currentLocation = ref<{ id: string; type: string } | null>(null);
  const activeAlerts = ref<PanicAlert[]>([]);
  const otherLocationsActiveAlerts = ref<PanicAlert[]>([]);
  const alertsToDisplay = ref<PanicAlert[]>([]); //Alerts that take over current users screen
  const isInitialised = ref<boolean>(false);
  const channel = ref<Channel | null>(null);
  const isPanicAlertsModalShowing = ref<boolean>(false);
  const isSendingPanicAlert = ref<boolean>(false);

  // Reactive Properties
  const hasActivePanicAlert = computed(() => allAlertsCount.value > 0);

  const isPanicButtonFlashing = computed(() => {
    if (!authStore.config) return false;

    return activeAlerts.value?.some(
      (alert) => alert.triggeringStaffId === authStore.config.staff.id,
    );
  });

  const isSomeoneResponding = computed(() => {
    if (isPanicButtonFlashing.value === false) return false;

    const currentUserAlert = activeAlerts.value?.find(
      (alert) => alert.triggeringStaffId === authStore.config.staff.id,
    );

    return currentUserAlert?.responses.some((response) => response.action === "responding");
  });

  const allAlertsCount = computed(() => {
    return activeAlerts.value.length + otherLocationsActiveAlerts.value.length;
  });

  const alertsGroupedByLocation = computed<PanicButtonLocationGroup[]>(() => {
    const locationGroups: PanicButtonLocationGroup[] = [];

    // Put current location on top
    if (activeAlerts.value.length > 0 && authStore.user?.mostRecentLocationId) {
      const siteName: string | undefined = authStore.user.sites.find(
        (site) => site.value === authStore.user.mostRecentLocationId,
      )?.label;

      locationGroups.push({
        label: `Current Location ${siteName ? `(${siteName})` : ""}`,
        labelId: `panic-button-current-location-group-label-${randomID()}`,
        alerts: activeAlerts.value,
        isCurrentLocation: true,
      });
    }

    if (otherLocationsActiveAlerts.value.length > 0) {
      locationGroups.push({
        label: "Other Locations",
        labelId: `panic-button-other-locations-group-label-${randomID()}`,
        alerts: otherLocationsActiveAlerts.value,
        isCurrentLocation: false,
      });
    }

    return locationGroups;
  });

  watch(
    [
      () => authStore.user?.mostRecentLocationId,
      () => authStore.user?.mostRecentRoomId,
      () => authStore.selectedTenant,
    ],
    async ([newLocationId, newRoomId, _newSelectedTenant]) => {
      if (authStore.isLoggedIn && authStore.selectedTenant) {
        if (!newLocationId) {
          currentLocation.value = null;
          disconnectPanicButton();
          await Promise.all([refreshServerData(), authStore.settingsPromise]);
          pairWithDesktopApp();
        } else {
          await authStore.settingsPromise;
          currentLocation.value = {
            id: newRoomId ?? newLocationId,
            type: authStore.user.mostRecentLocationType,
          };

          nextTick(async () => {
            await refreshServerData();

            if (serverData.value && !serverData.value.panicButtonDisabledReason) {
              initPanicButton();
            }
          });
        }
      }
    },
    {
      immediate: true,
      flush: "post",
    },
  );

  function subscribeToPusherChannel() {
    if (serverData.value?.panicButtonNotificationChannel) {
      channel.value = pusher.subscribe(serverData.value?.panicButtonNotificationChannel);
      channel.value.bind("pusher:subscription_succeeded", () => {
        isPusherChannelSubsribed.value = true;
      });

      channel.value.bind("panic-alert-created", fetchActiveAlerts);
      channel.value.bind("panic-alert-cancelled", fetchActiveAlerts);
      channel.value.bind("panic-alert-response-created", fetchActiveAlerts);
    }
  }

  async function fetchActiveAlerts() {
    if (serverData.value?.panicButtonActiveAlertsURL) {
      const getAlertsForCurrentLocation = axios.get<ActiveAlertsResponse>(
        serverData.value.panicButtonActiveAlertsURL,
      );

      const getAlertsForOtherLocations =
        serverData.value.panicButtonActiveAlertsURLsForOtherLocations.map(
          (otherLocationAlertsURL) => {
            return axios.get<ActiveAlertsResponse>(otherLocationAlertsURL);
          },
        );

      const [currentLocationResponse, otherLocationsResponses] = await Promise.all([
        getAlertsForCurrentLocation,
        Promise.all(getAlertsForOtherLocations),
      ]);

      activeAlerts.value = currentLocationResponse.data.panicAlerts; //Logic handled by activeAlerts watcher

      otherLocationsActiveAlerts.value = otherLocationsResponses.flatMap(
        (response) => response.data.panicAlerts,
      );
    }
  }

  /**
   * Whenever activeAlerts changes from pusher events or on page load, check for differences in state and do necessary updates
   */
  watch(activeAlerts, (currentActiveAlerts, prevActiveAlerts) => {
    const currentUserId = authStore.config?.staff.id;

    if (currentUserId) {
      // Display newly added alerts (that are not the current user)
      const filteredAlerts = currentActiveAlerts?.filter(
        (alert) =>
          alert.triggeringStaffId !== currentUserId &&
          alert.responses?.every((response) => response.staffId !== currentUserId),
      );

      let newAlerts = filteredAlerts;

      if (prevActiveAlerts) {
        // Take out all alerts from previous state
        newAlerts = filteredAlerts?.filter((alert) =>
          prevActiveAlerts.every((prevAlert) => alert.id !== prevAlert.id),
        );
      }

      newAlerts?.forEach(displayPanicAlert);

      // Mark alerts that have been removed as cancelled
      const deactivatedAlerts = prevActiveAlerts.filter((prevAlert) =>
        currentActiveAlerts.every((activeAlert) => prevAlert.id !== activeAlert.id),
      );

      deactivatedAlerts.map((alert) => alert.id).forEach(markAlertCancelled);

      // Remove alerts that current user has responded to
      const userRespondedAlerts = currentActiveAlerts?.filter((alert) => {
        const userHasRespondedToAlert = alert.responses?.some(
          (response) => response.staffId === currentUserId,
        );

        if (userHasRespondedToAlert) {
          const prevAlert = prevActiveAlerts.find((prevAlert) => prevAlert.id === alert.id);
          const isResponseNew =
            !prevAlert ||
            prevAlert.responses.every((prevResponse) => prevResponse.staffId !== currentUserId);

          if (isResponseNew) return true;
        }

        return false;
      });

      const alertsToRemove = alertsToDisplay.value.filter((alert) =>
        userRespondedAlerts.some((respondedAlert) => alert.id === respondedAlert.id),
      );

      alertsToRemove.map((alert) => alert.id).forEach(removePanicAlert);

      // Update other alerts with who is responding
      const alertsWithNewResponses = currentActiveAlerts?.filter((alert) => {
        // Filter out alerts with no responses
        // and alerts the current user has responded to
        // and alerts not in prev state (new alerts)
        const hasResponses = alert.responses?.length > 0;
        const currUserHasResponded = userRespondedAlerts.includes(alert);
        const prevAlert = prevActiveAlerts.find((prevAlert) => prevAlert.id === alert.id); // If there's no previous state, it's a whole new alert

        if (hasResponses === false || currUserHasResponded === true || !prevAlert) {
          return false;
        }

        // Compare to previous state to determine if the alert needs updating
        const hasNewResponse = alert.responses.some(
          (response) =>
            prevAlert.responses.find(
              (prevResponse) => prevResponse.staffId === response.staffId,
            ) === undefined,
        );

        return hasNewResponse;
      });

      alertsWithNewResponses?.forEach((alertWithNewResponse) => {
        const index = alertsToDisplay.value.findIndex(
          (alert) => alert.id === alertWithNewResponse.id,
        );

        if (index >= 0) {
          alertsToDisplay.value = alertsToDisplay.value.map((alert) => {
            if (alert.id === alertWithNewResponse.id) {
              return alertWithNewResponse;
            } else {
              return alert;
            }
          });
        }
      });
    }
  });

  async function getConfig(locationType?: string, locationId?: string) {
    let CONFIG_URL = "/integrations/windows-desktop-app/configuration";

    if (locationType && locationId) {
      CONFIG_URL += `/${locationType}/${locationId}`;
    }

    const { data } = await axios.get(CONFIG_URL);
    return data;
  }

  async function refreshServerData() {
    if (currentLocation.value) {
      serverData.value = await getConfig(currentLocation.value?.type, currentLocation.value?.id);
    } else {
      serverData.value = await getConfig();
    }
  }

  async function initPanicButton() {
    pairWithDesktopApp();
    subscribeToPusherChannel();
    await fetchActiveAlerts();
    isInitialised.value = true;
  }

  function disconnectPanicButton() {
    isDesktopPaired.value = false;
    activeAlerts.value = [];
    alertsToDisplay.value = [];

    if (isPusherChannelSubsribed.value) {
      channel.value.unsubscribe();
      channel.value = null;
      isPusherChannelSubsribed.value = false;
    }

    isInitialised.value = false;
  }

  async function pairWithDesktopApp() {
    isDesktopPaired.value = false;

    if (serverData.value && authStore.config && authStore.user) {
      const REQUEST_TIMEOUT = 2000;
      const INITIAL_PORT = 23330;
      const PORT_RANGE = 10;
      const MAX_PORT = INITIAL_PORT + PORT_RANGE;
      const desktopPairingPayload: PanicButtonDesktopAppPairingPayload = {
        ...serverData.value,
        staffId: authStore.config.staff.id,
        locationId: authStore.user.mostRecentLocationId,
        roomId:
          authStore.user.mostRecentRoomId?.length > 0 ? authStore.user.mostRecentRoomId : null,
      };

      const sentryDSN = import.meta.env.SENTRY_DSN;
      if (sentryDSN) {
        desktopPairingPayload.sentryDSN = sentryDSN;
      }

      const sentryEnvironment = import.meta.env.APP_ENVIRONMENT;
      if (sentryEnvironment) {
        desktopPairingPayload.sentryEnvironment = sentryEnvironment;
      }

      let port = INITIAL_PORT;

      while (isDesktopPaired.value === false && port < MAX_PORT) {
        try {
          const desktopPairResponse = await axios.post<
            PanicButtonDesktopAppPairingPayload,
            AxiosResponse<DesktopData>
          >(`http://localhost:${port}/pair`, desktopPairingPayload, {
            timeout: REQUEST_TIMEOUT,
            showError: false,
            withCredentials: false,
          });

          isDesktopPaired.value = true;
          desktopData.value = desktopPairResponse.data;
          pairedPort.value = port;
        } catch (error) {
          if (isAxiosError(error)) {
            if (error.code === "ECONNABORTED") {
              // Timeout
              console.error(
                "Hardware Panic Button Pair Timeout Occurred: Retrying with next port...",
              );
            }

            if (error.code === "ERR_NETWORK") {
              // Connection failure
              // Hiding error because 10 errors show in the console every load when the desktop applet is not running
              // console.error(
              //   "Hardware Panic Button Pair Connection Failure: Retrying with next port...",
              // );
            }
          }

          port++;
        }
      }
    }
  }

  async function checkForActivePanicAlerts() {
    if (serverData.value?.panicButtonActiveAlertsURL) {
      const currentLocationRequest = axios.get<ActiveAlertsResponse>(
        serverData.value.panicButtonActiveAlertsURL,
        {
          withCredentials: false,
        },
      );

      const otherLocationsRequests: Promise<AxiosResponse<ActiveAlertsResponse, any>>[] =
        serverData.value.panicButtonActiveAlertsURLsForOtherLocations.map((otherLocationURL) => {
          return axios.get<ActiveAlertsResponse>(otherLocationURL);
        });

      const [{ data: currentLocationData }, otherLocationsResponses] = await Promise.all([
        currentLocationRequest,
        Promise.all(otherLocationsRequests),
      ]);
      // moved to currentAlerts variable as unable to unpack when panicAlerts undefined or null
      const currentAlerts = currentLocationData.panicAlerts ?? [];
      const newActiveAlerts: PanicAlert[] = [
        ...currentAlerts,
        ...otherLocationsResponses.flatMap(
          (otherLocationResponse) => otherLocationResponse.data.panicAlerts,
        ),
      ];

      activeAlerts.value = newActiveAlerts;
    }
  }

  async function sendPanicAlert() {
    if (
      serverData.value?.panicButtonActivateAlertURL &&
      authStore.userId &&
      isPanicButtonFlashing.value === false &&
      isSendingPanicAlert.value === false
    ) {
      isSendingPanicAlert.value = true;

      const payload: ActivateAlertPayload = {
        locationId: authStore.user.mostRecentLocationId,
        roomId:
          authStore.user.mostRecentRoomId?.length > 0 ? authStore.user.mostRecentRoomId : null,
      };

      if (authStore.user.mostRecentLocationId) {
        payload.locationId = authStore.user.mostRecentLocationId;
      }

      if (authStore.user.mostRecentRoomId) {
        payload.roomId = authStore.user.mostRecentRoomId;
      }

      if (desktopData.value?.windowsUserName) {
        payload.windowsUser = desktopData.value.windowsUserName;
      }

      try {
        await axios.post<ActivateAlertPayload>(
          serverData.value.panicButtonActivateAlertURL,
          payload,
          { showError: true, withCredentials: false },
        );
      } catch (error) {
        if (error) {
          snackbarStore.add({
            message: "Medicus could not send a panic alert because of an error.",
            type: "warning",
          });
        }
      } finally {
        isSendingPanicAlert.value = false;
      }
    }
  }

  async function respondToAlert(panicAlertId: string, response: PanicAlertResponse) {
    const payload: PanicButtonRespondToAlertPayload = {
      panicAlertId,
      action: response,
      respondingWindowsUserId: desktopData.value?.windowsUserName ?? null,
    };

    await axios.post<PanicButtonRespondToAlertPayload, AxiosResponse<{}>>(
      serverData.value.panicButtonRespondToAlertURL,
      payload,
      {
        withCredentials: false,
      },
    );
  }

  async function cancelPanicAlert(id: string) {
    return axios.post(
      serverData.value.panicButtonCancelAlertURL,
      {
        panicAlertId: id,
      },
      { withCredentials: true },
    );
  }

  function displayPanicAlert(panicAlert: PanicAlert) {
    alertsToDisplay.value = [...alertsToDisplay.value, panicAlert];
  }

  function removePanicAlert(id: string) {
    const index = alertsToDisplay.value.findIndex((alert) => alert.id === id);

    if (index >= 0) {
      alertsToDisplay.value.splice(index, 1);
    }
  }

  function markAlertCancelled(id: string) {
    const index = alertsToDisplay.value.findIndex((alert) => alert.id === id);

    if (index >= 0) {
      alertsToDisplay.value[index].isCancelled = true;
    }
  }

  function hidePanicAlertModal() {
    isPanicAlertsModalShowing.value = false;
  }

  function showPanicAlertModal() {
    isPanicAlertsModalShowing.value = true;
  }

  return {
    isDesktopPaired,
    pairedPort,
    serverData,
    desktopData,
    currentLocation,
    hasActivePanicAlert,
    activeAlerts,
    otherLocationsActiveAlerts,
    alertsToDisplay,
    isInitialised,
    isPanicAlertsModalShowing,
    isPanicButtonFlashing,
    isSomeoneResponding,
    alertsGroupedByLocation,
    allAlertsCount,
    initPanicButton,
    pairWithDesktopApp,
    subscribeToPusherChannel,
    checkForActivePanicAlerts,
    sendPanicAlert,
    cancelPanicAlert,
    hidePanicAlertModal,
    showPanicAlertModal,
    respondToAlert,
    fetchActiveAlerts,
    removePanicAlert,
  };
});
