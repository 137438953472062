import Axios, { AxiosInstance } from "axios";
import { startLoading, stopLoading } from "../composables/focus";

// We create our own axios instance and set a custom base URL.
// Note that if we wouldn't set any config here we do not need
// a named export, as we could just `import axios from 'axios'`
const axios: AxiosInstance & {
  CancelToken: typeof Axios.CancelToken;
  isCancel: typeof Axios.isCancel;
} = Axios.create({
  withCredentials: true,
}) as any;

axios.CancelToken = Axios.CancelToken;
axios.isCancel = Axios.isCancel;

export default axios;

export const CancelToken = Axios.CancelToken;

// etag cache based on https://github.com/raulanatol/axios-etag-cache/
const cache = new Map();
function isCacheableMethod(config: any) {
  return ~["GET", "HEAD"].indexOf(config.method.toUpperCase());
}
const getHeaderCaseInsensitive = (headerName: string, headers = {}) =>
  headers[
    Object.keys(headers).find((x) => x.toLocaleLowerCase() === headerName.toLocaleLowerCase())
  ];

const mapCancelTokens = new Map<string, () => void>();

axios.interceptors.request.use((config) => {
  startLoading();
  if (isCacheableMethod(config)) {
    const uuid = config.url;
    const lastCachedResult = cache.get(uuid);
    if (lastCachedResult) {
      // @ts-expect-error types not fully match
      config.headers = { ...config.headers, "If-None-Match": lastCachedResult.etag };
    }
  }

  if (!config.cancelToken && config.cancelRequestId) {
    mapCancelTokens.get(config.cancelRequestId)?.();

    config.cancelToken = new Axios.CancelToken((cancel) => {
      mapCancelTokens.set(config.cancelRequestId!, cancel);
    });
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    stopLoading();
    if (isCacheableMethod(response.config)) {
      const responseETAG = getHeaderCaseInsensitive("etag", response.headers);
      if (responseETAG) {
        cache.set(response.config.url, { etag: responseETAG, data: response.data });
      }
    }
    return response;
  },
  (error) => {
    stopLoading();

    //Don't do anything if is a cancel or a failed panic button pairing attempt
    if (axios.isCancel(error)) {
      return new Promise(() => {});
    }

    if (error.response && error.response.status === 304) {
      const getCachedResult = cache.get(error.response.config.url);
      if (!getCachedResult) {
        return Promise.reject(error);
      }
      const newResponse = error.response;
      newResponse.status = 200;
      newResponse.data = getCachedResult.data;
      return Promise.resolve(newResponse);
    }

    return Promise.reject(error);
  },
);

// / etag cache
