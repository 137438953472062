import { Pinia, createPinia } from "pinia";

export let pinia = createPinia();

/**
 * @deprecated only to be used in testing
 * @param p new Pinia
 */
export function updateAppPinia(p: Pinia) {
  pinia = p;
}
