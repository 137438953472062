<script setup lang="ts">
import { ComponentPublicInstance, computed, inject, onUnmounted, toRef } from "vue";
import { NO_OP, useEvent } from "vue-composable";
import { useRouter } from "vue-router";
import { useFocusList } from "../../../../composables/focus-list";
import MLayoutStack from "../../../medicus/MLayoutStack";
import ContactCardRender from "./ContactCardRender.vue";
import { Patient } from "./helpers";

const props = defineProps<{
  title: string;
  items: Patient[];

  emptyText?: string;
  disableNavigation?: boolean;
  target?: HTMLElement | ComponentPublicInstance | null;

  onSelect?: (patient: Patient) => void;
}>();

const router = useRouter();

const close = inject("close", NO_OP);

const { focusedItem, onRef, onKeydown } = useFocusList(
  toRef(props, "items"),
  {
    onEscape() {},
    onSelect,
  },
  true,
  0,
);

function onSelect(item: Patient) {
  if (props.disableNavigation) return;
  if (props.onSelect) {
    props.onSelect(item);
  } else {
    router.push(`/patient/patient/care-record/${item.value}`);
  }
  close();
}

const remove = useEvent(
  computed(() => props.target?.$el ?? props.target ?? null),
  "keydown",
  (e: KeyboardEvent) => {
    // if is space do not handle
    if (e.code === "Space") return;
    onKeydown(e);
  },
  {
    capture: true,
  },
);
onUnmounted(remove);
</script>
<template>
  <MLayoutStack class="overflow-hidden" full gap="1">
    <span v-if="items.length" class="q-px-md contact-search-title">{{ title }}</span>
    <MLayoutStack v-if="items.length" class="scroll-area q-px-md q-pb-md" gap="2">
      <ContactCardRender
        v-for="item in items"
        :ref="onRef"
        :key="item.value"
        :item="item"
        :focused="item === focusedItem"
        @click="onSelect(item)"
      />
    </MLayoutStack>

    <div v-else-if="emptyText" class="h-full q-px-md self-center flex-auto flex items-center">
      <span class="empty-text">{{ emptyText }}</span>
    </div>
  </MLayoutStack>
</template>
<style lang="scss">
.contact-search-title {
  color: var(--grey-darkest);
}
</style>
