import { isArray, promisedTimeout } from "vue-composable";

export async function downloadFile(url: string | Blob, name: string = "") {
  const a = document.createElement("a");
  a.download = name;
  if (typeof url === "object") {
    a.href = URL.createObjectURL(url);
  } else {
    a.href = url?.toString() || "";
  }
  a.style.display = "none";
  document.body.append(a);
  a.dispatchEvent(new MouseEvent("click"));

  if (typeof url === "object") {
    window.URL.revokeObjectURL(a.href);
  }

  // Chrome requires the timeout
  await promisedTimeout(100);
  a.remove();
}

export async function downloadFiles(..._files: string[] | [string[]]) {
  let files: (string | Blob)[] =
    _files.length === 1 && isArray(_files[0]) ? (_files[0] as string[]) : (_files as string[]);

  let rename: (a: string | Blob, index: number) => string = () => "";

  if (typeof files[files.length - 1] === "function") {
    // @ts-expect-error is already function
    rename = files[files.length - 1];
    files = files.slice(0, -1);
  }

  // disable for now
  // eslint-disable-next-line no-constant-condition
  if (false && "BackgroundFetchManager" in self && "serviceWorker" in navigator) {
    await navigator.serviceWorker.ready.then(async (swReg) => {
      const bgFetch = await (swReg as any).backgroundFetch.fetch(Date.now() + "medicus", files);

      return bgFetch;
    });
  } else {
    // Provide fallback downloading.
    for (let i = 0; i < files.length; i++) {
      const url = files[i];
      await promisedTimeout(i * 1000);
      downloadFile(url, rename(url, i));
    }
  }
}
