<script setup lang="ts">
import { debounce } from "quasar";
import MLayoutStack from "../../../medicus/MLayoutStack";
import MInput from "../../../medicus/inputs/MInput";
import MLink from "../../../medicus/MLink";
import { inject, ref, watch } from "vue";
import { Patient } from "./helpers";

import RecentContacts from "./RecentContacts.vue";
import { NOOP } from "@vue/shared";
import axios from "../../../../utils/axios";
import { usePromiseLazy } from "vue-composable";
import Contacts from "./Contacts.vue";
import AdvancedSearchComponent from "./AdvancedSearchComponent.vue";
import MFormActions from "../../../medicus/MFormActions/MFormActions.vue";
import PageStepper from "../../../medicus/MPageStepper";

defineProps<{
  onSelect?: (patient: Patient) => void;
}>();

inject<(e: boolean) => void>("removePadding", NOOP)(true);
const close = inject("close", NOOP);

const disableNavigation = ref(false);
const search = ref<string>("");
const searchInputEl = ref<InstanceType<typeof MInput> | null>(null);
defineExpose({ search });

watch(searchInputEl, (s) => s?.focus());

const { result, exec, loading } = usePromiseLazy((query: string) =>
  axios
    .get<{ results: Patient[] }>("/patient/patient-finder", {
      params: {
        query,
      },
      cancelRequestId: "patient-finder",
    })
    .then((x) => x.data.results),
);

watch(
  [search],
  debounce(([search]: [string]) => exec(search), 200),
);

watch(
  search,
  () => {
    disableNavigation.value = true;
  },
  { flush: "sync" },
);

const emptyText = ref("");

watch(result, () => {
  emptyText.value = `There are no matches for "${search.value}".`;
  disableNavigation.value = false;
});
const pages = [
  "initial",
  { code: "advanced", backText: "Back" },
  { code: "advanced-results", backText: "Back" },
];
</script>
<template>
  <PageStepper class="patient-search q-pt-sm flex-auto" :items="pages">
    <template #initial="{ next }">
      <div class="search-component flex flex-col flex-nowrap h-full flex-auto">
        <MLayoutStack class="overflow-hidden medicus-grey-background flex-auto" full>
          <div />
          <MFormActions class="q-px-md">
            <MInput
              ref="searchInputEl"
              v-model="search"
              placeholder="Search patient name, DOB or NHS number"
              data-testid="search-patient-input"
            />
            <MLayoutStack horizontal class="q-mt-sm justify-end">
              <MLink text="Advanced options" @click="() => next({})" />
            </MLayoutStack>
          </MFormActions>

          <Suspense>
            <div
              v-if="loading"
              class="flex flex-col items-center justify-center flex-auto h-full empty-text"
              data-testid="searching"
            >
              Searching…
            </div>
            <template v-else-if="typeof result?.length === 'number' && search">
              <Contacts
                key="contact-list"
                title=""
                :items="result"
                :empty-text="emptyText"
                :disable-navigation="disableNavigation"
                :on-select="onSelect"
                :target="searchInputEl"
              />
            </template>
            <RecentContacts
              v-else
              :disable-navigation="disableNavigation"
              :on-select="onSelect"
              :target="searchInputEl"
            />

            <template #fallback>
              <div class="flex flex-col items-center justify-center flex-auto h-full empty-text">
                Loading
              </div>
            </template>
          </Suspense>
        </MLayoutStack>
      </div>
    </template>
    <template #advanced="{ next, state }">
      <AdvancedSearchComponent class="q-px-md" :search="state" @cancel="close" @submit="next" />
    </template>
    <template #advanced-results="{ state }">
      <div
        class="search-component flex flex-col flex-nowrap h-full medicus-grey-background flex-auto"
      >
        <MLayoutStack class="overflow-hidden" full>
          <Contacts
            key="contact-list"
            :items="state"
            title="Matching Patients"
            empty-text="No matches found."
            :on-select="onSelect"
          />
        </MLayoutStack>
      </div>
    </template>
  </PageStepper>
</template>
<style lang="scss">
.search-component {
  overflow: hidden;

  min-height: 400px;

  .header-commands {
    color: var(--grey-darkest);

    border-top: 1px solid var(--border-colour);

    .escape-key-header {
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 13px;

      margin-right: 0.25rem;
    }
  }
}
.patient-search {
  .stepper--header {
    margin-left: 16px;
  }
}
</style>
