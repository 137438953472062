import { App, defineAsyncComponent } from "vue";

export default function () {
  return {
    install(app: App) {
      app.component(
        "VueDraggable",
        defineAsyncComponent(() => import("vuedraggable")),
      );
    },
  };
}
