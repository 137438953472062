<script setup lang="ts">
import { QIcon } from "quasar";
import { Key } from "ts-key-enum";
import { computed, ref, watch } from "vue";
import { randomID } from "../../../utils/helpers";
import TransitionExpand from "../../transitions/TransitionExpand.vue";

const props = defineProps({
  id: String,

  title: String,
  // auto height
  auto: Boolean,

  transitionName: {
    type: String,
    default: "expand-medicus",
  },
  expanded: Boolean,

  borderless: Boolean,

  dense: Boolean,

  count: Number,

  hideHeader: Boolean,
});

const emit = defineEmits({
  expand: (_state: boolean) => true,
  "update:expanded": (_state: boolean) => true,
});

const expandedState = ref(false);
const expanderId = props.id || randomID("m-expander-");

watch(
  () => props.expanded,
  (v) => (expandedState.value = v),
  {
    immediate: true,
  },
);

function toggle() {
  expandedState.value = !expandedState.value;
  emit("expand", expandedState.value);
  emit("update:expanded", expandedState.value);
}

const ariaButton = computed(() => ({
  "aria-expanded": expandedState.value,
  "aria-controls": expanderId,
}));
</script>

<template>
  <div class="m-expander" :class="{ bordered: !borderless, expanded: expandedState }">
    <slot
      v-if="!hideHeader"
      name="head"
      v-bind="{
        expanded: expandedState,
        toggle,
        ariaButton,
      }"
    >
      <div
        class="m-expander-head flex flex-row medicus-outline"
        :class="{
          'with-padding': !dense,
        }"
        v-bind="ariaButton"
        role="button"
        tabindex="0"
        @click="toggle"
        @keydown="(e) => (e.key === Key.Enter || e.key === ' ' ? toggle() : null)"
      >
        <span v-if="count" class="m-expander--counter">{{ count }}</span>

        <slot name="title" v-bind="{ expanded: expandedState, toggle, count, ariaButton }">
          <div class="flex-auto">
            <h4 class="normal-weight">{{ title }}</h4>
          </div>
        </slot>
        <slot name="expand-button">
          <q-icon v-if="expandedState" name="fa-solid fa-chevron-up" />
          <q-icon v-else name="fa-solid fa-chevron-down" />
        </slot>
      </div>
    </slot>
    <div :id="expanderId">
      <transition-expand v-if="auto" :name="transitionName">
        <template v-if="expandedState">
          <slot />
        </template>
      </transition-expand>
      <transition v-else :name="transitionName">
        <template v-if="expandedState">
          <slot />
        </template>
      </transition>
    </div>
    <slot name="footer" />
  </div>
</template>

<style lang="scss">
.m-expander {
  display: flex;
  flex-direction: column;

  &.bordered {
    border-bottom: 1px solid var(--border-colour);
  }
  &.expanded {
    padding-bottom: 6px;
  }

  > * {
    padding-left: 5px;
    padding-right: 5px;
  }

  .m-expander--counter {
    background-color: var(--grey-darker);
    padding: 0px 7.5px;
    border-radius: 10px;

    color: var(--grey-lightest);

    margin-right: 7px;
    line-height: 150%;
  }

  .m-expander-head {
    cursor: pointer;
    display: flex;
    flex: 1 1 auto;
    align-items: center;

    color: var(--grey-darkest);

    border-radius: 4px;
    outline: none;

    h4.normal-weight {
      font-weight: normal;
    }

    &.with-padding {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    > .q-icon {
      color: var(--theme-blue) !important;
      margin-right: 10px;
    }

    &:hover {
      background-color: var(--grey-lightest);
    }
  }
}
</style>
<style>
.expand-medicus-enter-active,
.expand-medicus-leave-active {
  transition: height 0.5s ease-in-out;
  overflow: hidden;
}

.expand-medicus-enter,
.expand-medicus-leave-to {
  height: 0;
}

.expand-medicus-fast-enter-active,
.expand-medicus-fast-leave-active {
  transition: height 0.1s ease-in-out;
  overflow: hidden;
}

.expand-medicus-fast-enter,
.expand-medicus-fast-leave-to {
  height: 0;
}
</style>
