<script lang="ts" setup>
import { ref } from "vue";
import MTooltip from "./medicus/MTooltip";
import { QIcon } from "quasar";
defineProps({
  text: String,
});
const iconEl = ref<QIcon | null>(null);
</script>
<template>
  <q-icon ref="iconEl" name="fa-solid fa-circle-question">
    <m-tooltip :text="text" :scroll-target="iconEl?.$el">
      <slot>
        <span>{{ text }}</span>
      </slot>
    </m-tooltip>
  </q-icon>
</template>
