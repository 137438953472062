import { date } from "quasar";
import { isString } from "vue-composable";

export const INVALID_FORMAT_MESSAGE = "[INVALID DATE FORMAT]";

function invalidDateFormat(e: any) {
  try {
    // just to keep the call stack
    throw new Error(`${e} has invalid format`);
  } catch (e) {
    console.error(e);
    window.captureException?.(e);
    return INVALID_FORMAT_MESSAGE;
  }
}

// parsing date based on Medicus requirements
// MED-9661
export function parseDate(e: string | Date | number | any) {
  if (!e) return "";
  if (e instanceof Date) return e;
  if (typeof e === "number") {
    return new Date(e);
  }
  if (!isString(e)) return invalidDateFormat(e);

  const [dateStr, timeStr] = e.split(" ");
  const [year, month, day] = dateStr.split("-").map((x) => +x);

  // let d = new Date(year, month - 1, day);
  let d = new Date(year, month - 1, day);
  if (timeStr) {
    const [hours, minutes, seconds] = timeStr.split(":").map((x) => +x);
    // hour validation
    if (hours > 23 || hours < 0 || minutes < 0 || minutes > 59 || seconds < 0 || seconds > 59)
      return invalidDateFormat(e);

    d = date.adjustDate(d, {
      hours,
      minutes: minutes,
      seconds: seconds ?? 0,
    });

    // // remove possible timezone info
    // d = date.addToDate(d, { minutes: d.getTimezoneOffset() });
  }

  if (d.getFullYear() !== year || d.getMonth() !== month - 1 || d.getDate() !== day) {
    return invalidDateFormat(e);
  }

  return d;
}

export function formatDateTime(e: any, weekday?: boolean) {
  if (!e) return "";
  const d = parseDate(e);
  if (d === INVALID_FORMAT_MESSAGE) return d;
  // TODO this should be driven by organisation locale or user?
  return date.formatDate(d, `${weekday ? "ddd " : ""}DD MMM YYYY, HH:mm`) || "";
}

export function formatDate(e: any, weekday?: boolean) {
  return formatDateTime(e, weekday).split(",")[0];
}

export function formatDateTimeMedicus(e: any) {
  if (!e) return "";
  const d = parseDate(e);
  if (d === INVALID_FORMAT_MESSAGE) return d;
  return date.formatDate(d, "YYYY-MM-DD HH:mm:ss") || "";
}

export function getAge(e: any, t = new Date(), append?: boolean) {
  if (!e) return "";
  const d = parseDate(e);
  if (d === INVALID_FORMAT_MESSAGE) return d;
  const months = date.getDateDiff(t, d, "months");
  const r = months >= 12 ? Math.floor(months / 12) : months;

  if (append) {
    return `${r}${months >= 12 ? "y" : "m"}`;
  }
  return r;
}

export function getMonths(e: any, t = new Date()) {
  if (!e) return "";
  const d = parseDate(e);
  if (d === INVALID_FORMAT_MESSAGE) return d;
  return Math.floor(date.getDateDiff(t, d, "months"));
}

export function getMinutes(e: any, t = new Date()) {
  if (!e) return "";
  const d = parseDate(e);
  if (d === INVALID_FORMAT_MESSAGE) return d;
  return Math.floor(date.getDateDiff(t, d, "minutes"));
}
