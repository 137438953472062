import { reactive, ref, warn } from "vue";
import { defineStore } from "pinia";
import { KeyboardShortcut, KeyboardShortcutId } from "./types";

export const useKeyboardShortcutsStore = defineStore("keyboard-shortcuts", () => {
  const keyboardShortcuts = ref<KeyboardShortcut[]>([
    {
      id: "patient-finder",
      name: "Patient finder",
      altControl: true,
      code: "KeyF",
      key: "F",
    },
    {
      id: "panic-button",
      name: "Activate panic button",
      controlCommand: true,
      shift: true,
      code: "KeyP",
      key: "P",
    },
    {
      id: "submit-form",
      name: "Submit form",
      controlCommand: true,
      code: "Enter",
      key: "Enter",
    },
    {
      id: "complete-consultation",
      name: "Complete consultation",
      controlCommand: true,
      code: "Enter",
      key: "Enter",
    },
    {
      id: "collapse-main-menu",
      name: "Collapse main menu",
      altControl: true,
      code: "BracketLeft",
      key: "[",
    },
    {
      id: "expand-main-menu",
      name: "Expand main menu",
      altControl: true,
      code: "BracketRight",
      key: "]",
    },
    {
      id: "show-keyboard-shortcuts-modal",
      name: "Show keyboard shortcuts modal",
      altControl: true,
      shift: true,
      code: "Slash",
      key: "/",
    },
    {
      id: "close-drawer-item",
      name: "Close Modal / Slideover",
      code: "Escape",
      key: "Esc",
    },
  ]);

  const activeShortcutListeners = reactive(
    new Map<KeyboardShortcutId, (event: KeyboardEvent) => void>(),
  );

  function handleKeyPress(event: KeyboardEvent, shortcut: KeyboardShortcut) {
    const isMacOS = navigator.userAgent.includes("Macintosh");

    if (shortcut.code?.toLowerCase() !== event.code?.toLowerCase()) return;
    if (shortcut.shift && !event.shiftKey) return;
    if (shortcut.altControl && !event.altKey) return;
    if (shortcut.controlCommand) {
      const isControlCommandKeyPressed = isMacOS ? event.metaKey : event.ctrlKey;
      if (isControlCommandKeyPressed === false) return;
    }

    event.preventDefault();
    shortcut.callback?.(event);
  }

  function activateShortcut(id: KeyboardShortcutId, callback: Function) {
    const shortcut = keyboardShortcuts.value.find((shortcut) => shortcut.id === id);

    if (!shortcut) {
      warn(`Shortcut with ID ${id} not found.`);
      return;
    }

    if (shortcut.callback) {
      warn("Shortcut already has a callback attached.");
      return;
    }

    const listener = (event: KeyboardEvent) => handleKeyPress(event, shortcut);
    window.addEventListener("keydown", listener, { capture: true });
    activeShortcutListeners.set(id, listener);

    shortcut.callback = callback;
  }

  function deactivateShortcut(id: KeyboardShortcutId) {
    const shortcut = keyboardShortcuts.value.find((shortcut) => shortcut.id === id);

    if (!shortcut) {
      warn(`Shortcut with ID ${id} not found.`);
      return;
    }

    const listener = activeShortcutListeners.get(id);
    if (listener) {
      window.removeEventListener("keydown", listener, { capture: true });
      activeShortcutListeners.delete(id);
    }

    shortcut.callback = undefined;
  }

  return {
    keyboardShortcuts,
    activeShortcutListeners,

    activateShortcut,
    deactivateShortcut,
  };
});
