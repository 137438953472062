import { isString } from "@vue/shared";
import axios from "axios";
import { App } from "vue";
export function warningHandlerPlugin() {
  return {
    install(app: App) {
      const _warnHandler = app.config.warnHandler;
      const _errorHandler = app.config.errorHandler;

      app.config.warnHandler = (msg, instance, trace) => {
        _warnHandler?.(msg, instance, trace);
        // console.log("msg", msg, instance, trace);
        errorPresenter(msg, trace);
        console.warn(msg, trace);
      };

      app.config.errorHandler = (msg, vm, trace) => {
        _errorHandler?.(msg, vm, trace);
        console.warn(msg, trace);
      };

      window.addEventListener("error", (event) => {
        errorPresenter(event.message, event.error);
      });
      window.addEventListener("unhandledrejection", (event) => {
        const { reason } = event;
        if (axios.isCancel(reason) || axios.isAxiosError(reason)) return;
        errorPresenter(event.reason, "");
      });
    },
  };
}

function createModal() {
  const div = document.createElement("div");

  div.classList.add("error-dialog");
  const dialogs = document.querySelectorAll(".error-dialog");
  const gap = dialogs ? dialogs.length * 3 + "px" : "3px";
  div.style.marginLeft = gap;
  div.style.marginTop = gap;

  div.innerHTML = `
    <div class="error-dialog-heading flex justify-between">
     <h3>Warning</h3>
    </div>
  `;

  const closeButton = document.createElement("button");
  closeButton.textContent = "×";
  closeButton.onclick = () => {
    div.remove();
  };

  div.children.item(0)?.append(closeButton);

  return div;
}

function errorPresenter(msg: string, trace: any) {
  const div = createModal();

  const content = document.createElement("div");
  content.innerText = `${msg}\n${isString(msg) && msg.startsWith("Label missing") ? "" : trace}`;

  div.appendChild(content);

  document.body.appendChild(div);
}
