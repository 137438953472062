import { onMounted, onBeforeUnmount } from "vue";
import { useKeyboardShortcutsStore } from "../store/keyboard-shortcuts";
import { KeyboardShortcutId } from "../store/keyboard-shortcuts/types";

export function useKeyboardShortcut(id: KeyboardShortcutId, callback: (e: KeyboardEvent) => void) {
  const keyboardShortcuts = useKeyboardShortcutsStore();

  onMounted(() => {
    keyboardShortcuts.activateShortcut(id, callback);
  });

  onBeforeUnmount(() => {
    keyboardShortcuts.deactivateShortcut(id);
  });
}
