import { unref } from "vue";

export function validAttribute(v: any) {
  if (typeof v === "string") {
    return true;
  }
  return typeof v !== "undefined" && v;
}

export function getFocussableElements(el: HTMLElement, excludeInvalidTabIndex = false) {
  const focussableElements =
    'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
  return Array.from<HTMLElement>(el?.querySelectorAll(focussableElements) || []).filter(
    (x) => "focus" in x && (!excludeInvalidTabIndex || x.getAttribute("tabindex") != "-1"),
  );
}

export function unrefObject(obj: Record<string, any>) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      return [key, unref(value)];
    }),
  );
}

export function escapeRegex(text: String) {
  return text.replace(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&");
}

/**
 * Searches an array of strings or objects based on a query string.
 *
 * @param items The array to search. Can be an array of strings or an array of objects.
 * @param query The search text to find in the array.
 * @param objectKey If using an array of objects, the string property key of the object to use for searching.
 * @returns An array of items that matched the search, sorted alphabetically, with first word matches on top.
 * @throws Error if `objectKey` is not provided and `items` is an array of objects.
 */
export function searchArray(items: string[], query: string): string[];
export function searchArray<T>(items: T[], query: string, propertyKey: keyof T): T[];
export function searchArray<T>(
  items: string[] | T[],
  query: string,
  objectKey?: keyof T,
): string[] | T[] {
  if (!Array.isArray(items)) {
    throw new Error("The first argument must be an array.");
  }

  const escapedQuery = escapeRegex(query);

  const regex = new RegExp(`\\b${escapedQuery}`, "i");
  const startsWithRegex = new RegExp(`^${escapedQuery}`, "i");

  const isStringArray = typeof items[0] === "string";
  const isObjectArray = typeof items[0] === "object" && items[0] !== null;

  if (isStringArray) {
    return (items as string[])
      .filter((item) => regex.test(item))
      .sort((a, b) => sortByStartsWith(a, b, startsWithRegex));
  } else if (isObjectArray) {
    if (objectKey === undefined) {
      throw new Error("Object key must be provided for an array of objects.");
    }

    return (items as T[])
      .filter((item) => {
        const value = item[objectKey];
        return regex.test(String(value));
      })
      .sort((a, b) => {
        const aValue = a[objectKey];
        const bValue = b[objectKey];
        return sortByStartsWith(String(aValue), String(bValue), startsWithRegex);
      });
  } else {
    throw new Error("Unsupported array type.");
  }
}

/**
 * Helper function to sort items based on whether they start with the query string.
 *
 * @param a The first item to compare.
 * @param b The second item to compare.
 * @param startsWithRegex The regex to test if the item starts with the query string.
 * @returns A number indicating the sort order.
 */
export function sortByStartsWith(a: string, b: string, startsWithRegex: RegExp): number {
  const escapedA = escapeRegex(a);
  const escapedB = escapeRegex(b);
  const aStartsWith = startsWithRegex.test(escapedA);
  const bStartsWith = startsWithRegex.test(escapedB);
  return (bStartsWith ? 1 : 0) - (aStartsWith ? 1 : 0);
}

export function isScrollable(node: Element): boolean {
  if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
    return false;
  }

  const style = getComputedStyle(node);

  return ["overflow", "overflow-x", "overflow-y"].some((propertyName) => {
    const value = style.getPropertyValue(propertyName);
    return value === "auto" || value === "scroll";
  });
}

export function getScrollParent(node: Element): Element {
  let currentParent = node.parentElement;

  while (currentParent) {
    if (isScrollable(currentParent)) {
      return currentParent;
    }
    currentParent = currentParent.parentElement;
  }

  return document.scrollingElement || document.documentElement;
}
