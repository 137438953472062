<script lang="ts" setup>
import { isString } from "@vue/shared";
import { QIcon } from "quasar";
import { Key } from "ts-key-enum";
import { computed, getCurrentInstance, nextTick, provide, ref, watch, watchEffect } from "vue";
import {
  debounce,
  isArray,
  isObject,
  useDebounce,
  useEvent,
  useOnOutsidePress,
} from "vue-composable";
import { InputRules, useInputRules } from "../../../../composables/horizontalLabel";
import axios, { CancelToken } from "../../../../utils/axios";
import { randomID } from "../../../../utils/helpers";
import { getFocussableElements } from "../../../../utils/misc";
import MButton from "../../MButton";
import MLayoutStack from "../../MLayoutStack";
import MMenu from "../../MMenu";
import MBadge from "../../MBadge";
import MInput from "../MInput";
import SelectRenderList from "./SelectRenderList.vue";
import { GroupOption, ItemOption, SelectLabelSymbol } from "./_types";

const props = withDefaults(
  defineProps<{
    modelValue?: any;
    name?: string;

    multiple?: boolean;

    label?: string;
    placeholder?: string;

    for?: string;

    options?: Array<GroupOption | ItemOption>;

    hasPhoto?: boolean;

    clearable?: boolean;

    useInput?: boolean;

    debounce?: string | number;

    minSearchValueLength?: string | number;

    helper?: string;

    useChips?: boolean;

    border?: boolean;

    noValidation?: boolean;

    showAllErrors?: boolean;
    requiredText?: string;
    instructions?: string;
    rules?: InputRules;

    autofocus?: boolean;

    search?: (
      v: string,
      update: Function,
      cancelToken: InstanceType<typeof CancelToken>,
    ) => Promise<any>;
    searchFilter?: (item: string) => boolean;
    searchUrl?: string;
    // searchMinLen?: number;
    itemMap?: (v: any) => any;
  }>(),
  {
    useInput: undefined,
    border: true,
  },
);

const emit = defineEmits({
  "update:modelValue": (_value: any) => true,
  "input-value": (_option: ItemOption) => true,
  "search-results": (_results: Array<any>) => true,

  blur: () => true,
});

const inputEl = ref<InstanceType<typeof MInput> | null>(null);
const { proxy } = getCurrentInstance()!;

const debounceMs = computed(() => props.debounce || (props.search && 10));

const originalValue = props.modelValue;

const { value, displayErrors, formTouched, touched } = useInputRules(
  props,
  () => {
    //focus
    inputEl.value?.focus();
  },
  [],
  true,
  () => props.requiredText || `Enter a value for '${props.label}'`,
  debounceMs,
  () => {
    inputEl.value.$el.scrollIntoView?.();
  },
);

const selectedOption = ref<ItemOption | null>(null);
const selectedOptions = ref<Array<ItemOption>>([]);

provide("formData", undefined);
provide("validationController", undefined);

const text = ref("");
const isMousedown = ref(false); // Used to determine if input focus came from a click or a tab
const focusedItemId = ref("");
const searchedItems = ref<Array<GroupOption | ItemOption> | null>(null);

const isInput = computed(() =>
  Boolean((props.useInput !== false && !props.multiple) || props.search || props.searchUrl),
);

const filteredItems = computed(() => {
  if (searchedItems.value) return searchedItems.value;
  if (
    text.value === selectedOption.value?.label ||
    text.value === selectedOptions.value.map((x) => x.label)?.join(", ")
  ) {
    return props.options ?? [];
  }
  if (!props.options) return [];
  if (!text.value) {
    return props.options;
  }

  const needle = `${text.value ?? ""}`.toLocaleLowerCase();

  return props.options
    .map((it) => {
      if ("items" in it) {
        const items = it.items.filter((i) => i.label.toLocaleLowerCase().includes(needle));
        if (items.length === 0) {
          return null;
        }
        return {
          ...it,
          items,
        };
      }
      if (it.label.toLocaleLowerCase().includes(needle)) return it;
      return null;
    })
    .filter(Boolean);
});

const keydownCB = ref<(ev: KeyboardEvent) => void | null>(null);

function onKeydown(e: KeyboardEvent) {
  if ((e.target as HTMLButtonElement).tagName === "BUTTON") {
    return;
  }
  let newOpen = isOpen.value;

  switch (e.code) {
    case Key.Tab:
      newOpen = false;
      break;
    case Key.ArrowDown:
    case Key.ArrowUp: {
      e.preventDefault();
      if (!newOpen) {
        newOpen = true;
      }
      break;
    }
    case "Space": {
      if (isInput.value || (e.target as HTMLInputElement).hasAttribute("multiple")) {
        return;
      }
    }
    // eslint-disable-next-line no-fallthrough
    case Key.Enter:
      e.preventDefault();
      if (props.multiple) {
        newOpen = true;
      } else {
        newOpen = !newOpen;
      }
      break;
    case Key.Escape: {
      e.preventDefault();
      if (isOpen.value) {
        e.stopPropagation();
      }
      newOpen = false;
      break;
    }
    case Key.Backspace:
    case Key.Delete: {
      if (isInput.value) return;

      if ((props.clearable && !props.multiple) || !!(props.search || props.searchUrl)) {
        selectedOption.value = null;
        text.value = "";
        value.value = null;
      }
      break;
    }
  }

  keydownCB.value?.(e);

  // only update the state after the keydownCB has done it's thing
  isOpen.value = newOpen;
}
function onMenuKeydown(ev: KeyboardEvent) {
  onKeydown(ev);
}

function updateKeydown(cb: (ev: KeyboardEvent) => void) {
  keydownCB.value = cb;
}

function onSelect(e: GroupOption | ItemOption) {
  if ("title" in e) return;

  emit("input-value", e);

  if (props.multiple) {
    // check if clicked option is in selectedOptions list to determine add or remove
    const i = selectedOptions.value.findIndex((x) => x.value === e.value);
    if (i >= 0) {
      selectedOptions.value.splice(i, 1);
    } else {
      selectedOptions.value.push(e);
    }
    return;
  }
  setOption(e);
  isOpen.value = false;
}
function onClick(e: MouseEvent) {
  if ((e.currentTarget as HTMLDivElement).contains(e.target as HTMLElement)) {
    isOpen.value = !isOpen.value;
  }
}

function getLabel(e: any) {
  if (!e) return "";
  return (
    e.label || e.text || e.displayName || e.name || e.description || e || e[SelectLabelSymbol] || ""
  );
}

function setOption(e: ItemOption | any) {
  // if is the value, just ignore
  if (selectedOption.value && selectedOption.value.value === value.value && value.value === e) {
    if (e === null || e === undefined) {
      unsetOption();
    }
    return;
  }

  if (e != null) {
    if (props.options) {
      let found = false;
      const p = e;

      // find by instance

      // instance object
      const ins = isObject(e) ? ("value" in e ? e.value : e) : e;
      for (const it of props.options) {
        if ("items" in it) {
          const t = it.items.find((i) => i.value === ins);
          if (t) {
            found = true;
            e = t;
            break;
          }
        } else if (it.value === ins) {
          found = true;
          e = it;
        }
      }
      if (!found) {
        const str = isObject(e) ? JSON.stringify("value" in e ? e.value : e) : null;
        for (const it of props.options) {
          if ("items" in it) {
            const t = it.items.find((i) => (str ? str === JSON.stringify(i.value) : i.value === e));
            if (t) {
              found = true;
              e = t;
              break;
            }
          } else if (str ? JSON.stringify(it.value) === str : it.value === e) {
            found = true;
            e = it;
          }
          if (e?.value) break;
          if (found) break;
        }
      }
      if (!found) {
        // if the value is empty and no options matched it should not set the value
        if (!e) {
          unsetOption();
          return;
        }
        // just believe the value the user passed in
        if (e === p) {
          const keys = Object.keys(e);
          if (keys.length !== 2 || !keys.includes("label") || !keys.includes("value")) {
            e = {
              label: getLabel(e),
              value: e,
            };
          }
        }
      }
    }
  } else {
    unsetOption();
    return;
  }
  if (!isObject(e)) {
    unsetOption();
    return;
  }

  selectedOption.value = e as ItemOption;
  text.value = getLabel(e);
  value.value = e.value;
}

function unsetOption() {
  selectedOption.value = null;
  text.value = "";
  value.value = null;
}

watch(
  value,
  async (v, o) => {
    if (null === v && o) {
      onClear();
      return;
    }
    if (v && typeof v === "object") {
      if (v === selectedOption.value?.value) return;
      if (Array.isArray(v)) {
        // same object
        if (JSON.stringify(v) === JSON.stringify(o)) return;
        // if not multiple, just ignore
        if (!props.multiple) {
          return;
        }

        const options: Array<ItemOption> = [];
        for (const it of filteredItems.value) {
          if ("items" in it && it.items) {
            options.push(...it.items);
          } else {
            options.push(it as ItemOption);
          }
        }

        selectedOptions.value = options?.filter((x) => v.includes(x.value)) ?? [];
      } else {
        const keys = Object.keys(v);
        if (keys.length == 2 && keys.includes("label") && keys.includes("value")) {
          if (props.multiple) {
            selectedOptions.value = [v];

            return;
          }

          selectedOption.value = v;
          text.value = getLabel(v);
          value.value = v.value;
        }
      }
    }

    // ENG-8731
    if (!props.options) {
      if (isString(v)) {
        selectedOption.value = { label: v, value: v };
        text.value = v;
        value.value = v;
      } else if (isObject(v) && !Array.isArray(v)) {
        const keys = Object.keys(v);
        if (keys.length == 2 && keys.includes("label") && keys.includes("value")) {
          // @ts-expect-error not the correct type
          selectedOption.value = v;
          text.value = getLabel(v);
          value.value = v.value;

          return;
        }
        selectedOption.value = {
          label: getLabel(v),
          value: v,
        };
        text.value = getLabel(v);
        value.value = v;
      }
      return;
    }

    if (Array.isArray(v) || props.search || props.searchUrl) {
      return;
    }

    setOption(v);
  },
  { immediate: true, flush: "pre" },
);

let lastSearchCancel: Function | undefined;
const isFetching = ref(false);

async function doSearch(searchText: string) {
  if (props.minSearchValueLength && searchText.length < props.minSearchValueLength) {
    return;
  }

  try {
    lastSearchCancel?.();
    isFetching.value = true;
    let currentSearch: () => void = null;
    const cancelToken = new axios.CancelToken((cancel) => {
      currentSearch = lastSearchCancel = () => {
        cancel();
      };
    });

    const v = searchText;

    try {
      const results = props.search
        ? await props.search(v, (_cb: any) => {}, cancelToken)
        : await axios.get(props.searchUrl!, {
            cancelToken,
            params: {
              query: v,
            },
          });

      const data = isArray(results)
        ? results
        : isArray(results.data)
        ? results.data
        : results.data.results || [];

      emit("search-results", data);

      const items = props.itemMap ? data.map(props.itemMap) : data;

      if (currentSearch === lastSearchCancel) {
        searchedItems.value = props.searchFilter ? items.filter(props.searchFilter) : items;
        isOpen.value = true;
      }
    } catch (e) {
      currentSearch();
      if (currentSearch === lastSearchCancel) {
        lastSearchCancel = undefined;
        isFetching.value = false;
      }
    } finally {
      if (currentSearch === lastSearchCancel) {
        isFetching.value = false;
      }
    }
  } finally {
    //
  }
}

const searchFn = ref(doSearch);

watchEffect(() => {
  searchFn.value = debounceMs.value ? debounce(doSearch, +debounceMs.value) : doSearch;
});

watch(
  text,
  (v, p, onInvalidate) => {
    if (!v) {
      if (props.search || props.searchUrl) {
        searchedItems.value = [];
      }

      return;
    }
    if (!props.search && !props.searchUrl) return;
    if (v === selectedOption.value?.label) return;

    searchFn.value(v);
    onInvalidate(() => {
      lastSearchCancel?.();
    });
  },
  {
    flush: "sync",
  },
);

watch(
  selectedOptions,
  (o) => {
    if (!o || !props.multiple) return;
    const n = o
      .map((x) => x.value)
      .filter((x) => x !== null && x !== undefined)
      .sort();

    const v = [...((value.value ?? []) as [])].sort();
    if (JSON.stringify(n) === JSON.stringify(v)) return;
    value.value = n;
    text.value = "";
  },
  {
    flush: "sync",
    deep: true,
    immediate: true,
  },
);

const emitBlur = useDebounce(() => emit("blur"), 10);

useOnOutsidePress(
  computed(() => proxy.$el),
  () => {
    if (isOpen.value) {
      isOpen.value = false;
      emitBlur();
    }
  },
);

function onBlur(e: FocusEvent) {
  if (e.relatedTarget) {
    const target = e.relatedTarget as HTMLElement;

    if (proxy.$el.contains(target)) return;
  }

  // reset text
  if (props.multiple) {
    text.value = "";
  } else if (text.value !== selectedOption.value?.label) {
    onClear();
  }

  isOpen.value = false;
  emitBlur();

  if (formTouched) {
    setTimeout(() => {
      const t = (touched.value = touched.value || value.value !== originalValue);

      if (!proxy?.$.isUnmounted) {
        formTouched(t);
      }
    }, 50);
  }
}

function onClear() {
  if (isFetching.value) {
    lastSearchCancel?.();
    isFetching.value = false;
  }

  setOption(null);

  if (props.multiple) {
    selectedOptions.value = [];
  }

  text.value = "";
  value.value = null;
  isOpen.value = false;
}

function removeIndex(index: number) {
  if (selectedOptions.value?.length) {
    selectedOptions.value.splice(index, 1);
  } else {
    (value.value as [])?.splice(index, 1);
  }

  nextTick(() => {
    const badges = (proxy?.$el as HTMLInputElement).querySelectorAll<HTMLButtonElement>(
      ".input.select--chips .badge button",
    );

    if (badges[index]) {
      badges[index]?.focus();
    } else {
      getFocussableElements(proxy.$el as HTMLElement)[0]?.focus();
    }
  });
}

const isOpen = ref(false);
const id = computed(() => (props.for ? props.for : randomID("select")));
const menuId = computed(() => (props.for ? `${props.for}-menu` : randomID("select-menu")));

const open = () => (isOpen.value = true);
const focus = () => inputEl.value?.focus();

useEvent(
  computed(() => inputEl.value?.$el),
  "blur",
  onBlur,
  { capture: true },
);

useEvent(window, "mouseup", () => {
  isMousedown.value = false;
});

const clear = onClear;
defineExpose({
  open,
  focus,

  clear,

  isOpen: isOpen,

  isInput,
  text,
});

function handleInput() {
  isOpen.value = true;
}
</script>
<template>
  <MInput
    ref="inputEl"
    v-bind="$props"
    v-model="text"
    :class="['m-select', (isInput || !useChips || !multiple) && 'use-input']"
    :for="id"
    :instructions="instructions"
    :autofocus="autofocus"
    :options="undefined"
    :search="undefined"
    :placeholder="placeholder"
    :use-input="undefined"
    :debounce="undefined"
    :autogrow="multiple || useChips"
    :clearable="clearable || !!(search || searchUrl)"
    :loading="isFetching"
    :aria-controls="menuId"
    :aria-owns="menuId"
    :aria-expanded="isOpen"
    :aria-activedescendant="focusedItemId"
    input-role="combobox"
    data-form-type="other"
    no-validation
    :readonly="$attrs.readonly || !isInput"
    :show-clear="multiple && (clearable || !!(search || searchUrl)) ? value?.length > 0 : undefined"
    :display-errors="displayErrors"
    @keydown="onKeydown"
    @click="onClick"
    @clear="onClear"
    @mousedown="isMousedown = true"
    @input="handleInput"
    @focus="
      () => {
        if (isMousedown === false) {
          inputEl?.selectText();
        }
      }
    "
  >
    <template v-if="$slots.prepend" #prepend>
      <slot name="prepend" />
    </template>
    <template #append>
      <q-icon
        v-if="!search && !searchUrl"
        :class="['select--icon cursor-pointer', isOpen && 'rotate-180']"
        name="fas fa-caret-down"
      />
    </template>

    <template v-if="useChips || multiple" #input="{ id, inputRole, autocomplete }">
      <div
        class="input select--chips"
        tabindex="0"
        :aria-labelledby="`label-${id}`"
        :role="inputRole"
        :aria-controls="menuId"
        :aria-owns="menuId"
        :aria-expanded="isOpen"
        :aria-activedescendant="focusedItemId"
        @keydown="onKeydown"
      >
        <MBadge
          v-if="selectedOption"
          :aria-labelledby="`${id}-selected-option-label`"
          role="option"
        >
          <m-layout-stack horizontal>
            <span :id="`${id}-selected-option-label`">{{ selectedOption.label }}</span>
            <m-button
              icon="fa-solid fa-x"
              :label="`Remove ${selectedOption.label}`"
              color="secondary"
              icon-only
              ghost
              :data-testid="`remove-chip-btn`"
              @click="setOption(null)"
            />
          </m-layout-stack>
        </MBadge>
        <template v-else-if="selectedOptions?.length">
          <MBadge
            v-for="(option, index) in selectedOptions"
            :key="`${option.value}:${index}`"
            :text="option.label"
            :aria-labelledby="`${id}-selected-option-label-${index}`"
            role="option"
          >
            <m-layout-stack horizontal>
              <span :id="`${id}-selected-option-label-${index}`">{{ option.label }}</span>
              <m-button
                icon="fa-solid fa-x"
                :label="`Remove ${option.label}`"
                icon-only
                ghost
                random="1223"
                :data-testid="`remove-chip-${index}-btn`"
                @click="removeIndex(index)"
              />
            </m-layout-stack>
          </MBadge>
        </template>
        <MBadge
          v-else-if="typeof value === 'string'"
          :aria-labelledby="`${id}-selected-option-label`"
          role="option"
        >
          <m-layout-stack horizontal>
            <span :id="`${id}-selected-option-label`">{{ value }}</span>
            <m-button
              icon="fa-solid fa-x"
              :label="`Remove ${value}`"
              icon-only
              ghost
              :data-testid="`remove-chip-btn`"
              @click="setOption(null)"
            />
          </m-layout-stack>
        </MBadge>
        <template v-else-if="Array.isArray(value)">
          <MBadge
            v-for="(option, index) in value"
            :key="`${option}:${index}`"
            :aria-labelledby="`${id}-selected-option-label-${index}`"
            role="option"
          >
            <m-layout-stack horizontal>
              <span :id="`${id}-selected-option-label-${index}`">{{ option }}</span>
              <m-button
                icon="fa-solid fa-x"
                :label="`Remove ${option}`"
                icon-only
                ghost
                :data-testid="`remove-chip-${index}-btn`"
                @click="removeIndex(index)"
              />
            </m-layout-stack>
          </MBadge>
        </template>

        <!-- <input /> -->

        <input
          :id="id"
          v-model="text"
          class="select-chips-input"
          :role="inputRole"
          :aria-labelledby="`label-${id}`"
          :autocomplete="autocomplete"
          multiple="true"
        />
      </div>
    </template>

    <template #default="{ el }">
      <MMenu
        v-if="filteredItems.length > 0 && $attrs.readonly !== true"
        :id="menuId"
        v-model="isOpen"
        no-auto-open
        role="listbox"
        :aria-multiselectable="multiple"
        :target="el"
        @keydown="onMenuKeydown"
      >
        <SelectRenderList
          v-model="focusedItemId"
          :items="filteredItems"
          :name="name"
          :value="value"
          :multiple="multiple"
          :selected-option="selectedOption"
          :selected-options="selectedOptions"
          :disabled="isFetching"
          :data-testid="$attrs['data-testid']"
          @select="onSelect"
          @update:keydown="updateKeydown"
        >
          <template v-if="$slots['option-section']" #option-section="vBind">
            <slot name="option-section" v-bind="vBind" />
          </template>
          <template v-if="$slots['option-action']" #option-action="vBind">
            <slot name="option-action" v-bind="vBind" />
          </template>
        </SelectRenderList>
      </MMenu>
    </template>
  </MInput>
</template>
<style lang="scss">
.m-select {
  &.use-input input {
    // quasar has aria-owns changing the cursor to pointer
    cursor: text;
  }

  .select--icon {
    transition: transform 0.28s;
  }

  * + .select--icon {
    padding-right: 4px;
    padding-left: 4px;
  }

  .select--chips {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;

    .m-badge {
      margin: 4px;
    }
  }

  input.select-chips-input {
    width: auto !important;
    flex: 1 1 auto;
    padding-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
</style>
