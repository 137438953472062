import { ref, watch, readonly, UnwrapRef, unref, MaybeRef } from "vue";
import { isString, useEvent } from "vue-composable";

export type AudioPlayer = UnwrapRef<ReturnType<typeof useAudioPlayer>>;
export const globalPlayer = ref<AudioPlayer>();
// globalPlayer.value = useAudioPlayer() as any;

export function usePlayAudio(url: string, duration?: number): AudioPlayer;
export function usePlayAudio(blob: Blob): AudioPlayer;
export function usePlayAudio(blob: Blob | string, duration?: number): AudioPlayer;
export function usePlayAudio(blob: string | Blob, _duration?: number): AudioPlayer {
  const player = useAudioPlayer();
  if (isString(blob)) {
    player.audioUrl.value = blob;
  } else {
    player.audioUrl.value = window.URL.createObjectURL(blob);
  }

  return (globalPlayer.value = player as any);
}

export function SecondsToAudioLength(secs: MaybeRef<number | undefined | null>) {
  const s = unref(secs);
  if (!s) return "00:00:00";
  const totalSecs = Math.floor(s);
  const totalMinutes = Math.floor(totalSecs / 60);
  const totalHours = Math.floor(totalMinutes / 60);

  const seconds = totalSecs % 60;
  const minutes = totalMinutes % 60;

  return [
    totalHours < 10 ? "0" + totalHours : totalHours,
    minutes < 10 ? "0" + minutes : minutes,
    seconds < 10 ? "0" + seconds : seconds,
  ].join(":");
}

export function useAudioPlayer() {
  const STEP = 10;
  const audio = typeof Audio !== "undefined" ? new Audio() : ({} as any);

  const playing = ref(false);
  const audioUrl = ref<string>();
  const position = ref<number | null>(null);

  const duration = ref<number>(0);

  watch(audioUrl, (u) => {
    if (u) {
      audio.src = u;
    } else {
      audio.src = "";
      duration.value = 0;
    }
  });
  watch(audioUrl, () => (position.value = 0));
  // useEvent(audio, 'readystatechange')

  if (typeof Audio !== "undefined") {
    // NOTE using events because of keyboard controls
    useEvent(audio, "play", () => {
      playing.value = true;
    });
    useEvent(audio, "pause", () => {
      playing.value = false;
    });

    let durationSeek = false;

    useEvent(audio, "durationchange", () => {
      if (Number.isFinite(audio.duration)) {
        duration.value = audio.duration;
      } else {
        // bump currentTime to force reading full stream
        audio.currentTime = 999999;
      }
      durationSeek = true;
    });

    useEvent(audio, "timeupdate", () => {
      if (durationSeek) {
        audio.currentTime = 0;
        durationSeek = false;
        return;
      }
      position.value = audio.currentTime;
    });
  }

  watch(position, (c) => {
    if (!c) return;
    if (Math.floor(c) !== Math.floor(audio.currentTime)) {
      audio.currentTime = c;
    }
  });

  function play() {
    console.log("read", audio.readyState);
    if (audio.readyState >= 2) {
      audio.play();
    } else {
      // call it again and keep calling until it's ready
      setTimeout(play, 200);
    }
  }
  function pause() {
    audio.pause();
  }
  function forward() {
    position.value = Math.min((position.value || 0) + STEP, audio.duration);
  }
  function rewind() {
    position.value = Math.max((position.value || 0) - STEP, 0);
  }

  return {
    playing,
    position,
    audioUrl,

    duration: readonly(duration),

    play,
    pause,
    forward,
    rewind,
  };
}
