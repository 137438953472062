<script lang="ts" setup>
import { QBtn } from "quasar";
import { onBeforeUnmount, onMounted, ref } from "vue";
import { useEvent } from "vue-composable";
import { showDialog } from "../../../utils/dialog";
import { pusher } from "../../../plugins/thirdparties/pusher";
import { useAuth } from "../../../store";
import { useSnackbar } from "../../../composables/snackbar";
import axios from "../../../utils/axios";
import MLayoutStack from "../../medicus/MLayoutStack/MLayoutStack.vue";
import { useDrawerStore } from "../../../store/drawers";
import { dispatch } from "../../../utils/bus";
import { useRoute } from "vue-router";

useEvent(window, "keydown", (ev) => {
  // Ctrl/cmd + shift + C
  if (ev.key !== "c") return;
  if (!ev.ctrlKey && !ev.metaKey) return;
  if (!ev.shiftKey) return;
  ev.preventDefault();
  if (openDialog) return;
  openSearch();
});

let openDialog: ReturnType<typeof showDialog> | null = null;

function openSearch() {
  useDrawerStore().open("modal", `/chat/chat-finder`, {});
}

const isUnread = ref<Boolean>(false);
await axios.get(`/chat/has-unread`).then((resp) => {
  isUnread.value = resp.data.unreadCount > 0;
});

const broadcastChannels = [];

const route = useRoute();

onMounted(() => {
  const store = useAuth();

  const channelName = store.config?.tenantId + "-chatparticipant-" + store.config?.staff.id;
  let channel = pusher.subscribe(channelName);

  channel.bind("all_messages_read", () => {
    isUnread.value = false;
  });
  channel.bind("chat_updated", (v) => {
    isUnread.value = true;
    // if we happen to be viewing the chat dont show the toast
    if (v.id !== route.query?.id) {
      useSnackbar().add({
        title: "New Message",
        message: v.title,
        actions: [
          {
            label: "Open",
            to: `/chat/${v.type}/list?id=${v.id}`,
          },
        ],
        type: "system",
        timeout: 5000,
      });
    }

    dispatch("chatUpdated", v);
  });
  channel.bind("message_updated", (v) => {
    dispatch("messageUpdated", v);
  });

  broadcastChannels.push(channel);
});

onBeforeUnmount(() => {
  if (broadcastChannels.length === 0) {
    return;
  }

  broadcastChannels.forEach((x) => {
    x.unbind_all();
    x.unsubscribe();
  });
});
</script>

<template>
  <q-btn flat no-caps dense data-testid="app-search" @click="openSearch">
    <MLayoutStack horizontal center full class="relative-position">
      <q-icon name="fa-regular fa-comment" />
      <span>Chat</span>
      <i v-if="isUnread" class="fa-solid fa-circle fa-xs green-icon" />
    </MLayoutStack>
  </q-btn>
</template>
<style lang="scss">
.sidebar-expanded .green-icon {
  left: 12px;
}

.modal-wrapper .app-chat-modal.modal {
  background-color: #fff;

  > .modal-header {
    box-shadow: none;
    padding: 10px 21px 0px 21px;

    > .modal-title {
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;

      margin: auto 0;
    }
  }
}

.green-icon {
  color: var(--status-green);
  position: absolute;
  left: 19px;
  top: 2px;
}
</style>
