<script lang="ts" setup>
import { computed } from "vue";
const props = defineProps<{
  icon?: boolean;
  white?: boolean;
}>();

const fill = computed(() => (props.white ? "#FFF" : "#051e3c"));
</script>
<template>
  <svg
    v-if="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 77 52"
    aria-labelledby="medicus-logo-img-comp"
  >
    <title id="medicus-logo-img-comp">Medicus</title>
    <defs>
      <clipPath id="a" clipPathUnits="userSpaceOnUse">
        <path d="M0 92.1h92.1V0H0z" />
      </clipPath>
    </defs>
    <g clip-path="url(#a)" transform="matrix(1.33777 0 0 -1.33777 -25.98 88.65)">
      <path
        class="edicus-logo-text"
        :fill="fill"
        d="M20.17 30.38c2.55.36 4.09 1.13 4.09 3.38v26.78c0 2.2-1.54 2.97-4.09 3.32v1.66h11.08l12.15-25.3 11.5 25.3h10.95v-1.66c-2.54-.35-3.85-1.12-3.85-3.32V33.76c0-2.25 1.3-3.02 3.85-3.38v-1.65h-14.8v1.65c2.54.36 3.84 1.13 3.84 3.38v25.72l-12.56-27.5H40.2L26.87 59.67v-25.9c0-2.25 1.54-3.02 4.08-3.38v-1.65H20.17z"
      />
      <path
        class="medicus-logo-dot"
        fill="#0cd996"
        d="M68.77 32.16a3.8 3.8 0 003.73 3.73 3.8 3.8 0 003.73-3.73 3.8 3.8 0 00-3.73-3.79 3.8 3.8 0 00-3.73 3.8"
      />
    </g>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 295 55"
    aria-labelledby="medicus-logo-img-comp"
  >
    <title id="medicus-logo-img-comp">Medicus</title>
    <defs>
      <clipPath id="a" clipPathUnits="userSpaceOnUse">
        <path d="M0 283.46h283.46V0H0z" />
      </clipPath>
    </defs>
    <g clip-path="url(#a)" transform="matrix(1.3251 0 0 -1.3251 -45 226.45)">
      <path
        class="medicus-logo-text"
        :fill="fill"
        d="M34.7 132.16c2.56.35 4.1 1.12 4.1 3.38v26.78c0 2.2-1.54 2.96-4.1 3.32v1.66H45.8L57.93 142l11.5 25.3h10.96v-1.66c-2.55-.36-3.85-1.13-3.85-3.32v-26.78c0-2.26 1.3-3.03 3.85-3.38v-1.66H65.58v1.66c2.55.35 3.85 1.12 3.85 3.38v25.71l-12.56-27.5h-2.14L41.4 161.44v-25.9c0-2.25 1.54-3.02 4.1-3.37v-1.66H34.7zM94.04 145.6c5.1.3 6.46 1.25 6.46 4.16 0 3.14-1.54 5.98-5.4 5.98-4.08 0-6.21-3.26-6.5-7.46-.19-2.26.4-2.85 1.89-2.79zm-12.2-1.7c0 9.12 6.87 13.86 14.28 13.86 6.1 0 10.3-3.2 10.3-7.94 0-4.39-3.67-5.46-10.96-5.81l-5.03-.3c-1.37-.06-1.72-.89-1.48-2.49.65-4.62 5.15-7.34 9.95-7.34 2.43 0 5.21.65 7.29 3.2l1.24-1.07c-1.12-2.13-5.1-5.87-11.55-5.87-7.4 0-14.04 4.92-14.04 13.75M130.67 140.7v5.32c0 7.06-4.38 9.78-7.88 9.78-4.26 0-6.7-4.03-6.7-9.66 0-6.7 3.38-12.2 8.48-12.2 2.73 0 6.1 1.48 6.1 6.75m-21.03 2.96c0 9.19 6.28 14.1 12.91 14.1 5.93 0 8.12-4.02 8.12.3v4.86c0 2.67-.89 3.56-3.79 3.26v1.6l7.64 1.48c1.54.3 2.37-.41 2.37-1.9v-32.11c0-2.07 1.13-2.67 2.85-2.25v-.89c-.71-1.36-2.55-1.96-4.2-1.96-1.96 0-3.74.83-4.33 2.61-.3.89-.83 1.19-1.37 1.19-1.54 0-3.43-3.8-8.83-3.8-6.04 0-11.37 5.1-11.37 13.51M146.2 166.4a3.76 3.76 0 003.73 3.74 3.76 3.76 0 003.73-3.73 3.76 3.76 0 00-3.73-3.74 3.76 3.76 0 00-3.73 3.74m-2.55-34.31c2.78.3 3.8 1.07 3.8 3.56v15.58c0 2.72-.9 3.55-3.8 3.32v1.54l7.64 1.54c1.54.3 2.37-.42 2.37-1.96v-20.02c0-2.5 1.01-3.26 3.8-3.56v-1.6h-13.81zM159.65 143.83c0 9.19 7.23 13.93 14.63 13.93 6.64 0 10.73-3.8 10.73-7.35 0-1.78-1.07-3.32-3.2-3.32-2.02 0-2.97 1.42-3.2 3.14-.3 2.43-1.13 5.51-5.28 5.51-4.62 0-7.17-3.73-7.17-9.42 0-7.7 4.86-12.38 11.14-12.38 2.26 0 4.74.59 6.7 3.5l1.24-1.01c-1-2.5-4.97-6.29-11.37-6.29-7.35 0-14.22 4.86-14.22 13.7"
      />
      <path
        class="medicus-logo-dot"
        fill="#0cd996"
        d="M247.84 133.94a3.8 3.8 0 003.73 3.73 3.8 3.8 0 003.73-3.73 3.8 3.8 0 00-3.73-3.8 3.8 3.8 0 00-3.73 3.8"
      />
      <path
        class="medicus-logo-text"
        :fill="fill"
        d="M244.61 138.32c0 5.69-6.16 7.23-10.42 8.6-3.14 1-5.51 2.3-5.51 5.27 0 2.3 1.48 3.79 4.44 3.79 3.14 0 5.63-1.6 7.05-5.75.3-.89.77-1.24 1.54-1.24 2.13 0 1.96 3.43.54 6.34-1.96 1.42-5.16 2.43-8.6 2.43-6.1 0-10.66-2.9-10.66-7.88 0-4.92 4.44-7.17 9.06-8.66 3.68-1.12 6.58-2 6.58-5.1 0-2.18-1.42-4.04-5.27-4.08-5.1-.05-9.72 4.42-10.2 4.84-.09.08-.22.06-.28-.03-.77-1.26-2.78-3.73-4.97-3.73-1.18 0-1.91.66-1.91 2.12v20.44c0 1.54-.9 2.25-2.43 1.96l-7.59-1.54v-1.54c2.9.11 3.8-.66 3.8-3.32v-9.66c0-4.44-2.38-7.7-6.35-7.7-3.9 0-6.1 3.26-6.1 7.7v14.1c0 1.54-.83 2.25-2.37 1.96l-7.64-1.54v-1.54c2.9.11 3.79-.66 3.79-3.32v-10.96c0-7.77 4.27-10.14 8.65-10.14 4.92 0 7.47 2.79 8.6 4.1.29.35.53.53.82.53.42 0 .65-.36.71-.9.3-1.95 1.66-3.73 4.62-3.73 4.3 0 6.95 1.98 8.74 4.2 2.36-1.97 6-4.19 9.99-4.19 5.96 0 11.37 3.03 11.37 8.17"
      />
    </g>
  </svg>
</template>
