<script setup lang="ts">
import { QAvatar, QIcon } from "quasar";
import { computed } from "vue";

const props = defineProps({
  photoUrl: String,
  initials: String,
  name: String,
  workspace: Boolean,
  size: {
    type: String,
    default: "22px",
  },
});

const text = computed(() => {
  if (props.initials) {
    return props.initials;
  }
  if (props.name) {
    return props.name
      .split(/[^a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœøūšÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒØŪŠ]/gim)
      .filter(Boolean)
      .map((x) => x[0])
      .join("");
  }

  return "";
});

const initial = computed(() => text.value.slice(0, props.workspace ? 1 : 2).toLocaleUpperCase());
const colour = computed(() => {
  const str = props.name || props.initials;
  if (!str) return `medicus-no-colour`;
  let hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return `medicus-${Math.abs(hash) % 20}`;
});
</script>
<template>
  <q-avatar
    class="medicus-avatar"
    :class="{ [colour]: true, 'workspace-avatar': workspace }"
    :title="name"
    aria-hidden="true"
    data-testid="medicus-avatar"
  >
    <template v-if="initial">
      <img v-if="photoUrl" :src="photoUrl" data-testid="assignee-photo" />
      <div class="assignee-initials" data-testid="assignee-initials">
        {{ initial }}
      </div>
    </template>
    <template v-else>
      <q-icon class="no-name-avatar" name="fa-solid fa-user" data-testid="no-assignee-icon" />
    </template>
  </q-avatar>
</template>

<style lang="scss">
.medicus-avatar {
  font-size: calc(v-bind(size) + 6px);
  width: v-bind(size);
  height: v-bind(size);

  // border: 1px solid var(--border-colour);

  .q-avatar__content {
    font-size: 0.35em;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: capitalize;

    width: 100%;
    height: 100%;

    .assignee-initials {
      font-weight: bold;
    }
  }

  &.workspace-avatar {
    // margin-right: 5px;
    width: v-bind(size);
    height: v-bind(size);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    .q-avatar__content {
      font-size: 14px !important;
      color: #ffffff !important;

      // NOTE reset the margin
      margin-left: 0;

      line-height: initial;
    }

    .q-avatar__content > .no-name-avatar {
      font-size: 14px !important;
      color: #808080 !important;
    }
  }

  &.medicus-no-colour {
    background: var(--grey-lightest) !important;
    color: #808080 !important;
  }

  &.medicus-0 {
    background: var(--status-green) !important;
    color: #000 !important;
  }

  &.medicus-1 {
    background: #49946c !important;
    color: #fff !important;
  }

  &.medicus-2 {
    background: #22704b !important;
    color: #fff !important;
  }

  &.medicus-3 {
    background: #004d2b !important;
    color: #fff !important;
  }

  &.medicus-4 {
    background: #002d0e !important;
    color: #fff !important;
  }

  // --
  &.medicus-5 {
    background: var(--theme-accent-blue) !important;
    color: #fff !important;
  }

  &.medicus-6 {
    background: var(--theme-blue) !important;
    color: #fff !important;
  }

  &.medicus-7 {
    background: #7badef !important;
    color: #000 !important;
  }

  &.medicus-8 {
    background: #98c8ff !important;
    color: #000 !important;
  }

  &.medicus-9 {
    background: #b6e5ff !important;
    color: #000 !important;
  }

  &.medicus-10 {
    background: #e16e7c !important;
    color: #000 !important;
  }

  // --

  &.medicus-11 {
    background: #bd4e5e !important;
    color: #000 !important;
  }

  &.medicus-12 {
    background: #9a2e42 !important;
    color: #fff !important;
  }

  &.medicus-13 {
    background: #770428 !important;
    color: #fff !important;
  }

  &.medicus-14 {
    background: #55000e !important;
    color: #fff !important;
  }

  //--
  &.medicus-15 {
    background: var(--text-color) !important;
    color: #fff !important;
  }

  &.medicus-16 {
    background: #3d4456 !important;
    color: #fff !important;
  }

  &.medicus-17 {
    background: #676f82 !important;
    color: #fff !important;
  }

  &.medicus-18 {
    background: #959cb1 !important;
    color: #fff !important;
  }

  &.medicus-19 {
    background: #c5cce3 !important;
    color: #000 !important;
  }
}
</style>
