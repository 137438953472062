import { DialogObject, useDialog } from "../composables/dialog";
import { WrappableDialog } from "../vue";
import { router } from "../router";
import { handlingError } from "./component";

export function showDialog<O extends Exclude<DialogObject, "resolve" | "reject">>(
  options: Partial<O>,
): WrappableDialog<O> {
  const r = useDialog();

  // @ts-expect-error not fully correct inferred type
  const dialog = (r.value = {
    // zIndex,
    type: "dialog",
    wrapperClass: handlingError ? "dialog-warning" : undefined,
    contentClass: options.text ? "whitespace-pre-wrap" : undefined,
    small: false,
    ...options,
    router,

    resolve(...args: any[]) {
      successCb.forEach((x) => x(...args));
      r.value = undefined;
    },
    reject(...args: any[]) {
      rejectCb.forEach((x) => x(...args));
      r.value = undefined;
    },
  });

  const successCb: Array<(...args: any[]) => void> = [];
  const rejectCb: Array<(...args: any[]) => void> = [];

  return {
    options,

    onOk(cb: () => void) {
      successCb.push(cb);
      return this;
    },
    onCancel(cb: () => void) {
      rejectCb.push(cb);
      return this;
    },
    onFinally(cb: () => void) {
      successCb.push(cb);
      rejectCb.push(cb);
      return this;
    },
    close() {
      // @ts-expect-error not fully correct inferred type
      dialog.close?.();
    },
  };
}
