import { QItem, QItemLabel, QItemSection, QList } from "quasar";
import { App, Component, defineAsyncComponent } from "vue";
import MButton from "../components/medicus/MButton";
import MBanner from "../components/medicus/MBanner";
import MDynamic from "../components/medicus/MDynamic";
import MDynamicSelect from "../components/medicus/MDynamicSelect";
import MForm from "../components/medicus/MForm";
import MItem from "../components/medicus/MItem";
import MLabeledText from "../components/medicus/MLabeledText";
import MBadge from "../components/medicus/MBadge";
import MRadioGroup from "../components/medicus/MRadioGroup";
import MSidebar from "../components/medicus/MSidebar";
import MInput from "../components/medicus/inputs/MInput";
import MSelect from "../components/medicus/inputs/MSelect";
import MTooltip from "../components/medicus/MTooltip";

export function componentPlugin() {
  return {
    async install(app: App) {
      // load medicus components
      const modules: Record<string, () => Promise<any>> = import.meta.glob(
        "./../components/medicus/**/M*.vue",
      );

      // special components
      const loadComponents: Record<string, false | Component> = {
        MBanner,

        MButton,
        MBadge,

        MInput,
        MForm,
        MLabeledText,

        MItem,
        MRadioGroup,

        // error causing by changing props
        MSidebar,

        // trying to fix subtree error
        MDynamicSelect,
        MDynamic,

        MSelect,

        MTooltip,
      };

      Object.keys(modules).map((p) => {
        const name = p.split("/").at(-1).slice(0, -4)!;
        const comp = modules[p];
        if (name in loadComponents) {
          const comp = loadComponents[name];
          if (comp === false) return;
          app.component(name, comp);
          return;
        }
        app.component(name, defineAsyncComponent(comp));
      });

      app.component(
        "QBtn",
        defineAsyncComponent(() => import("quasar").then((x) => x.QBtn)),
      );
      app.component("QItem", QItem);
      app.component("QItemLabel", QItemLabel);
      app.component("QItemSection", QItemSection);
      app.component("QList", QList);

      app.component(
        "QBadge",
        defineAsyncComponent(() => import("quasar").then((x) => x.QBadge)),
      );
      app.component(
        "QBanner",
        defineAsyncComponent(() => import("quasar").then((x) => x.QBanner)),
      );
      app.component(
        "QCard",
        defineAsyncComponent(() => import("quasar").then((x) => x.QCard)),
      );
      app.component(
        "QCardSection",
        defineAsyncComponent(() => import("quasar").then((x) => x.QCardSection)),
      );
      app.component(
        "QChip",
        defineAsyncComponent(() => import("quasar").then((x) => x.QChip)),
      );
      app.component(
        "QDate",
        defineAsyncComponent(() => import("quasar").then((x) => x.QDate)),
      );
      app.component(
        "QDialog",
        defineAsyncComponent(() => import("quasar").then((x) => x.QDialog)),
      );
      app.component(
        "QEditor",
        defineAsyncComponent(() => import("quasar").then((x) => x.QEditor)),
      );
      app.component(
        "QExpansionItem",
        defineAsyncComponent(() => import("quasar").then((x) => x.QExpansionItem)),
      );
      app.component(
        "QFile",
        defineAsyncComponent(() => import("quasar").then((x) => x.QFile)),
      );
      app.component(
        "QForm",
        defineAsyncComponent(() => import("quasar").then((x) => x.QForm)),
      );
      app.component(
        "QIcon",
        defineAsyncComponent(() => import("quasar").then((x) => x.QIcon)),
      );
      app.component(
        "QSeparator",
        defineAsyncComponent(() => import("quasar").then((x) => x.QSeparator)),
      );
      app.component(
        "QSpace",
        defineAsyncComponent(() => import("quasar").then((x) => x.QSpace)),
      );
      app.component(
        "QToggle",
        defineAsyncComponent(() => import("quasar").then((x) => x.QToggle)),
      );
      app.component(
        "QToolbar",
        defineAsyncComponent(() => import("quasar").then((x) => x.QToolbar)),
      );
      app.component(
        "QUploader",
        defineAsyncComponent(() => import("quasar").then((x) => x.QUploader)),
      );
    },
  };
}
