import { computed } from "vue";
import { useLocalStorage, useNow } from "vue-composable";

// TODO this should come from env
const EXPIRES_IN = 1 * 1000 * 60 * 45; // 45 min
const SESSION_KEY = "__session_expires__";

const { now } = useNow({
  refreshMs: 2000, // 2s
});

const { storage: expiresInStorage } = useLocalStorage<string | null>(SESSION_KEY);

if (import.meta.env.VITEST) {
  globalThis.expiresInStorage = expiresInStorage;
}

export function updateExpires() {
  const at = now.value + EXPIRES_IN;
  expiresInStorage.value = `${at}`;
}

export const expiresIn = computed(checkExpired);
export const isExpired = computed(() => expiresIn.value <= 0 || !expiresIn.value);

window.addEventListener("storage", (e) => {
  if (e.key === SESSION_KEY) {
    if (expiresInStorage.value !== e.newValue) {
      expiresInStorage.value = e.newValue;
    }
  }
});

export function checkExpired() {
  const i = expiresInStorage.value;
  if (!i) return Number.MAX_SAFE_INTEGER;
  return +i - now.value;
}

export function removeExpires() {
  localStorage.removeItem(SESSION_KEY);
}
