import { AxiosRequestConfig } from "axios";
import axios from "../utils/axios";

export interface TenantConfig {
  tenantId: string;
  country: string;
  jurisdiction: string;
  organisationTypes: Array<string>;
  staff: { id: string; name: string; initials: string };
  localOrganisation: { name: string };
  demo: boolean;
  teams: { id: string; name: string }[];
  patientFacingServicesApiEnabled: boolean;
  homepageUrl: string;
}

export function settings(config?: AxiosRequestConfig<TenantConfig>) {
  return axios.get<TenantConfig>("/settings", config).then((x) => x.data);
}

export function load<T>(url: string, config?: AxiosRequestConfig & { showError?: boolean }) {
  return axios.get<T>(url, config);
}
