import { date as dateUtils } from "quasar";
import { computed, nextTick, ref, watch } from "vue";
import { formatDate, formatDateTime } from "../utils/date";
import { getMaxDate, getMinDate } from "../utils/helpers";
import { namedValidators, useInputRules } from "./horizontalLabel";

export function useDateValue<
  T extends {
    modelValue?: Date | string | any;
    name?: string;
    seconds?: string;

    rules?: Array<any>;
    requiredText: string;
    showAllErrors?: boolean;

    minDate: Date | string | any;
    maxDate: Date | string | any;

    minDateText?: string;
    maxDateText?: string;
  },
>(
  props: T,
  hasTime: boolean = false,
  onFocus?: () => void,
  rules: Array<(v: any) => boolean | string> = [],
  requiredTextCb = () => props.requiredText,
) {
  const allRules = [
    (v: any) => {
      if (!v) return true;
      const minDate = getMinDate(props.minDate, hasTime);
      return (
        (date.value as any) >= minDate ||
        props.minDateText ||
        `Must be on or after ${hasTime ? formatDateTime(minDate) : formatDate(minDate)}`
      );
    },
    (v: any) => {
      if (!v) return true;
      const maxDate = getMaxDate(props.maxDate, hasTime);
      return (
        (date.value as any) <= maxDate ||
        props.maxDateText ||
        `Must be on or before ${hasTime ? formatDateTime(maxDate) : formatDate(maxDate)}`
      );
    },
    ...rules,
  ];
  if (hasTime) {
    allRules.push((v) => {
      if (!v) return true;
      if (!timeText.value) return "Invalid time";
      return namedValidators.time(timeText.value) === true || "Invalid time";
    });
  }

  const { value, errors, displayErrors, valid, touched } = useInputRules(
    props,
    onFocus,
    allRules,
    undefined,
    requiredTextCb,
  );

  // server `YYYY-MM-DD` or `YYYY-MM-DD HH:mm`
  // const formValue: WritableComputedRef<Date | string | null> = useFormValue(props);

  // either `DD/MM/YYYY` or `DD/MM/YYYY HH:mm`
  const inputValue = computed<string>({
    get() {
      if (timeText.value) {
        return [dateText.value, timeText.value + ":" + props.seconds].join(" ");
      }
      return dateText.value;
    },
    set(v) {
      const [date, time] = extractDateAndTime(v);

      dateText.value = toInputDate(date) ?? "";
      timeText.value = time ?? "";
    },
  });

  const timeText = ref<string>("");
  const dateText = ref<string>("");

  const date = computed(() => {
    const t = dateText.value;
    if (!t) return undefined;
    const [day, month, year] = (~t.indexOf("-") ? t.split("-").reverse() : t.split("/")).map(
      (x) => +x,
    );
    const [hours, minutes, seconds] = timeText.value?.split(":").map((x) => (x ? +x : 0)) ?? [0, 0];

    const d = new Date(year, month - 1, day, hours, minutes ?? 0, seconds ?? 0);

    // fix year
    // because `05/05/05` will be converted to: `05/05/1905`
    d.setFullYear(year);

    return d;
  });

  function toInputDate(v: string) {
    return v ? v.split("-").reverse().join("/") : "";
  }

  function extractDateAndTime(v: string): [string, string | undefined] {
    return v ? v.split(" ") : ([] as any);
  }

  /**
   * Reverses the format of a date string between "dd/mm/yyyy" and "yyyy/mm/dd".
   * @param {string} dateString - The input date string in the format "dd/mm/yyyy" or "yyyy/mm/dd".
   * @returns {string} The reversed date string
   */
  function reverseDateFormat(dateString: string) {
    const parts = dateString.split("/");
    const reversedDate = parts.reverse().join("/");
    return reversedDate;
  }

  watch(
    value,
    (v) => {
      if (!v) {
        inputValue.value = "";
      } else {
        const [date, time] =
          v instanceof Date
            ? [
                `${v.getFullYear()}-${(v.getMonth() + 1).toString().padStart(2, "0")}-${v
                  .getDate()
                  .toString()
                  .padStart(2, "0")}`,
                `${v.getHours()}:${v.getMinutes()}:${v.getSeconds()}`,
              ]
            : extractDateAndTime(v as any);

        inputValue.value = [
          toInputDate(date),
          // removing time
          (hasTime && time?.split(":").slice(0, 2).join(":")) || undefined,
        ]
          .filter(Boolean)
          .join(" ");
      }
    },
    {
      immediate: true,
    },
  );

  watch(
    inputValue,
    (v) => {
      const date = v?.split(" ")[0].split("/").reverse().join("-") || undefined;

      if (hasTime) {
        const time = timeText.value;
        if (!time || namedValidators.time(timeText.value) !== true) {
          // clear the value
          if (value.value) {
            const previousDate = dateText.value;
            value.value = null;
            nextTick(() => {
              // reset date
              dateText.value = previousDate;
            });
          }
          return;
        }

        value.value = [date, timeText.value ? `${timeText.value}:${props.seconds}` : timeText.value]
          .filter(Boolean)
          .join(" ");
      } else {
        value.value = date || null;
      }
    },
    {
      // if a date is passed we should update first thing
      immediate: value.value instanceof Date,
    },
  );

  const displayDate = computed(() => {
    const date = value.value || dateText.value;

    if (!date) return "";

    const yearFirstDate = reverseDateFormat(date);
    const formattedDate = dateUtils.formatDate(yearFirstDate, "DD MMM YYYY");

    return formattedDate;
  });

  return {
    date,
    errors,
    displayErrors,
    valid,

    dateText,
    timeText,

    rules: allRules,

    input: inputValue,

    displayDate,
    touched,
  };
}
