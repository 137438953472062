<script setup lang="ts">
import { QRadio } from "quasar";
import { computed, getCurrentInstance, onMounted, ref } from "vue";
import { useFormValue } from "../../../../composables/namedValue";
import { randomID } from "../../../../utils/helpers";
import MLayoutStack from "../../MLayoutStack";

const props = defineProps<{
  modelValue?: any;
  name?: string;
  val?: any;

  label?: string;

  disabled?: boolean;
  disable?: boolean;

  tabindex?: string;

  ariaLabelledby?: string;
}>();

const instance = getCurrentInstance()!;
const templateValue = useFormValue(props);

const isRadioGroup = ref(false);
const tabindex = computed(() => {
  if (!isRadioGroup.value) {
    return "0";
  }
  if (templateValue.value) {
    if (templateValue.value === props.val) {
      return "0";
    }
    return "-1";
  }

  return (instance.proxy?.$el as HTMLElement)?.getAttribute("radio-index") ?? "0";
});
onMounted(() => {
  isRadioGroup.value = instance.parent?.attrs.role === "radiogroup";
});

const forId = randomID("radio_group");
</script>
<template>
  <m-layout-stack class="m-radio gap-1" no-gap tabindex="-1">
    <q-radio
      v-model="templateValue"
      class="medicus-outline"
      dense
      :name="name"
      :val="val"
      role="radio"
      :tabindex="tabindex"
      :disable="disabled || disable"
      :aria-labelledBy="ariaLabelledby || forId"
    >
      <label :id="forId" class="radio-label">{{ label }}</label>
    </q-radio>
    <div v-if="$slots.default && templateValue === val" class="input-value">
      <slot />
    </div>
  </m-layout-stack>
</template>

<style lang="scss">
.m-radio {
  --q-primary: var(--theme-accent-blue);

  .input-value {
    margin-left: 25px;
  }

  .radio-label {
    display: block;
    padding: 3px 0;
    cursor: pointer;
  }

  .q-radio__inner {
    pointer-events: initial;
  }

  .q-radio__inner--falsy {
    color: var(--grey);
    background: white;
  }

  .q-radio__inner--truthy {
    .q-radio__bg {
      border-radius: 9999px;
      background: var(--theme-blue);
    }

    .q-radio__check {
      color: white !important;
    }
  }

  &[disabled="disabled"],
  .q-radio.disabled {
    opacity: 1 !important;
    .q-radio__label {
      color: var(--grey) !important;
    }

    .q-radio__inner--truthy {
      color: var(--theme-blue) !important;
      opacity: 0.4;
    }

    .q-radio__inner--falsy {
      color: var(--grey-light);
    }

    .q-radio__inner--truthy {
      .q-radio__bg {
        border-radius: 9999px;
        background: var(--theme-blue);
      }

      .q-radio__check {
        color: white !important;
      }
    }

    &:focus {
      .q-radio__inner--truthy svg {
        color: #0099ff !important;
      }
    }
  }

  &:not(.disabled):focus {
    .q-radio__inner:before {
      background: none !important;
    }

    svg {
      color: var(--theme-blue) !important;
    }
  }

  .q-radio__inner {
    width: 19px;
    height: 19px;
    min-width: 19px;
    align-self: flex-start;
    margin-top: 3px;
  }

  .q-radio__label {
    margin-right: 1em;

    font-size: 14px;
    line-height: 150%;
    font-weight: 400;
    color: var(--text-color);

    padding-left: 8px;
  }
}
</style>
