<script lang="ts" setup>
import { inject, onErrorCaptured } from "vue";
import type { captureException } from "@sentry/vue";
const sentry = inject<{ captureException: typeof captureException } | undefined>(
  "SENTRY",
  undefined,
);
onErrorCaptured((err) => {
  if (sentry) {
    sentry.captureException(err);
    console.log("error", sentry);
  }
  console.error(err);
  return false;
});
</script>
<template><slot /></template>
