import { markRaw } from "vue";
import SearchComponent from "../../components/misc/AppSearch/SearchComponent";
import { Patient } from "../../components/misc/AppSearch/SearchComponent/helpers";
import { showDialog } from "../../utils/dialog";

export function patientSearch(opts?: {
  success?: (patient: Patient) => PromiseLike<void>;
  cancel?: () => PromiseLike<void>;
}): Promise<string | null> {
  return new Promise((res) => {
    let resolved = false;
    showDialog({
      title: "Find a Patient",
      noButtons: true,
      component: markRaw(SearchComponent),
      type: "small",
      class: "app-search-modal",

      contentClass: "h-full",

      transient: true,

      componentProps: {
        onSelect(patient: Patient) {
          resolved = true;
          opts?.success?.(patient);
          res(patient.value);
        },
      },
    }).onFinally(() => {
      if (resolved) return;
      opts?.cancel?.();
      res(null);
    });
  });
}
