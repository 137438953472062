<script setup lang="ts">
import { ComponentPublicInstance, PropType, computed, nextTick, ref, watch } from "vue";
import { useOnScroll, useVModel } from "vue-composable";
import MDynamic from "../MDynamic";
import MLayoutStack from "../MLayoutStack";
import MLink from "../MLink";
import { randomID } from "../../../utils/helpers";
import MAction, { type ActionModelValue } from "../MAction";
import MMenu from "../MMenu";

const props = defineProps({
  modelValue: {
    type: null,
    default: () => {
      return VMODEL;
    },
  },
  items: {
    type: Array as () => Array<Item>,
    required: true,
  },

  noScrollContainer: Boolean,

  noPrefetch: Boolean,

  absolute: Boolean,

  base: String,
  dataProps: Object,

  subtitle: Boolean,
  noRefresh: Boolean,

  reload: Boolean,

  inline: Boolean,

  startOpen: Boolean,

  singleColumn: Boolean,

  action: Object as PropType<ActionModelValue>,
});

const headerEl = ref<InstanceType<typeof MLayoutStack> | null>(null);

defineEmits({
  "update:modelValue": (e: any) => true,
});

function getDefaultItem(items: Item[]) {
  const first = items?.[0];
  if (first?.title) {
    return first?.items?.[0];
  }
  return first;
}

watch(
  () => props.items,
  (items) => {
    if (props.modelValue === VMODEL) {
      value.value = getDefaultItem(items);
    }
  },
);

const value =
  props.modelValue !== VMODEL ? useVModel(props, "modelValue") : ref(getDefaultItem(props.items));
if (props.modelValue !== VMODEL) {
  if (!value.value) {
    value.value = getDefaultItem(props.items);
  }
}

const open = ref(props.startOpen);

const dynamicEl = ref<{ comp: ComponentPublicInstance }>();

const compEl = computed(() => dynamicEl.value?.comp?.$el);

const { scrollTop } = useOnScroll(compEl, { passive: true }, 10);
watch(scrollTop, (top, o) => {
  if (o === 0 && top > 0) {
    open.value = false;
  }
});

async function onItemClick(item: Item) {
  value.value = item;
  open.value = false;
  await nextTick();
  headerEl.value?.$el.querySelector("button").focus();
}

const menuId = randomID("dynamic-select");
</script>

<script lang="ts">
export interface Item {
  label?: string;
  value?: string;
  data?: Record<string, any>;

  title?: string;
  items?: Item[];
}
const VMODEL = {};
export default {
  inheritAttrs: false,
};
</script>
<template inherit-attrs="false">
  <div class="m-dynamic-select" :class="{ subtitle, inline }">
    <m-layout-stack
      horizontal
      justify-between
      class="wrapper"
      :class="{
        inline,
        'flex-0': true,
      }"
    >
      <m-layout-stack
        ref="headerEl"
        class="select-header"
        :class="{
          inline,
        }"
        horizontal
        :justify-between="!inline"
      >
        <div v-if="inline" class="select-inline flex-auto">
          <m-layout-stack horizontal justify-between gap="1">
            <button
              class="select-input-inline medicus-outline"
              :aria-expanded="open"
              :aria-label="`Section: ${value?.label}`"
              :aria-controls="open ? menuId : undefined"
              :aria-owns="open ? menuId : undefined"
              @click="open = !open"
            >
              <h2>{{ value?.label }}</h2>
              <i
                class="fa-solid fa-chevron-down q-icon q-select__dropdown-icon q-px-sm"
                :class="{ 'rotate-180': open }"
              />
            </button>
          </m-layout-stack>
          <m-menu
            :id="menuId"
            v-model="open"
            :target="$el"
            :offset="[0, 100]"
            :max-height="500"
            no-parent-event
            no-auto-open
          >
            <ul :class="['select-input-inline-list', !singleColumn && 'grid']">
              <template v-for="group in items" :key="group.title">
                <template v-if="group.items">
                  <li v-for="item in group.items" :key="item.label" role="menuitem">
                    <m-link
                      :class="{
                        'selected-item': item.value === value?.value,
                      }"
                      class="cursor-pointer"
                      :text="item.label"
                      @click="onItemClick(item)"
                    />
                  </li>
                </template>
                <li v-else>
                  <m-link
                    :key="group.label"
                    :class="{
                      'selected-item': group === value,
                    }"
                    class="cursor-pointer"
                    :text="group.label"
                    @click="onItemClick(group)"
                  />
                </li>
              </template>
            </ul>
          </m-menu>
        </div>

        <m-layout-stack v-else class="overflow-auto" horizontal>
          <slot name="option-action" />

          <div
            v-for="option in items"
            :key="option.label"
            :class="{
              'option-item': true,
              selected: option.value === value?.value,
            }"
            @click="value = option"
          >
            {{ option?.label }}
          </div>
          <slot name="prepend" />
        </m-layout-stack>
      </m-layout-stack>

      <slot v-if="action || $slots.action" name="action">
        <m-action :model-value="action" />
      </slot>
    </m-layout-stack>
    <m-dynamic
      v-if="value && value.value"
      ref="dynamicEl"
      :key="reload ? value.value : undefined"
      :class="[!noScrollContainer && 'scroll-area flex-auto']"
      :no-data="!!value.data"
      v-bind="{ ...$attrs, ...dataProps, ...value.data }"
      :url="value.value"
    />
  </div>
</template>
<style lang="scss">
$input-height: 35px;
.m-dynamic-select {
  display: flex !important;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  .wrapper {
    width: 100%;

    &.inline {
      padding-top: 8px;

      .title {
        padding: 0 0px 0 16px;

        font-size: 18px;
        font-weight: bold;
        line-height: 150%;
        letter-spacing: 0.01em;
        margin: 0;
      }

      .q-select__dropdown-icon {
        color: var(--theme-blue);
      }
    }
  }

  &.inline {
    > *:nth-child(2) {
      padding-top: 8px;
      padding-right: 1px;
      padding-bottom: 1px;
    }
  }

  .option-item {
    cursor: pointer;

    padding: 5px 10px;
    border-radius: 4px;

    flex: 0 0 auto;

    &:hover {
      background: #d1d1d1d1;
    }

    &.selected {
      background: var(--theme-grey);
      color: white;
      font-weight: bold;
    }
  }

  > .select-header {
    .medicus-button-container {
      align-self: center;
    }

    &.inline {
      // border-bottom: 1px solid var(--grey-light);

      // background: white;
      padding-top: 8px;

      .title {
        padding: 0 0px 0 16px;

        font-size: 18px;
        font-weight: bold;
        line-height: 150%;
        letter-spacing: 0.01em;
        margin: 0;
      }

      .q-select__dropdown-icon {
        color: var(--theme-blue);
      }
    }

    .m-expander-head {
      height: $input-height;
    }
  }

  &.subtitle {
    .q-field__native {
      font-size: 15px;

      line-height: 150%;
      letter-spacing: 0.01em;

      color: var(--text-color);
    }
  }
}
.select-input-inline {
  display: flex;
  align-items: center;
}

.select-input-inline-list {
  padding-inline-start: 20px;
  // margin-left: 1.5rem;

  list-style-type: disc;
  gap: 0.5rem;

  margin: 16px;

  &.grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, auto));
  }

  button {
    display: list-item;
  }

  .selected-item {
    text-decoration: none;
    pointer-events: none;
    font-weight: bold;
  }
}
</style>
